export class Alert {
    type: AlertType;
    message: string;
    timeout_value:number;
    status:boolean;
    id:number;
    title:string
}

export enum AlertType {
    Success,
    Error,
    Info,
    Warning
}
export class Loader{
	loader:any;
}

export class BadgeStatus {
    badge_status:boolean = false;
}