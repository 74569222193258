import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './/app-routing.module';
import { ProfileComponent } from './profile/profile.component';
import { NavigationsModule } from './navigations/navigations.module';
import { SignupComponent,changeMobileComponent } from './signup/signup.component';
import { HttpModule } from "@angular/http";
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { AlertService } from "./_services/index";
import { AlertComponent } from './_directives/index';
import { FormsModule } from "@angular/forms";
import { EqualValidators } from './_directives/equal-validators.directive';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { CustomFormsModule } from 'ngx-custom-validators';
import { MatDialogModule } from '@angular/material/dialog';
import {LoaderModule} from './loader/loader.module';
import { SocketIoModule, SocketIoConfig } from 'ngx-socket-io';
import {environment} from '../environments/environment';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import { PipeModule } from './pipemodule/pipe.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { UUID } from 'angular2-uuid';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';


const config: SocketIoConfig = { url:environment.chat_url , options: {reconnection: true,reconnectionDelay: 1000,reconnectionDelayMax : 5000,reconnectionAttempts: Infinity,query: "name=icarryit&nm="+UUID.UUID()}};

//path: '/chat/socket.io',

@NgModule({
  declarations: [
    AppComponent,
    ProfileComponent,
    SignupComponent,
    AlertComponent,
    EqualValidators,
    changeMobileComponent,
    TermsConditionComponent,
    PrivacyPolicyComponent,
  ],
  imports: [
    NgbModule,
    PipeModule,
    BrowserModule,
    AppRoutingModule,
    NavigationsModule,
    HttpModule,
    BrowserAnimationsModule,
    FormsModule,
    MatDialogModule,
    MatCheckboxModule,
    DeviceDetectorModule.forRoot(),
    CustomFormsModule,
    LoaderModule,
    SocketIoModule.forRoot(config),
  ],
  entryComponents:[changeMobileComponent],
  providers: [AlertService],
  bootstrap: [AppComponent]
})
export class AppModule { 
  
}
//platformBrowserDynamic().bootstrapModule(AppModule);
