<app-header (goChangeLang)="goToChangeLang($event)"></app-header>
<section class="sign_up">
  <div class="sign_header">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center">
          <p class="pt-2 pb-2 border-bottom text-left">
            {{ app_constant["YOU_ARE_HERE" + app_lang?.lang] }}:
            <a href="javascript:void(0)">{{
              app_constant["HOME_LINK" + app_lang?.lang]
            }}</a>
            <span>»</span>
            <a href="javascript:void(0)">{{
              app_constant["SIGNUP_LINK" + app_lang?.lang]
            }}</a>
          </p>
          <h2 *ngIf="!users_data?.email" class="mb-2 mt-5 ">
            {{ app_constant["CREATE_ACCOUNT" + app_lang?.lang] }}
          </h2>
          <div class="head_social mb-5">
            <a target="_blank" href="{{social_data?.facebook | replace}}" ><span class="icon-facebook"></span></a>
            <a target="_blank" href="{{social_data?.twitter | replace}}"><span class="icon-twitter"></span></a>
            <a href="mailto:info@fiuge.com"
              ><span class="icon-email-fil"></span
            ></a>
            <a routerLink="/refer-app"><span class="icon-share"></span></a>
          </div>
          <div class="sign_up_mobile mb-4">
            <img src="assets/images/blue_mob.png" class="blue-img" />
            <img src="assets/images/yellow_mob.png" class="yellow-img" />
          </div>
        </div>
      </div>

      <div class="row">
        <div *ngIf="userSignData == 'customer'" class="col-12">
          <div class="slide text-center">
            <h6 class="mb-4">
              {{ app_constant["CUSTOMER_SING_UP_TITLE" + app_lang?.lang] }}
            </h6>
            <p class="mb-5">
              {{ app_constant["CUSTOMER_SING_UP_MAINTEXT" + app_lang?.lang] }}
            </p>
          </div>
        </div>
        <div *ngIf="userSignData == 'carrier'" class="col-12">
          <div class="slide text-center">
            <h6 class="mb-4">
              {{ app_constant["CARRIER_SING_UP_TITLE" + app_lang?.lang] }}
            </h6>
            <p class="mb-5">
              {{ app_constant["CARRIER_SING_UP_MAINTEXT" + app_lang?.lang] }}
            </p>
          </div>
        </div>
      </div>
      <div *ngIf="userSignData == 'customer'" class="row mb-5">
        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-courier_service"></span>
          </div>
          <p>{{ app_constant["ALL_IN_ONE" + app_lang?.lang] }}</p>
        </div>

        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-dollar-code"></span>
          </div>
          <p>{{ app_constant["SET_THE_PRICE" + app_lang?.lang] }}</p>
        </div>

        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-bell"></span>
          </div>
          <p>{{ app_constant["UP_TO_DATE" + app_lang?.lang] }}</p>
        </div>

        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-lock"></span>
          </div>
          <p>{{ app_constant["EASY_AND_SECURE" + app_lang?.lang] }}</p>
        </div>
      </div>
      <div *ngIf="userSignData == 'carrier'" class="row mb-5">
        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-courier_service"></span>
          </div>
          <p>{{ app_constant["CARRIER_ICON_All_In_One" + app_lang?.lang] }}</p>
        </div>

        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-dollar-code"></span>
          </div>
          <p>
            {{ app_constant["CARRIER_ICON_Set_The_Price" + app_lang?.lang] }}
          </p>
        </div>

        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-bell"></span>
          </div>
          <p>{{ app_constant["CARRIER_ICON_Up_To_Date" + app_lang?.lang] }}</p>
        </div>

        <div class="col-md-3 col-ms-3 services">
          <div class="serv-box">
            <span class="icon-lock"></span>
          </div>
          <p>{{ app_constant["CARRIER_ICON_Easy_Secure" + app_lang?.lang] }}</p>
        </div>
      </div>
    </div>
  </div>

  <div class="sign_form" *ngIf="!users_data?.status">
    <div class="container">
      <form
        (ngSubmit)="submit(registrationForm.valid)"
        #registrationForm="ngForm"
      >
        <div class="row form-sign">
          <div class="col-md-6 border-right">
            <h4 class="border-heading">
              {{ app_constant["AC_HEADING" + app_lang?.lang] }}
            </h4>
            <div class="in-fild">
              <span class="icon-email"></span>
              <input
                pattern="[a-z0-9A-Z._%+-]+@[a-z0-9A-Z.-]+\.[a-zA-Z]{2,8}$"
                #email="ngModel"
                [(ngModel)]="model.email"
                type="email"
                name="email"
                placeholder="{{ app_constant['S_EMAIL' + app_lang?.lang] }}"
                class="form-control shadow-input"
                required
                [ngClass]="{
                  error:
                    email.errors &&
                    (email.dirty || email.touched || registrationForm.submitted)
                }"
              />
              <div *ngIf="email.errors">
                <p
                  *ngIf="email.errors.pattern && registrationForm.submitted"
                  class="error-msg"
                >
                  {{ app_constant["CORRECT_EMAIL" + app_lang?.lang] }}
                </p>
                <p
                  *ngIf="email.errors.required && registrationForm.submitted"
                  class="error-msg"
                >
                  {{ app_constant["EMAIL_REQUIRED" + app_lang?.lang] }}
                </p>
              </div>
            </div>
            <div class="in-fild country-code">
              <span class="icon-phone"></span>
              <div class="co_input">
                <span class="icon-plus"></span>
                <input
                  pattern="[0-9]+"
                  required
                  #country_code="ngModel"
                  [ngClass]="{
                    error:
                      country_code.errors?.required &&
                      (country_code.dirty ||
                        country_code.touched ||
                        registrationForm.submitted)
                  }"
                  name="country_code"
                  [(ngModel)]="model.country_code"
                  type="text"
                  pattern="[0-9]+"
                  maxlength="5"
                  placeholder=""
                />
              </div>
              <input
                #phone_no="ngModel"
                pattern="[0-9]+"
                maxlength="12"
                minlength="7"
                [(ngModel)]="model.phone_no"
                type="text"
                name="phone"
                placeholder="{{
                  app_constant['PHONE_NUMBER' + app_lang?.lang]
                }}"
                class="form-control shadow-input"
                required
                [ngClass]="{
                  error:
                    phone_no.errors &&
                    (phone_no.dirty ||
                      phone_no.touched ||
                      registrationForm.submitted)
                }"
              />
              <div class="d-flex justify-content-between align-items-center">
                <div *ngIf="country_code.errors">
                  <p
                    *ngIf="
                      country_code.errors.pattern && registrationForm.submitted
                    "
                    class="error-msg"
                  >
                    {{ app_constant["CORRECT_COUNTRYCODE" + app_lang?.lang] }}
                  </p>
                  <p
                    *ngIf="
                      country_code.errors.required && registrationForm.submitted
                    "
                    class="error-msg"
                  >
                    {{ app_constant["COUNTRY_CODE_REQUIRED" + app_lang?.lang] }}
                  </p>
                </div>
                <div *ngIf="phone_no.errors">
                  <p
                    *ngIf="
                      phone_no.errors.pattern && registrationForm.submitted
                    "
                    class="error-msg"
                  >
                    {{ app_constant["CORRECT_PHONE_NUMBER" + app_lang?.lang] }}
                  </p>
                  <p
                    *ngIf="
                      phone_no.errors.required && registrationForm.submitted
                    "
                    class="error-msg"
                  >
                    {{ app_constant["PHONE_NUMBER_REQUIRED" + app_lang?.lang] }}
                  </p>
                </div>
                <div *ngIf="phone_no.errors && !phone_no.errors.pattern">
                  <p
                    *ngIf="
                      phone_no.errors.minlength && registrationForm.submitted
                    "
                    class="error-msg"
                  >
                    {{ app_constant["PHONE_NUMBER_TO_SHORT" + app_lang?.lang] }}
                  </p>
                </div>
              </div>
            </div>
            <div class="in-fild" *ngIf="!model.fb_id">
              <span class="icon-lock"></span>
              <input
                minlength="4"
                [(ngModel)]="model.password"
                type="password"
                #new_password="ngModel"
                name="new_password"
                validateEqual="confirm_password"
                reverse="true"
                placeholder="{{
                  app_constant['PASSWORD_PLACEHOLDER' + app_lang?.lang]
                }}"
                class="form-control border-input"
                required
                [ngClass]="{
                  error:
                    new_password.errors &&
                    (new_password.dirty ||
                      new_password.touched ||
                      registrationForm.submitted)
                }"
              />
              <div *ngIf="new_password.errors">
                <p
                  *ngIf="
                    new_password.errors.minlength && registrationForm.submitted
                  "
                  class="error-msg"
                >
                  {{ app_constant["PASSWORD_TO_SHORT" + app_lang?.lang] }}
                </p>
                <p
                  *ngIf="
                    new_password.errors.required && registrationForm.submitted
                  "
                  class="error-msg"
                >
                  {{ app_constant["PASSWORD_REQUIRED" + app_lang?.lang] }}
                </p>
              </div>
            </div>
            <div class="in-fild" *ngIf="!model.fb_id">
              <span class="icon-lock"></span>
              <input
                [equal]="model.password"
                #confirm_password="ngModel"
                [(ngModel)]="model.confirm_password"
                type="password"
                name="confirm_password"
                placeholder="{{
                  app_constant['CONFIRM_PAS_PLACEHOLDER' + app_lang?.lang]
                }}"
                class="form-control border-input"
                required
                [ngClass]="{
                  error:
                    confirm_password.errors &&
                    (confirm_password.dirty ||
                      confirm_password.touched ||
                      registrationForm.submitted)
                }"
              />
              <div *ngIf="model.confirm_password">
                <p
                  *ngIf="model.confirm_password != model.password"
                  class="error-msg"
                >
                  {{
                    app_constant["CONFIRM_PASSWORD_NOTMATCHED" + app_lang?.lang]
                  }}
                </p>
              </div>
              <div *ngIf="confirm_password.errors">
                <p
                  *ngIf="
                    confirm_password.errors.required &&
                    registrationForm.submitted
                  "
                  class="error-msg"
                >
                  {{
                    app_constant["CONFIRM_PASSWORD_REQUIRED" + app_lang?.lang]
                  }}
                </p>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <h4 class="border-heading">
              {{ app_constant["PER_HEADING" + app_lang?.lang] }}
            </h4>
            <div class="in-fild">
              <span class="icon-user"></span>
              <input
                #first_name="ngModel"
                type="text"
                [(ngModel)]="model.first_name"
                name="first_name"
                placeholder="{{ app_constant['FIRST_NAME' + app_lang?.lang] }}"
                class="form-control shadow-input"
                required
                [ngClass]="{
                  error:
                    first_name.errors &&
                    (first_name.dirty || registrationForm.submitted)
                }"
              />
              <div *ngIf="first_name.errors">
                <p
                  *ngIf="
                    first_name.errors.required && registrationForm.submitted
                  "
                  class="error-msg"
                >
                  {{ app_constant["FIRST_NAME_REQUIRED" + app_lang?.lang] }}
                </p>
              </div>
            </div>
            <div class="in-fild">
              <span class="icon-user"></span>
              <input
                #last_name="ngModel"
                [(ngModel)]="model.last_name"
                type="text"
                name="last_name"
                placeholder="{{ app_constant['LAST_NAME' + app_lang?.lang] }}"
                class="form-control shadow-input"
                required
                [ngClass]="{
                  error:
                    last_name.errors &&
                    (last_name.dirty || registrationForm.submitted)
                }"
              />
              <div *ngIf="last_name.errors">
                <p
                  *ngIf="
                    last_name.errors.required && registrationForm.submitted
                  "
                  class="error-msg"
                >
                  {{ app_constant["LAST_NAME_REQUIRED" + app_lang?.lang] }}
                </p>
              </div>
            </div>
            <div>
              <span class="mr-3">
                <mat-checkbox
                  #terms="ngModel"
                  name="condition"
                  [(ngModel)]="model.terms"
                  class=""
                  id="terms_conditions"
                  required
                >
                </mat-checkbox>
                <b>
                  <a routerLink="/terms-condition" target="_blank">
                    {{
                      app_constant["TERMS_CONDITION_TEXT_1" + app_lang?.lang]
                    }}</a
                  ></b
                >
              </span>
            </div>
            <div class="error" *ngIf="terms_condition && !model.terms">
              <p class="text-danger mb-0">
                {{ app_constant["ERROR_FOR_TERM_CONDITION" + app_lang?.lang] }}
              </p>
            </div>
          </div>
          <div class="col-12 text-center send mt-5">
            <button [disabled]="btn_disable" class="btn btn-warning">
              <i [ngClass]="{ 'fa fa-circle-notch fa-spin': btn_disable }"></i
              >{{ app_constant["VERIFY_ACCOUNT" + app_lang?.lang] }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div
    class="sign_form verify_otp"
    *ngIf="users_data?.email && users_data?.status"
  >
    <form>
      <div class="container">
        <div class="row form-sign">
          <div class="col-sm-12 text-center">
            <h4 class="border-heading">
              {{ app_constant["VERFIY_HEADING" + app_lang?.lang] }}
            </h4>
            <p>{{ app_constant["RESEND_CONTET" + app_lang?.lang] }}</p>
            <h5>+{{ users_data?.country_code }} {{ users_data?.phone_no }}</h5>
            <p class="mt-3 mb-3">
              {{ app_constant["ENTER_CODE" + app_lang?.lang] }}
            </p>
            <div class="otp justify-content-center d-flex mb-3">
              <div>
                <input
                  (keypress)="onInputEntry($event, 'tab', 0)"
                  id="tab0"
                  type="text"
                  minlength="1"
                  maxlength="1"
                  placeholder="*"
                  name="first_digit"
                  [(ngModel)]="first_digit"
                  class="form-control border-input"
                />
              </div>

              <div>
                <input
                  (keypress)="onInputEntry($event, 'tab', 1)"
                  id="tab1"
                  type="text"
                  minlength="1"
                  maxlength="1"
                  placeholder="*"
                  name="second_digit"
                  [(ngModel)]="second_digit"
                  class="form-control border-input"
                />
              </div>

              <div>
                <input
                  (keypress)="onInputEntry($event, 'tab', 2)"
                  id="tab2"
                  type="text"
                  minlength="1"
                  maxlength="1"
                  placeholder="*"
                  name="third_digit"
                  [(ngModel)]="third_digit"
                  class="form-control border-input"
                />
              </div>

              <div>
                <input
                  (keypress)="onInputEntry($event, 'tab', 3)"
                  id="tab3"
                  type="text"
                  minlength="1"
                  maxlength="1"
                  placeholder="*"
                  name="fourth_digit"
                  [(ngModel)]="fourth_digit"
                  class="form-control border-input"
                />
              </div>
            </div>

            <p class="mt-0 mb-0">
              <a
                (click)="reSendOtp()"
                href="javascript:void(0)"
                class="text-m-dark"
                >{{ app_constant["RESEND_CODE" + app_lang?.lang] }}
                <span class="icon-refresh"></span
              ></a>
            </p>
          </div>
          <div class="col-12 text-center send mt-5">

            <button
              [disabled]="btn_disable"
              (click)="back()"
              class="btn btn-warning"
            >
              {{ app_constant["BACK_BUTTON" + app_lang?.lang] }}
            </button>
            <button
              [disabled]="btn_disable"
              (click)="registration()"
              class="btn btn-warning ml-2"
            >
              <i
                [ngClass]="{ 'fa fa-circle-notch fa-spin': btn_disable }"
                class=""
              ></i
              >{{ app_constant["SUBMIT" + app_lang?.lang] }}
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="sign_footer_details bg-warning-6">
    <div class="container">
      <h6 class="d-flex align-items-center justify-content-center mb-0">
        {{ app_constant["CONTACT_MORE_INFO" + app_lang?.lang] }}
        <span class="icon-down-arrow"></span>
      </h6>
    </div>
  </div>
</section>
<app-footer [childLangauge]="parentLangauge"></app-footer>
