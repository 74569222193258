<div *ngIf="alert_status == 'success' && !status">
    <div class="successpop {{alert_css}} " id="success_message">
        <div class="success_mag text-center text-success">
            <div class="mb-3">
                <span class="icon-security"></span>
            </div>
            <div>
                <h4 class="mb-3">{{app_constant['SUCCESS'+app_lang?.lang]}}</h4>
                <p class="mb-0 text-dark-7">{{alert_message}}</p>
            </div>
        </div>
    </div>
</div>
<!-- faild alert popups -->
<div *ngIf="alert_status == 'error'">
    <div class="successpop {{alert_css}} " id="faild_message">
        <div class="danger_mag text-center text-danger">
            <div class="mb-3">
                <span class="icon-danger"></span>
            </div>
            <div>
                <h4 class="mb-3">{{app_constant['OH_NO'+app_lang?.lang]}}</h4>
                <p class="mb-0 text-dark-7">{{alert_message}}</p>
            </div>
        </div>
    </div>
</div>
<div *ngIf="alert_status == 'notification'" class="notification_box">
    <div class="">
        <div *ngIf="alert_info.type=='INACTIVE_USER'" class="noti_img">
            <img src="assets/images/sad.svg">
        </div>
        <div *ngIf="alert_info.type!='INACTIVE_USER'" class="noti_img">
            <img src="assets/images/happy.svg">
        </div>
        <div class="pt-3">
            <h4>{{alert_info?.title}}</h4>
            <p *ngIf="chat_message">{{chat_message}}</p>
            <p *ngIf="!chat_message">{{alert_info?.message}}</p>
        </div>
        <div class="not_btn">
          <button *ngIf="alert_info.type=='private_message'" (click)="closeNotification()" class="btn btn-ok btn-danger text-black mr-md-2">{{app_constant['CANCEL_BTN_VIEW'+app_lang?.lang]}}</button>
          <button *ngIf="alert_info.type == 'ACCEPT_REQUEST' || alert_info.type == 'CARRIER_ACCEPT_REQUEST' || alert_info.type == 'PICK_UP_REQUEST' || alert_info.type == 'DELIVER_REQUEST'" (click)="closeNotification()" class="btn btn-ok btn-danger text-black mr-md-2">{{app_constant['CANCEL_BTN_VIEW'+app_lang?.lang]}}</button>
            <button *ngIf="current_id != alert_info?.id && alert_info?.id && view_status == false && alert_info.type != 'ACCEPT_REQUEST' && alert_info.type != 'CARRIER_ACCEPT_REQUEST' && alert_info.type != 'PICK_UP_REQUEST' && alert_info.type != 'DELIVER_REQUEST'"
                (click)="ok()" class="btn btn-ok btn-warning text-black mr-md-2">{{app_constant['OK_BTN'+app_lang?.lang]}}</button>
            <!-- || alert_info.type == 'DELIVER_REQUEST' -->
            <button *ngIf="current_id != alert_info?.id && alert_info?.id && view_status == false && (alert_info.type == 'ACCEPT_REQUEST' || alert_info.type == 'CARRIER_ACCEPT_REQUEST' || alert_info.type == 'PICK_UP_REQUEST')" (click)="ok()" class="btn btn-ok btn-warning text-black mr-md-2">{{app_constant['VIEW_BTN'+app_lang?.lang]}}</button>
            <button *ngIf="current_id != alert_info?.id && alert_info?.id && view_status == false && (alert_info.type == 'DELIVER_REQUEST')" (click)="ok()" class="btn btn-ok btn-warning text-black mr-md-2">{{app_constant['RATE_NOW'+app_lang?.lang]}}</button>
            <button *ngIf="alert_info?.package_id && view_status == true" (click)="gochat(alert_info)" class="btn btn-ok btn-warning text-black mr-md-2">{{app_constant['VIEW_BTN'+app_lang?.lang]}}</button>
            <button *ngIf="alert_info.type !='INACTIVE_USER' && alert_info.type != 'VERIFY_USER' && alert_info.type != 'private_message' && alert_info.type != 'ACCEPT_REQUEST' && alert_info.type != 'CARRIER_ACCEPT_REQUEST' && alert_info.type != 'PICK_UP_REQUEST' && alert_info.type != 'DELIVER_REQUEST'"
                (click)="closeNotification()" class="btn btn-ok btn-danger text-black">
                <span *ngIf="alert_info?.action != 'add' && alert_info?.action != 'accepted' && alert_info?.action != 'delete' ">{{app_constant['CANCEL_BUTTON'+app_lang?.lang]}}</span>
                <span *ngIf="alert_info?.action == 'add' || alert_info?.action == 'accepted' || alert_info?.action == 'delete' ">{{app_constant['OK_BTN'+app_lang?.lang]}}</span>
               </button>
            <button *ngIf="alert_info.type=='INACTIVE_USER' || alert_info.type =='VERIFY_USER'" (click)="closeNotification()" class="btn btn-ok btn-danger text-black">{{app_constant['OK_BTN'+app_lang?.lang]}}</button>

        </div>
        <!-- <div *ngIf="alert_info.type == 'DELIVER_REQUEST'">
        </div> -->
    </div>
</div>
<!-- *ngIf="alert_status == 'delivery_request'" -->
<div class="notification_box review_popup" *ngIf="alert_status_delivery == 'delivery_request'">
    <div class="">
        <div class="noti_img">
            <img src="assets/images/happy.svg">
        </div>
        <div class="pt-3">
            <h4>{{alert_info?.title}}</h4>
            <!-- *ngIf="chat_message" -->
            <p *ngIf="chat_message">{{chat_message}} </p>
            <p *ngIf="!chat_message">{{alert_info?.message}}</p>
        </div>
        <form (ngSubmit)="submit(addReviewForm.valid)" #addReviewForm="ngForm">
            <div class="">
                <p class="text-center pb-2 mb-0 transform-reset">{{app_constant['OVERALL_SUCCESS'+app_lang?.lang]}}</p>
                <div class="text-center pt-2 pb-2 mb-3 border-bottom rating">
                    <!--  -->
                    <h6 class="m-0 " [ngClass]="{'error-text': addReviewForm.submitted && !model.overall_rating}">
                        <!-- {{app_constant['RATE_OVERALL'+app_lang?.lang]}} -->
                        <ng-template #overall let-fill="fill">
                            <span class="star" [class.full]="fill === 100">
                        <span class="half" [style.width.%]="fill"><span class="icon-star-fill"></span></span><span class="icon-star-fill"></span>
                            </span>
                        </ng-template>
                        <!-- {{currentRate}} -->
                        <ngb-rating [(rate)]="model.overall_rating" [starTemplate]="overall" [readonly]="false" max="5"></ngb-rating>
                    </h6>
                </div>
            </div>
            <div class="not_btn pb-2">
                <!-- <button (click)="ok()" class="btn btn-ok btn-warning text-black mr-md-2">{{app_constant['OK_BTN'+app_lang?.lang]}}</button> -->
                <button [disabled]="btn_disable" type="submit" class="btn btn-warning"><i [ngClass]="{'fa fa-circle-notch fa-spin':btn_disable}"></i> {{app_constant['RATE_NOW'+app_lang?.lang]}}</button>
                <button (click)="skipNotification()" [disabled]="btn_disable" type="submit" class="btn btn-danger ml-1">{{app_constant['SKIP_BTN'+app_lang?.lang]}} </button>
            </div>
            <a (click)="ok()" href="javascript:void(0)"> {{app_constant['GIVE_FEEDBACK'+app_lang?.lang]}}</a>
        </form>
    </div>
</div>
