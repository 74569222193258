import { NgModule } from '@angular/core';
import { Shortname } from './shortname.pipe';
import { Jobs } from './shortname.pipe';
import { Decodemessage } from './shortname.pipe';

import { CargoSequece } from './shortname.pipe';
import { SecondToTime } from './shortname.pipe';
import { ReplaceText } from './shortname.pipe';
import { SumPipe } from './shortname.pipe';
import { SumDiscountPipe } from './shortname.pipe';
import { TimeFormat } from './shortname.pipe';
import { TimeFormat1 } from './shortname.pipe';
import { GroupByPipe } from './shortname.pipe';

import { LocalDatePipe } from './shortname.pipe';
import { LocalDatePipe1 } from './shortname.pipe';

import { PriceCurrency } from './shortname.pipe';
import { SafeHtmlPipe } from './shortname.pipe';
import { ReplacePipe,TimeFormat2, DecodeText } from './shortname.pipe';













@NgModule({
  declarations: [
    Shortname,
    Jobs,
    Decodemessage,
    CargoSequece,
    SecondToTime,
    ReplaceText,
    SumPipe,
    SumDiscountPipe,
    TimeFormat,
    TimeFormat1,
    PriceCurrency,
    GroupByPipe,
    LocalDatePipe,
    LocalDatePipe1,
    SafeHtmlPipe,
    ReplacePipe,
    TimeFormat2,
    DecodeText
  ],
 exports:[Shortname, Jobs,Decodemessage, CargoSequece,SecondToTime,ReplaceText,SumPipe,SumDiscountPipe,TimeFormat,TimeFormat1,PriceCurrency,GroupByPipe,LocalDatePipe,LocalDatePipe1,SafeHtmlPipe,ReplacePipe,TimeFormat2, DecodeText]
})
export class PipeModule { }
