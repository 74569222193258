import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFinnish from '@angular/common/locales/fi';
import localeSwedish from '@angular/common/locales/sv';
import localeEnglish from '@angular/common/locales/en';
@Injectable({ providedIn: 'root' })
export class SessionService {

    private _locale: string;

    set locale(value: string) {
        this._locale = value;
    }
    get locale(): string {
        return this._locale || 'en-US';
    }
    getLocale(){
      return this._locale || 'en-US';
    }
    registerCulture(culture: string) {
        if (!culture) {
            return;
        }
        this.locale = culture;

        // Register locale data since only the en-US locale data comes with Angular
        switch (culture) {
            case 'fi': {
                registerLocaleData(localeFinnish);
                break;
            }
            case 'sv': {
                registerLocaleData(localeSwedish);
                break;
            }
            case 'en': {
                registerLocaleData(localeEnglish);
                break;
            }
        }
    }
}