import { Component,OnInit } from '@angular/core';
import { environment } from "./../environments/environment";
import { AlertService } from "./_services/index";
//import { DataService } from "./data.service"; 
import { UserService } from "./user/user.service"; 
import * as CryptoJS from 'crypto-js';
import { Socket } from 'ngx-socket-io';
import { DataService } from "./user/data.service"; 
import { Router,ActivatedRoute } from '@angular/router';
import { Location } from "@angular/common";


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'icarryit-web';
  base_url_data:any;
  user_info:any;
  emit_service:any;
  package_data:any;
  package_id:any;
  to_id:any;
  user_type:any;
  route_url:any;
  count:number=1;
  sub:any;
  constructor(location: Location,private alertService: AlertService,private userService: UserService,public socket: Socket,private dataService: DataService,private route :ActivatedRoute,private router: Router,private activatedRoute: ActivatedRoute,
    ) {
      router.events.subscribe(val => {
        var userType = localStorage.getItem('user_type');
        if (userType) {
          this.user_type = JSON.parse(CryptoJS.AES.decrypt(userType, environment.secret_key).toString(CryptoJS.enc.Utf8));
        
        if (location.path() != "") {
          this.route_url = location.path();
        } else {
          if(this.user_type == 'customer'){
            this.route_url = "/";
          }else if(this.user_type == 'carrier'){
            this.route_url = "/cargo";
          }
          
        }
      } 
      this.init();
      });
     }

     init(){
      if (this.route_url != undefined && this.count==1) {
       
        this.count++;
       
        if(this.user_type=='customer'){
          if(this.route_url !='/'){
            this.router.navigate([this.route_url]);
          }else{
            this.router.navigate(['/']);
          }
        
    
       }else{
        if(this.route_url !='/cargo'){
          this.router.navigate([this.route_url]);
        }else{
          this.router.navigate(['/cargo']);
        }
        
       }
      }
    }

    ngOnInit() {
      
    
     
   
      this.emit_service = this.dataService.emitData.subscribe(message =>{

     
        this.sub = this.route.params.subscribe(params => {
          if(params['data']){
            
                    this.package_data = JSON.parse(atob(params['data']));
                    
                    this.package_id = this.package_data.package_id;
                    this.to_id =this.package_data.to_id;
                    
                  }
        })
      

      //   this.route.queryParams.subscribe(params=>{
          
        
      //  })
       
        if(message.type=='private_message' && message.message){
         
          if(parseInt(message.package_id) != parseInt(this.package_id)){
            if(this.router.url.search('user-chat')==-1){
              this.alertService.info(message,15000, true, message['project_id']);
            }
            
            
          }
          this.dataService.clearDataService();
        }
        
        this.package_id=''
      })
      //require("style-loader!./../styles.scss");
      // var baseUrlData = localStorage.getItem('base');
      // if (baseUrlData != undefined) {
      //   this.base_url_data = JSON.parse(CryptoJS.AES.decrypt(baseUrlData, environment.secret_key).toString(CryptoJS.enc.Utf8));
      // }else{

        this.userService.base_url().subscribe(data=> {
          var response = JSON.parse(data['_body']);
          if(data['status'] == 200){
            this.base_url_data = response;
            var icarryit_user = CryptoJS.AES.encrypt(JSON.stringify(response), environment.secret_key).toString();
            localStorage.setItem('base', icarryit_user);
            if(!this.base_url_data){
              this.base_url_data = {};
            }
          }
        },(err)=>{
          console.log(err)
          if(err.status == 0){
            this.alertService.error('CONNECTION REFUSED');
          }else if(err.status == 500){
            this.alertService.error(err.statusText);
          }else if(err.status == 404){
            this.alertService.error(err.statusText);
          }else{
            var res = JSON.parse(err['_body']);
            this.alertService.error(res.message);
          }
    
        });
      //}
      if(localStorage.getItem('users')){
        var info = localStorage.getItem('users');
        this.user_info = JSON.parse(CryptoJS.AES.decrypt(info, environment.secret_key).toString(CryptoJS.enc.Utf8));
        
        var user_data = {
          'user_id': this.user_info.id,
          'email':  this.user_info.email,
        }
       
        this.socket.connect();
        this.socket.emit('login',JSON.stringify(user_data), (data) => {

          
         });
      }
      
    }

    
}
