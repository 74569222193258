import { Component, OnInit } from '@angular/core';

import { Alert, AlertType } from '../_models/index';
import { AlertService } from '../_services/index';
/*declare var $: any;*/
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { environment } from "./../../environments/environment";
import { UserService } from "./../user/user.service";

@Component({
    moduleId: '',
    selector: 'alert',
    templateUrl: 'alert.component.html'
})

export class AlertComponent {
    alerts: Alert[] = [];
    alert_status:string;
    alert_status_delivery:string;
    alert_message:string;
    alert_info:any={};
    alert_css:string;
    status:boolean;
    job_id:any;
    current_id:any;
    chat_message:any;
    app_lang:any;
    app_constant:any;
    view_status:boolean=false;
    model:any={};
    btn_disable:boolean=false;
  
    constructor(private alertService: AlertService,private router: Router,private userService:UserService) { }

    ngOnInit() {
      this.app_constant=environment;
        this.alertService.getAlert().subscribe((alert: Alert) => {
            this.view_status = false;
            var encryptedData = localStorage.getItem('app_langauge');
            if(encryptedData){
              this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
            }else{
              this.app_lang={lang:'_EN'}
            }
            var url= window.location.pathname;
                var str = url;
                var res = str.split('/');
                this.current_id=res[3];
            if (!alert) {
                // clear alerts when an empty alert is received
                this.alerts = [];
                return;
            }
            if (AlertType.Success === alert.type) {
                if(alert.status == true){
                    this.alert_status = 'suc';
                }else{
                    this.alert_status = 'success';
                }
                this.status = alert.status;
                this.alert_message = alert.message;
                this.alert_css = 'success_message active';
            }else if(AlertType.Info == alert.type){
                
                this.alert_info = alert.message;
                if(this.alert_info.type =='DELIVER_REQUEST'){
                    this.alert_status_delivery = 'delivery_request';
                }else{
                    this.alert_status = 'notification';
                }
                if(this.alert_info.type =='private_message'){
                    this.view_status=true;
                    var message =  environment['NEW_MESSAGE'+this.app_lang.lang]
                    message = message.replace("{USER_ID}", this.alert_info.user_name);
                    message = message.replace("{ID}", parseInt(this.alert_info.package_id));
                    message = message+' '+this.alert_info.message;
                    this.chat_message = message;
                }else{
                    this.chat_message = '';
                }
            } else {
                this.alert_status = 'error';
                this.alert_message = alert.message;
                this.alert_css = 'faild_message active';
            }
            
                setTimeout(()=>{
                    this.alert_css = '';
                    this.alert_status = '';
                   // this.alert_status_delivery = '';
                    this.status = true;
                },alert.timeout_value);
           
            
        });
    }
    skipNotification(){
        this.alert_status_delivery = '';
    }
    closeNotification(){
        this.alert_status = '';
        
        this.status = false;
    }
    ok(){
        this.alert_status = '';
        this.status = false;
        if(localStorage.getItem('user_type')){
            var encryptedTypeData = localStorage.getItem('user_type')
            if(encryptedTypeData){
               var user_type = JSON.parse(CryptoJS.AES.decrypt(encryptedTypeData, environment.secret_key).toString(CryptoJS.enc.Utf8));
               if(user_type=='customer'){
                   if(this.alert_info.type == 'DELIVER_REQUEST'){
                    this.alert_status_delivery = '';
                    var carrier_id = CryptoJS.AES.encrypt(JSON.stringify(this.alert_info.carrier_id), environment.secret_key).toString();
                    this.router.navigate(['/package-review/'+this.alert_info.id],{ queryParams: { carrier_id: carrier_id } });  
                }else{
                    this.router.navigate(['/order-details/'+this.alert_info.id]);
                   }
                
                
               }else if(user_type == 'carrier'){
                 if(this.alert_info.type == 'release') {
                    this.router.navigate(['/cargo']);
                 } else if(this.alert_info.type == 'transfer' || this.alert_info.type == 'accept'){
                    this.router.navigate(['/route-details']);
                 }
                
               }
            }
        }
        
    }
    gochat(alert_info){

        this.alert_status = '';
        this.status = false;
        if(localStorage.getItem('user_type')){
            var encryptedTypeData = localStorage.getItem('user_type')
            if(encryptedTypeData){
               var user_type = JSON.parse(CryptoJS.AES.decrypt(encryptedTypeData, environment.secret_key).toString(CryptoJS.enc.Utf8));
               //if(user_type=='customer'){
                var package_data={package_id:alert_info.package_id,to_id:alert_info.from_id,status:'to_pickup'}
                // var encrypt_data = CryptoJS.AES.encrypt(JSON.stringify(package_data), environment.secret_key).toString();
                
                // this.router.navigate(['/user-chat'], { queryParams: { 'data':encrypt_data} });
                var encrypt_data = btoa(JSON.stringify(package_data));             
                this.router.navigate(['/user-chat/'+encrypt_data]);
            }
        }
    }

    submit(form){
        this.model.package_id = this.alert_info.id;
        this.model.carrier_id = this.alert_info.carrier_id;
        if(form && this.model.overall_rating){
            this.model.lang = this.app_lang.lang.replace('_','');
            this.btn_disable = true;
            this.userService.add_overall_ratting(this.model).subscribe(data=> {
            var res = JSON.parse(data['_body']);
            if(data.status == 200){
                this.model.overall_rating = 0;
                this.btn_disable = false;
                this.alert_status_delivery = '';
                this.status = false;
                // this.router.navigate(['/order-details/'+this.model.package_id]);
                this.alertService.success(res.message);
            }
        },(err) => {
            this.btn_disable = false;
            //this.spinnerService.hide();
                console.log(err)
                if(err.status == 0){
                    this.alertService.error('CONNECTION REFUSED');
                }else if(err.status == 500){
                    this.alertService.error(err.statusText);
                }else if(err.status == 404){
                    this.alertService.error(err.statusText);
                }else{
                    var res =JSON.parse(err['_body']);
                    this.alertService.error(res.message);
                }
        });
    }
}
}
