<nav class=" navbar bg-warning-6 navbar-expand-lg yellow-set-theme">
    <div class="container">
        <div class="d-flex align-items-center">
            <a class="navbar-brand" href="javascript:void(0)">
                <img routerLink="/cargo" class="blue-img" src="./assets/images/w_logo.svg" alt="..." />
                <img routerLink="/" class="yellow-img" src="./assets/images/b_logo.svg" alt="..." />
            </a>
            <div class="select_lan">
                <select name="langauge" [(ngModel)]="app_lang.lang" (ngModelChange)="changeLangauge($event)">
                    <option value="_EN">En</option>
                    <option value="_FI">Fi</option>
                    <option value="_SW">Sw</option>
                </select>
            </div>
        </div>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon">
                <span class="one"></span>
                <span class="two"></span>
                <span class="three"></span>
            </span>
        </button>

        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul *ngIf="user_type == 'customer'" class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a [class.active]="current_url==='createpackage'" class="nav-link" routerLink="/">{{app_constant['SEND_AN_ITEM'+app_lang?.lang]}}</a>
                </li>
                <li class="nav-item">
                    <a [class.active]="current_url==='trackpackage'" class="nav-link" routerLink="/track-package">{{app_constant['TRACK_A_PACKAGE'+app_lang?.lang]}}</a>
                </li>
                <li class="nav-item">
                  <a [class.active]="current_url==='food-cargo'" class="nav-link" routerLink="/prepare-cargo">{{app_constant['PREPARE_CARGO'+app_lang?.lang]}}</a>
              </li>
                <li class="nav-item">
                    <a [class.active]="current_url==='message'" class="nav-link" routerLink="/message">{{app_constant['MESSAGES'+app_lang?.lang]}}</a>
                </li>
                <li *ngIf="!profile_data?.email" class="nav-item">
                    <a [class.active]="current_url===''" class="nav-link" routerLink="/login">{{app_constant['LOGIN_REGISTER'+app_lang?.lang]}}</a>
                </li>

            </ul>
            <ul *ngIf="user_type == 'carrier'" class="navbar-nav ml-auto">
                <li class="nav-item active">
                    <a [class.active]="current_url==='cargo'" class="nav-link" routerLink="/cargo">{{app_constant['SELECT_YOUR_CARGO'+app_lang?.lang]}}</a>
                </li>
                <li class="nav-item">
                    <a [class.active]="current_url==='route' || current_url==='route1'" class="nav-link" routerLink="/route-details">{{app_constant['ROUTE'+app_lang?.lang]}}</a>
                </li>
                <li class="nav-item">
                    <a [class.active]="current_url==='confirmpickup'" class="nav-link" routerLink="/confirm-pickup">{{app_constant['PICKUPMENU'+app_lang?.lang]}}</a>
                </li>
                <li class="nav-item">
                    <a [class.active]="current_url==='confirmdelivery'" class="nav-link" routerLink="/confirm-delivery">{{app_constant['HAND_OVER'+app_lang?.lang]}}</a>
                </li>
                <li class="nav-item">
                    <a [class.active]="current_url === 'message'" class="nav-link" routerLink="/message">{{app_constant['MESSAGES'+app_lang?.lang]}}</a>
                </li>
                <li *ngIf="!profile_data?.email" class="nav-item">
                    <a [class.active]="current_url===''" class="nav-link " routerLink="/login">{{app_constant['LOGIN_REGISTER'+app_lang?.lang]}}</a>
                </li>

            </ul>
            <ul class="navbar-nav align-items-center mobile_menu_set">
                <li *ngIf="profile_data?.email" class="nav-item dropdown">
                    <div class="dropdown-toggle d-flex justify-content-lg-center align-items-center" data-toggle="dropdown">
                        <span><!-- {{profile_data?.first_name}} {{profile_data?.last_name}} --></span>
                        <div class="user-pic ml-2"><img (error)="errorHandler($event)" src="{{app_constant?.profile_image}}{{profile_data?.image}}"></div>
                    </div>
                    <ul class="dropdown-menu">
                        <!-- <li><a routerLink="/user/profile">My Profile</a></li> -->
                        <li><a routerLink="/my-account">{{app_constant['MY_ACCOUNT'+app_lang?.lang]}}</a></li>
                        <li><a (click)="logOut()" href="javascript:void(0)">{{app_constant['LOG_OUT'+app_lang?.lang]}}</a></li>
                    </ul>
                </li>
                <li class="">
                    <a *ngIf="user_type == 'customer'" (click)="changeTheme()" href="javascript:void(0)" class="text-uppercase switch-account-btn ml-lg-3"><span class="icon-double-arrow"></span> <span >{{app_constant['SWITC_TO_CARRIER'+app_lang?.lang]}}</span></a>

                    <a *ngIf="user_type == 'carrier'" (click)="changeTheme()" href="javascript:void(0)" class="text-uppercase switch-account-btn ml-lg-3"><span class="icon-double-arrow"></span><span >{{app_constant['SWITC_TO_CUSTOMER'+app_lang?.lang]}}</span></a>
                </li>
            </ul>
        </div>
    </div>
</nav>
