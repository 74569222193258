import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Socket } from 'ngx-socket-io';
import { AlertService } from "../_services/index";
import { environment } from "./../../environments/environment";
import * as CryptoJS from 'crypto-js';
import{LoaderService} from './../loader/loader.service';


import { Router,ActivatedRoute,NavigationEnd } from '@angular/router';
@Injectable({
  providedIn: 'root'
})
export class DataService {
  app_lang:any;
  user_type:string;
  profile_data:any;
  private changelangauge = new BehaviorSubject({ "langauge": ""});
  change_langauge = this.changelangauge.asObservable();

  private changeProfile = new BehaviorSubject({ "image": "",first_name:"",last_name:"",delete_img:""});
  change_profile = this.changeProfile.asObservable();

  private emitRequest = new BehaviorSubject({ "type": "","pickup_image":"","signature_image":"", "id": "","cargo_id":"", "title": "", "message": "", "sender_id": '', "user_type": "", "receiver_id": '', "read_status": 0,"carrier_first_name":"","carrier_last_name":"","carrier_phone_no":"","carrier_id":"","carrier_email":"","carrier_country_code":"","carrier_image":"" });
  emit_request = this.emitRequest.asObservable();

  private startPrivateTyping = new BehaviorSubject({"type":"","request_id":"","message":"","sender_id":'',"receiver_id":'', "msg_date":'', "msg_type":'', 'from_id':'', 'user_name':'', to_id:'', package_id:'', team_id:'',job_id:''});
  startTyping = this.startPrivateTyping.asObservable();

  private emitSource = new BehaviorSubject({"type":"","request_id":"","message":"","sender_id":'',"receiver_id":'', "msg_date":'', "msg_type":'', 'from_id':'','to_id':'', 'user_name':'', 'package_id':'', 'project_status':'', 'date':''});
  emitData = this.emitSource.asObservable();


  private cargoActivitySource = new BehaviorSubject({ "action": "",cargo_info:{id:'',user_id:'',client_id:''} });
  cargo_activity_source = this.cargoActivitySource.asObservable();

  constructor(private router: Router,private socket: Socket,private alertService: AlertService,private loaderservice:LoaderService,) {

    this.socket.on('web_activity', (data) => {

      console.log(data);
      var job_status = data.title;
      if(data.action=='accepted'){
        data.message= environment['ACCEPTED_CARGO'+this.app_lang.lang];
      }


        this.alertService.info(data,10000, true, data['project_id']);
        if(data.type == 'release'){
          if(this.router.url == '/route-details' || this.router.url == '/confirm-pickup' || this.router.url == '/confirm-delivery'){
            var encryptedData = localStorage.getItem('user_type');
            if(encryptedData){
              var user_type = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
              if(user_type == 'carrier'){
                this.router.navigate(['/cargo']);
              }
            }
          }
          this.loaderservice.get_data_service(data);
          localStorage.removeItem('current_cargo');

        }
        if(data.type == 'transfer'){

          var cargo_data = {cargo_id:data.id}
          var current_cargo_id = CryptoJS.AES.encrypt(JSON.stringify(cargo_data), environment.secret_key).toString();
         localStorage.setItem('current_cargo', current_cargo_id);

         this.loaderservice.get_data_service(data);


        }

        if(data.type == 'accept'){

          var cargo_data = {cargo_id:data.id}
          var current_cargo_id = CryptoJS.AES.encrypt(JSON.stringify(cargo_data), environment.secret_key).toString();
         localStorage.setItem('current_cargo', current_cargo_id);
         this.loaderservice.get_data_service(data);

        }




        if(data.status_code == 3){
          localStorage.removeItem('users');
          localStorage.removeItem('send_item');
          localStorage.removeItem('current_cargo');

          this.socket.disconnect(environment.chat_url);
             this.router.navigate(['/login']);
        }
        this.emitRequest.next(data);
    })

    // this.socket.on('connect_error', (error) => {
    //   console.log(error);
    // });
    this.socket.on('cargo_activity', (data) => {
      var encryptedData = localStorage.getItem('app_langauge');
      if(encryptedData){
        this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
      }else{
        this.app_lang={lang:'_EN'}
      }
     // var job_status = data.title;
      if(data.action=='accepted'){
        var message = environment['ACCEPTED_CARGO' + this.app_lang.lang];
        //message = message.replace("{USER_ID}", results[0].name);
        message = message.replace("{ID}", parseInt(data.cargo_info.id));
        data.message = message;
        this.alertService.info(data,20000, true, data['project_id']);
      }
      console.log(JSON.stringify(data));
      const encryptedDatatheme = localStorage.getItem("user_type");
      if (encryptedDatatheme) {
        this.user_type = JSON.parse(
          CryptoJS.AES.decrypt(
            encryptedDatatheme,
            environment.secret_key
          ).toString(CryptoJS.enc.Utf8)
        );
      }
      var profileData = localStorage.getItem("users");
    if (profileData) {
      this.profile_data = JSON.parse(
        CryptoJS.AES.decrypt(profileData, environment.secret_key).toString(
          CryptoJS.enc.Utf8
        )
      );
    }

      if(data.action=='add'){
        var message = environment['ADD_CARGO' + this.app_lang.lang];
        //message = message.replace("{USER_ID}", results[0].name);
        var p = Number(data.cargo_info.price);
        message = message.replace("{PRICE}", p.toFixed(2));
        data.message = message;

        if(this.user_type == 'customer' && parseInt(this.profile_data.id) == parseInt(data.cargo_info.client_id)){
          this.alertService.info(data,20000, true, data['project_id']);
        }else if(this.user_type == 'carrier'){
          this.alertService.info(data,20000, true, data['project_id']);
        }
      }

      if(data.action=='delete'){
        var message = environment['DELETE_CARGO' + this.app_lang.lang];
        //message = message.replace("{USER_ID}", results[0].name);
        message = message.replace("{ID}", parseInt(data.cargo_info.id));
        data.message = message;

        if(this.user_type == 'customer' && parseInt(this.profile_data.id) == parseInt(data.cargo_info.client_id)){
          this.alertService.info(data,20000, true, data['project_id']);
        }else if(this.user_type == 'carrier'){
          this.alertService.info(data,20000, true, data['project_id']);
        }
      }





        this.cargoActivitySource.next(data);

    })


    this.socket.on('startPrivateTyping', (data) => {
      data =JSON.parse(data);
      this.startPrivateTyping.next(data);
      })
      this.socket.on('stopPrivateTyping', (data) => {
        data =JSON.parse(data);
        this.startPrivateTyping.next(data);
        })
        this.socket.on('privateMessage', (data) => {
          //data =JSON.parse(data);
          this.emitSource.next(data);
          })
   }
  changeLangaugeService(data){
    this.changelangauge.next(data);
  }
  changeProfileService(data){
    this.changeProfile.next(data);
  }

  clearDataService(){
    var data = {"type":"","request_id":"","message":"","sender_id":'',"receiver_id":'', "msg_date":'', "msg_type":'', 'from_id':'','to_id':'', 'user_name':'', 'package_id':'', 'project_status':'', 'date':''}
    this.emitSource.next(data);
  }
  language(lang){
   // alert(lang)
    this.app_lang={lang:lang}

  }
}
