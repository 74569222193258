import {
  Component,
  OnInit,
  EventEmitter,
  Input,
  Output,
  OnDestroy,
  Inject
} from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "../../../environments/environment";
import { Router, ActivatedRoute } from "@angular/router";
import { DataService } from "../../user/data.service";
import "@angular/platform-browser-dynamic";
import { DOCUMENT } from "@angular/common";
import "lodash";
declare var require: any;
import { LoaderService } from "../../loader/loader.service";
import { Socket } from "ngx-socket-io";
import { UserService } from "../../user/user.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"]
})
export class HeaderComponent implements OnInit, OnDestroy {
  @Output() goChangeLang = new EventEmitter<string>();
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    @Inject(DOCUMENT) private document,
    private loaderservice: LoaderService,
    public socket: Socket,
    private userService: UserService
  ) {}
  app_lang: any;
  app_constant: any;
  profile_data: any = {};
  change_profile_service_data: any;
  theme_status: boolean = false;
  user_type: any;
  current_url: any;

  changeTheme() {
    this.loaderservice.change_loader_status(true);

    var themevalue = "zoomOut";
    var themevalue1 = "zoomIn";
    this.theme_status = !this.theme_status;
    if (this.theme_status === false) {
      //this.document.getElementById('loginSection').classList.add(themevalue)
      this.document.getElementById("my-app").classList.remove("carrier_theme");
      this.document.getElementById("my-app").classList.add("customer_theme");
      this.user_type = "customer";
      this.router.navigate(["/"]);
    } else {
      //this.document.getElementById('loginSection').classList.add(themevalue)
      this.document.getElementById("my-app").classList.remove("customer_theme");
      this.document.getElementById("my-app").classList.add("carrier_theme");
      this.router.navigate(["/cargo"]);
      this.user_type = "carrier";
    }
    this.loaderservice.currentUrlMessager.subscribe(url => {
      this.current_url = url;
      if (
        this.user_type === "carrier" &&
        (this.current_url === "createpackage" ||
          this.current_url === "trackpackage")
      ) {
        this.router.navigate(["cargo"]);
      }
      if (
        this.user_type === "customer" &&
        (this.current_url === "cargo" ||
          this.current_url === "route" ||
          this.current_url === "confirmdelivery" ||
          this.current_url === "confirmpickup")
      ) {
        this.router.navigate(["/"]);
      }
    });
    var icarryit_user = CryptoJS.AES.encrypt(
      JSON.stringify(this.user_type),
      environment.secret_key
    ).toString();
    localStorage.setItem("user_type", icarryit_user);
    localStorage.removeItem("send_item");
    this.loaderservice.change_loader_status(false);
  }
  ngOnInit() {
    this.loaderservice.currentUrlMessager.subscribe(url => {
      this.current_url = url;
      var encryptedDatatheme = localStorage.getItem("user_type");
      if (encryptedDatatheme) {
        this.user_type = JSON.parse(
          CryptoJS.AES.decrypt(
            encryptedDatatheme,
            environment.secret_key
          ).toString(CryptoJS.enc.Utf8)
        );
      }
      if (
        this.user_type === "carrier" &&
        (this.current_url === "createpackage" ||
          this.current_url === "trackpackage" ||
          this.current_url === "food-cargo")
      ) {
        this.router.navigate(["cargo"]);
      }
      if (
        this.user_type === "customer" &&
        (this.current_url === "cargo" ||
          this.current_url === "route" ||
          this.current_url === "confirmdelivery" ||
          this.current_url === "confirmpickup")
      ) {
        this.router.navigate(["/"]);
      }
    });
    // require('style-loader!./../../../styles.scss');
    var encryptedData = localStorage.getItem("user_type");
    if (encryptedData) {
      this.user_type = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(
          CryptoJS.enc.Utf8
        )
      );
      if (this.user_type === "carrier") {
        this.theme_status = true;
        this.document
          .getElementById("my-app")
          .classList.remove("customer_theme");
        this.document.getElementById("my-app").classList.add("carrier_theme");
      } else {
        this.theme_status = false;
        this.document
          .getElementById("my-app")
          .classList.remove("carrier_theme");
        this.document.getElementById("my-app").classList.add("customer_theme");
      }
    } else {
      this.user_type = "customer";
      var icarryit_user = CryptoJS.AES.encrypt(
        JSON.stringify(this.user_type),
        environment.secret_key
      ).toString();
      localStorage.setItem("user_type", icarryit_user);
    }

    this.app_constant = environment;
    var encryptedData = localStorage.getItem("app_langauge");
    if (encryptedData) {
      this.app_lang = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(
          CryptoJS.enc.Utf8
        )
      );
    } else {
      this.app_lang = { lang: "_EN" };
    }

    var icarryit_lang = CryptoJS.AES.encrypt(
      JSON.stringify(this.app_lang),
      environment.secret_key
    ).toString();
    localStorage.setItem("app_langauge", icarryit_lang);

    this.change_profile_service_data = this.dataService.change_profile.subscribe(
      data => {
        if (data.image) {
          this.profile_data.image = data.image;
        }
        if (data.delete_img) {
          this.profile_data.image = "";
        }
        if (data.first_name) {
          this.profile_data.first_name = data.first_name;
        }
        if (data.last_name) {
          this.profile_data.last_name = data.last_name;
        }
      }
    );
    var profileData = localStorage.getItem("users");
    if (profileData) {
      this.profile_data = JSON.parse(
        CryptoJS.AES.decrypt(profileData, environment.secret_key).toString(
          CryptoJS.enc.Utf8
        )
      );
    }
  }

  changeLangauge(langauge: string): void {
    var user_data = { lang: langauge };
    var icarryit_web_lang = CryptoJS.AES.encrypt(
      JSON.stringify(user_data),
      environment.secret_key
    ).toString();
    localStorage.setItem("app_langauge", icarryit_web_lang);
    if (langauge == "_EN") {
      var new_lang = "en";
    } else if (langauge == "_SW") {
      var new_lang = "sv";
    } else {
      var new_lang = "fi";
    }
    localStorage.setItem("gpl", new_lang);

    this.dataService.language(langauge);
    {
    }
    this.userService.change_langauge(langauge).subscribe(
      data => {
        var url = this.router.url.split("/");
        var res = JSON.parse(data["_body"]);
        //this.router.navigateByUrl(this.router.url)
        // this.router.navigate([this.router.url])
        if (url[1] == "terms-condition") {
          //this.langauge.lang = this.langauge.lang.replace('_','');

          this.router.navigate([
            "/terms-condition/" + langauge.replace("_", "")
          ]);
        } else if (url[1] == "about-us") {
          this.router.navigate(["/about-us/" + langauge.replace("_", "")]);
        }
        location.reload();
        if (data.status == 200) {
        }
      },
      err => {
        //this.loaderservice.change_loader_status(false);
      }
    );
    this.goChangeLang.emit(langauge);
  }
  errorHandler(event, index) {
    event.target.src = environment.user_no_image;
  }

  ngOnDestroy() {
    if (this.change_profile_service_data) {
      this.change_profile_service_data.unsubscribe();
    }
  }

  logOut() {
    this.userService.log_out();
    this.profile_data = {};
    localStorage.removeItem("users");
    localStorage.removeItem("send_item");
    localStorage.removeItem("current_cargo");
    localStorage.removeItem("select_cargo_id");
    localStorage.removeItem("travel_by");
    this.router.navigate(["/login"]);
    this.socket.disconnect(environment.chat_url);
    //window.location.reload();
  }
}
