<!-- *ngIf="loader_status" -->
<div *ngIf="loader_status">
    <div class="lds-css ng-scope">
        <div class="lds-double-ring">
            <div></div>
            <div></div>
            <img src="assets/images/icon-logo.svg">
        </div>
    </div>
</div>
<div *ngIf="loading">
    <div class="lds-css ng-scope">
        <div class="lds-double-ring">
            <div></div>
            <div></div>
            <img src="assets/images/icon-logo.svg">
        </div>
    </div>
</div>