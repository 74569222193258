import { Component, OnInit,Inject,OnDestroy } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from "./../../environments/environment";
import { LoginService } from "../login/login.service";
import { Router,ActivatedRoute } from '@angular/router';
import { AlertService } from "../_services/index";
declare var require: any;
const Fingerprint2 = require("fingerprintjs2")
import { DeviceDetectorService } from 'ngx-device-detector';
import {MatDialog, MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import { Socket } from 'ngx-socket-io';
import { UserService } from "../user/user.service";

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit,OnDestroy {
  model:any={country_code:358};
  app_lang:any={};
  device_unique_id:any;
  deviceInfo:any={};
  constructor(private route:ActivatedRoute,private loginService:LoginService,public socket: Socket,public dialog: MatDialog, private alertService: AlertService,private router: Router,private deviceService: DeviceDetectorService,private userService:UserService) {

   }
  app_constant:any;
  parentLangauge:any;
  btn_disable:boolean=false;
  users_data:any={};
  verification_data:any = [];
  first_digit:number;
  second_digit:number;
  third_digit:number;
  fourth_digit:number;
  ip_address:any;
  user_type:any;
  terms_condition:boolean=false;
  userSignData = '';
  social_data: any = {};
  ngOnInit() {

    this.userService.log_out()
    this.userService.admin_setting().subscribe(data => {
        const response = JSON.parse(data['_body']);
        if ( data['status'] === 200 ) {
          this.social_data = response.result;
        }
      }, ( err ) => {
      });
    window.scroll(0,0);
    var userData = localStorage.getItem('user_type');
    this.userSignData = JSON.parse(CryptoJS.AES.decrypt(userData, environment.secret_key).toString(CryptoJS.enc.Utf8))
    this.route.queryParams.subscribe(params=>{
    //this.model = JSON.parse(CryptoJS.AES.decrypt(params['data'], environment.secret_key).toString(CryptoJS.enc.Utf8));
    // this.social_data = this.social_data.package_id;
  })
    this.loginService.get_ip().subscribe(data=> {
      var res = JSON.parse(data['_body']);
      console.log(res)
      if(data.status == 200){
        this.ip_address = res.ip
        //console.log(this.ip_address)
      }
    },(err) => {

    });
    var options = {
      NOT_AVAILABLE: 'not available',
      ERROR: 'error',
      EXCLUDED: 'excluded',
  }
    Fingerprint2.getV18(options, (result, components)=>  {

      this.device_unique_id = result;
      console.log(this.device_unique_id)

    })
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.app_constant = environment;
    var encryptedData = localStorage.getItem('app_langauge');
    if(encryptedData){
      this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
    }else{
      this.app_lang.lang='_EN';
    }

    var encryptedUserData = localStorage.getItem('users_data');
    if(encryptedUserData){
      this.users_data = JSON.parse(CryptoJS.AES.decrypt(encryptedUserData, environment.secret_key).toString(CryptoJS.enc.Utf8));
      this.users_data.verification_code = this.users_data.verification_code
      // this.first_digit =this.users_data.verification_code.toString()[0];
      // this.second_digit =this.users_data.verification_code.toString()[1];
      // this.third_digit =this.users_data.verification_code.toString()[2];
      // this.fourth_digit =this.users_data.verification_code.toString()[3];

      this.model= this.users_data;
    }else{

      //this.users_data=null;
      this.users_data={};
    }

    var encryptedSocialData = localStorage.getItem('social_data');
    if(encryptedSocialData){
      var social_datas = JSON.parse(CryptoJS.AES.decrypt(encryptedSocialData, environment.secret_key).toString(CryptoJS.enc.Utf8));


      this.model= social_datas;
      this.model.country_code=this.users_data.country_code;
      this.model.phone_no=this.users_data.phone_no;
      this.users_data.social_key=this.model.fb_id;
      this.users_data.social_type=this.model.social_type;
     // this.model.verification_code = this.users_data.verification_code;

    }
    else{
      this.users_data.social_key='';
      this.users_data.social_type='';
    }


  }
  onInputEntry(event, id, nextInputIndex) {
    console.log(event.key);
    //let input = event.target;
    let nexInput = +nextInputIndex + 1;
    let newID = id + nexInput;
    if(nextInputIndex<3){
      document.getElementById(newID).focus();
    }

  }
  registration(){

    this.users_data.dd = {
      "os": this.deviceInfo.os,
      "platform": this.deviceInfo.browser,
      "make_model": "",
      "push_device_token": "",
      "device_type": "web",
      "screen_resolution": window.screen.availHeight+'*'+window.screen.availWidth,
      "device_unique_id":this.device_unique_id
  }
  this.users_data.dod = {
      "app_version": "1.0",
      "os_version": this.deviceInfo.os_version,
      "network_provider": "",
      "network_type": ""
  }
  this.btn_disable = true;
  var userType = localStorage.getItem('user_type');
  if(userType){
    this.users_data.user_type = JSON.parse(CryptoJS.AES.decrypt(userType, environment.secret_key).toString(CryptoJS.enc.Utf8));
  }
  else{
    this.users_data.user_type='customer';
  }

    this.users_data.new_verification_code = this.first_digit+this.second_digit+this.third_digit+this.fourth_digit;
    console.log('++++++++++++++++++++++++++++++++++++++++')
    console.log(JSON.stringify(this.model));
    console.log(JSON.stringify(this.users_data.social_key));
    this.users_data.social_key=this.model.fb_id;
    this.users_data.social_type=this.model.social_type;

    this.loginService.user_registration(this.users_data).subscribe(data=> {
      var res = JSON.parse(data['_body']);
      if(data.status == 200){
        var login_user_data = {
          'user_id': res.result.id,
          'email':  res.result.email,
        }

        var encryptedData = localStorage.getItem('app_langauge');
        if(encryptedData){
        this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
        }else{
          this.app_lang.lang='_EN';
        }

        if(this.app_lang.lang=='_EN'){
          var new_lang = 'en';
        }else if(this.app_lang.lang == '_SW'){
          var new_lang = 'sv';
        }else{
          var new_lang = 'fi';
        }
        localStorage.setItem('gpl', new_lang);

        this.socket.connect();
        this.socket.emit('login',JSON.stringify(login_user_data), (data) => {
           console.log(JSON.stringify(data))
         });
        this.btn_disable = false;
        this.users_data.verification_code = res.result.verification_code;
        res.result.ip_address = this.ip_address;
        var user_data = CryptoJS.AES.encrypt(JSON.stringify(res.result), environment.secret_key).toString();
        localStorage.setItem('users', user_data);

        var send_datas = {token:res.result.token}
        this.userService.create_account(send_datas).subscribe(data=> {
          var response = JSON.parse(data['_body']);
          res.result.customer_id = response.customer_id;

          var user_data = CryptoJS.AES.encrypt(JSON.stringify(res.result), environment.secret_key).toString();
          localStorage.setItem('users', user_data);
        })
        localStorage.removeItem('users_data');
        this.alertService.success(res.message);


        if(localStorage.getItem('send_item')){
          this.router.navigate(['/']);
        }else{
          var encryptedTypeData = localStorage.getItem('user_type')
          if(encryptedTypeData){
            var user_type = JSON.parse(CryptoJS.AES.decrypt(encryptedTypeData, environment.secret_key).toString(CryptoJS.enc.Utf8));
            if(user_type == 'carrier'){
              this.router.navigate(['/cargo']);
            }else{
              this.router.navigate(['/']);
            }
           }

        }
      }

    },(err) => {
      this.btn_disable = false;
      //this.spinnerService.hide();
        console.log(err)
        if(err.status == 0){
          this.alertService.error('CONNECTION REFUSED');
        }else if(err.status == 500){
          this.alertService.error(err.statusText);
        }else if(err.status == 404){
          this.alertService.error(err.statusText);
        }else{
          var res =JSON.parse(err['_body']);
          this.alertService.error(res.message);
        }
     });
  }
  ngOnDestroy() {
    localStorage.removeItem('social_data');


}
  back(){
    this.users_data.status = false;
  }
  reSendOtp(){
    this.btn_disable = true;
    //this.users_data.country_code = 1;
    //this.spinnerService.show();
  this.loginService.resend_otp(this.users_data).subscribe(data=> {
  var res = JSON.parse(data['_body']);
  if(data.status == 200){
    this.btn_disable = false;
    this.users_data.verification_code = res.result.verification_code;
    var users_data = CryptoJS.AES.encrypt(JSON.stringify(this.users_data), environment.secret_key).toString();
    // this.first_digit =res.result.verification_code.toString()[0];
    // this.second_digit =res.result.verification_code.toString()[1];
    // this.third_digit =res.result.verification_code.toString()[2];
    // this.fourth_digit =res.result.verification_code.toString()[3];
    console.log(this.users_data.verification_code);
    localStorage.setItem('users_data', users_data);
    this.alertService.success(res.message);
  }

},(err) => {
  this.btn_disable = false;
  //this.spinnerService.hide();
    console.log(err)
    if(err.status == 0){
      this.alertService.error('CONNECTION REFUSED');
    }else if(err.status == 500){
      this.alertService.error(err.statusText);
    }else if(err.status == 404){
      this.alertService.error(err.statusText);
    }else{
      var res =JSON.parse(err['_body']);
      this.alertService.error(res.message);
    }
 });
  }
  submit(form ){
    console.log(this.model.terms)

    if(form && this.model.terms){
      this.btn_disable = true;

      //this.model.country_code = 1;
      //this.spinnerService.show();
      this.loginService.user_verification(this.model).subscribe(data=> {
      var res = JSON.parse(data['_body']);
      if(data.status == 200){
        this.btn_disable = false;
        this.terms_condition = false;

        this.model.status = true;
        this.users_data = JSON.parse(JSON.stringify(this.model))
        this.model.verification_code = res.result.verification_code;
        var users_data = CryptoJS.AES.encrypt(JSON.stringify(this.model), environment.secret_key).toString();
        // this.first_digit =res.result.verification_code.toString()[0];
        // this.second_digit =res.result.verification_code.toString()[1];
        // this.third_digit =res.result.verification_code.toString()[2];
        // this.fourth_digit =res.result.verification_code.toString()[3];
        localStorage.setItem('users_data', users_data);
        //this.router.navigate(['/faq']);
        this.alertService.success(res.message);
      }
    },(err) => {
      this.btn_disable = false;
      //this.spinnerService.hide();
        console.log(err)
        if(err.status == 0){
          this.alertService.error('CONNECTION REFUSED');
        }else if(err.status == 500){
          this.alertService.error(err.statusText);
        }else if(err.status == 404){
          this.alertService.error(err.statusText);
        }else{
          var res =JSON.parse(err['_body']);
          this.alertService.error(res.message);
        }
    });
  }else{
    if(this.model.terms==undefined || this.model.terms == false){
      this.terms_condition = true;
    }
  }
}
  goToChangeLang(langauge: string): void {
    this.app_lang.lang=langauge;
    this.parentLangauge=langauge;
  }

  openChangeNumber(phone_no,country_code){
		const dialogRef = this.dialog.open(changeMobileComponent, {
		  width: '450px',
		  // height: '550px',
		  data: {phone_no:phone_no,user_data:this.users_data,country_code:country_code}
	  });

	  dialogRef.afterClosed().subscribe(res => {
      console.log(res);
      if(res){
        //if(res.id){
          //this.card_list.push(result);
          this.users_data.verification_code = res.result.verification_code;
          this.users_data.phone_no = res.result.phone_no;
          this.users_data.country_code = res.result.country_code;
          console.log(this.users_data.country_code)
          var users_data = CryptoJS.AES.encrypt(JSON.stringify(this.users_data), environment.secret_key).toString();
          //var res = JSON.parse(result['_body']);
          this.first_digit =res.result.verification_code.toString()[0];
          this.second_digit =res.result.verification_code.toString()[1];
          this.third_digit =res.result.verification_code.toString()[2];
          this.fourth_digit =res.result.verification_code.toString()[3];
          console.log(this.users_data.verification_code);
          localStorage.setItem('users_data', users_data);
        //}
      }

	  });
	  }
}


@Component({
	selector: 'dialog-content',
	templateUrl: 'mobile.html',
  })
  export class changeMobileComponent implements OnInit {
	btn_disable:boolean;
	admin_data:any;
	model:any={};
	app_constant:any;
  app_lang:any;

	constructor(
	  public dialogRef: MatDialogRef<changeMobileComponent>,
	  @Inject(MAT_DIALOG_DATA) public data: any,
    private alertService: AlertService,
    private loginService:LoginService

	) {

	   }
	   ngOnInit(){
       this.model.phone_no = this.data.phone_no;
       this.model.country_code = this.data.country_code;
       console.log(this.data)
		  this.app_constant = environment ;
		  var encryptedData = localStorage.getItem('app_langauge');
		if(encryptedData){
		this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
		}else{
			this.app_lang.lang='_EN';
		}
		  console.log(this.app_constant)
	   }
	close(){
	 this.dialogRef.close();
	}

  changeNumber(form){
    if(form){
      this.btn_disable = true;
     var send_data={};
      send_data = JSON.parse(JSON.stringify(this.data.user_data))
      send_data['phone_no'] = this.model.phone_no;
      send_data['country_code'] = this.model.country_code;
      this.loginService.resend_otp(send_data).subscribe(datas=> {
        var res = JSON.parse(datas['_body']);
        if(datas.status == 200){
          this.btn_disable = false;
          res.phone_no = this.model.phone_no;
          res.country_code = this.model.country_code;
          this.dialogRef.close(res);
          this.alertService.success(res.message);
        }

      },(err) => {
        this.btn_disable = false;
        //this.spinnerService.hide();
          console.log(err)
          if(err.status == 0){
            this.alertService.error('CONNECTION REFUSED');
          }else if(err.status == 500){
            this.alertService.error(err.statusText);
          }else if(err.status == 404){
            this.alertService.error(err.statusText);
          }else{
            var res =JSON.parse(err['_body']);
            this.alertService.error(res.message);
          }
       });
    }

  }


  }
