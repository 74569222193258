import { Pipe, PipeTransform } from '@angular/core';
import { SessionService } from '../user/session.service';
import { formatDate } from '@angular/common';
import { environment } from "../../environments/environment";
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { DomSanitizer } from '@angular/platform-browser';
declare var require: any;

const cryptLib = require('@skavinvarnan/cryptlib');
declare function escape(s:string): string;



@Pipe({ name: 'shortname' })
export class Shortname implements PipeTransform{
  transform(text: string, text2: string){
    var name = text;
    if(name){
         var name =  name.charAt(0) + text2.charAt(0);
      return name
    }else{
      return 'N/A'
    }

  }
}

@Pipe({ name: 'decodemessage' })
export class Decodemessage implements PipeTransform{
  transform(text: string, text2: string){
    var name = text;

    if(name){
         var name =  decodeURIComponent(escape(name));
      return name
    }else{
      return 'N/A'
    }

  }
}
@Pipe({ name: 'jobs' })
export class Jobs implements PipeTransform{
  transform(text: number, numLetters: number){
    var job = text;
    var word = 'Job';
    if(job){
      if(job > 1){
        word = 'Jobs';
      }
      else{
        word = 'Job';
      }

      return word
    }
    else{
      return word
    }

  }
}
@Pipe({ name: 'cargo_sequence',
pure:false})
export class CargoSequece implements PipeTransform{
  transform(package_id: string, type: string){
    if(localStorage.getItem('package_ids')){
            var select_index = JSON.parse(localStorage.getItem('package_ids'));
            var array_index = select_index.map(function (el) { return el.package_id + ':' + el.type; }).indexOf(package_id+':'+type);
            if(array_index != -1){
              return array_index + 1;
            }
        }

  }
}

@Pipe({ name: 'second_time',pure:false })
export class SecondToTime implements PipeTransform{
  app_lang:any;
  transform(text: number, numLetters: number){
    if(text){
      //this.app_constant = environment;
      var encryptedData = localStorage.getItem('app_langauge');
      if(encryptedData){
        this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
      }else{
        this.app_lang={lang:'_EN'}
      }
      var totalSeconds = text;
      totalSeconds = totalSeconds*60;
      var hours = Math.floor(totalSeconds / 3600);
      var minutes = Math.floor((totalSeconds - (hours * 3600)) / 60);
      var seconds = totalSeconds - (hours * 3600) - (minutes * 60);

      // round seconds
      seconds = Math.round(seconds * 100) / 100

      var result = (hours < 10 ? "0" + hours : hours);
      var string = '';
      if (hours > 0) {
        if(this.app_lang.lang == '_EN' || this.app_lang.lang == '_SW'){
          result += " hr";
        }else{
          result += " h";
        }

          string += result;

      }
      if (minutes > 0) {
          string += " " + (minutes < 10 ? "0" + minutes : minutes) + " min";
          // string += "-" + (minutes < 10 ? "0" + minutes : minutes);

      }
      // if (seconds > 0) {
      //   //seconds.round()
      //   seconds = Math.round(seconds)
      //     string += " " + (seconds < 10 ? "0" + seconds : seconds) + " Sec";
      // }

      return string;
    }else{
      return '0 Sec' ;
    }


  }
}

@Pipe({ name: 'trplace_text' })
export class ReplaceText implements PipeTransform{
  transform(text: string, numLetters: number){
    if(text){
      text =  text.replace("_", " ");

      return text;
    }


  }
}

@Pipe({
  name: 'sum_price',
  pure: false
  })
  export class SumPipe implements PipeTransform {
  transform(items: any[], attr: string): any {
    if(items){
      var sum = 0;
      items.forEach(obj => {

          sum = sum + parseFloat(obj['price']);

      });
      return sum.toFixed(2);
    }

  }
  }
  @Pipe({
    name: 'sum_with_discount',
    pure: false
    })
    export class SumDiscountPipe implements PipeTransform {
    transform(items: any[], attr: string): any {
      if(items){
        var sum = 0;
        items.forEach(obj => {


            sum = sum + (parseFloat(obj['price'])-parseFloat(obj['promocode_amount']));


        });

        return sum.toFixed(2);
      }

    }
    }

    @Pipe({name: 'convertFrom24To12Format',pure:false})
    export class TimeFormat implements PipeTransform {
         transform(time: any): any {
           if(time){
            let hour = (time.split(':'))[0]
            let min = (time.split(':'))[1]
            let part = hour >= 12 ? 'pm' : 'am';
            min = (min+'').length == 1 ? `0${min}` : min;
            hour = hour > 12 ? hour - 12 : hour;
            hour = (hour+'').length == 1 ? `0${hour}` : hour;
            if(hour=='00'){
              return `${12}:${min} ${part}`
            }else{
              return `${hour}:${min} ${part}`
            }

           }

           }
       }

       @Pipe({name: 'convertFrom24To12Format1',pure:false})
       export class TimeFormat1 implements PipeTransform {
            transform(time: any,dates:any): any {
              if(time){
                 var date = moment(dates).format('YYYY-MM-DD');
                 date = date.concat(' ' + time);
                 var localTime  = moment.utc(date).toDate();
                 return moment(localTime).format('HH:mm');;


              }

              }
          }

          @Pipe({name: 'convertFrom24To12Format2',pure:false})
          export class TimeFormat2 implements PipeTransform {

               transform(time: any,dates:any): any {
                 if(time){
                    var date = moment().format('YYYY-MM-DD');
                    date = date.concat(' ' + time);
                   // var localTime  = moment.utc(date).toDate();
                    return moment(date).format('HH:mm');;


                 }

                 }
             }

       @Pipe({name: 'chatGroupBy',
      // pure: false
      })


       export class GroupByPipe implements PipeTransform {
           transform(collection:any, property: string): Array<any> {
               // prevents the application from breaking if the array of objects doesn't exist yet
               if(!collection) {
                   return null;
               }

               const groupedCollection = collection.reduce((previous, current)=> {
                   if(!previous[current[property]]) {
                       previous[current[property]] = [current];
                   } else {
                       previous[current[property]].push(current);
                   }

                   return previous;
               }, {});
               // this will return an array of objects, each object containing a group of objects
               return Object.keys(groupedCollection).map(key => ({ key, value: groupedCollection[key] }));
           }
       }


       @Pipe({
        name: 'localDate',
        pure: false
          })
          export class LocalDatePipe implements PipeTransform {

              constructor(private session: SessionService) { }

              transform(value: any, format: string,lang:any) {
                  if (!value) { return ''; }

                var a =  formatDate(value, format, this.session.locale);
                if(lang == 'remove-text') {
                  if(this.session.locale == 'fi'){
                    a= a.slice(0, -2);
                  }
                  return a
                }else{
                  return a
                }

              }
    }

    @Pipe({
      name: 'localDate1',
      pure: false
        })
        export class LocalDatePipe1 implements PipeTransform {

            constructor(private session: SessionService) { }

            transform(value: any, format: string,lang:any,time:any) {
                if (!value) { return ''; }


              var a =  formatDate(value, format, this.session.locale);
              if(lang == 'remove-text') {
                if(this.session.locale == 'fi'){
                  a= a.slice(0, -2);
                }
                return a
              }else{
                return a
              }

            }
  }

    @Pipe({name: 'pricecurrency',pure:false})
    export class PriceCurrency implements PipeTransform {
         transform(price: any,lang:any): any {

           if(price || price ==0){

             if(lang == '_SW'){
                var new_lang = 'fi-FI';
             }else if(lang == '_FI'){
              var new_lang = 'fi-FI';
             }else{
              var new_lang = 'fi-FI';
             }

            var new_price  = new Intl.NumberFormat(new_lang, { style: 'currency', currency: 'EUR' }).format(price)


            if(new_price =='epäluku' && lang == '_EN'){
              new_price = 'Invalid price';
            }else if(new_price =='epäluku' && lang == '_FI'){
              new_price = 'Virheellinen hinta';
            }else if(new_price =='epäluku' && lang == '_SW'){
              new_price = 'Ogiltigt pris';
            }


            return new_price

           }

           }
       }

       @Pipe({ name: 'safeHtml',pure:false})
       export class SafeHtmlPipe implements PipeTransform  {
         constructor(private sanitized: DomSanitizer) {}
         transform(value) {
           if(value){
            return this.sanitized.bypassSecurityTrustHtml(value);
           }

         }
       }

       @Pipe({
        name: 'replace'
      })
      export class ReplacePipe implements PipeTransform {

        transform(value: any, args?: any): any {

          var regex = new RegExp("^(http|https)://");
          var test = regex.test(value);
          if (!test) {
            value = 'https://' + value;
          }


          return value;
        }

      }


         @Pipe({ name: "decodeText", pure: false })
          export class DecodeText implements PipeTransform {
            transform(text: any): any {
              if (text) {



                try {
                  var baseUrlData = localStorage.getItem("base");
                  const base_url_data = JSON.parse(
                    CryptoJS.AES.decrypt(baseUrlData, environment.secret_key).toString(
                      CryptoJS.enc.Utf8
                    )
                  );
                  const decryptedString = cryptLib.decryptCipherTextWithRandomIV(
                    text,
                    base_url_data.encryption_key
                  );
                  return decryptedString;
                } catch (DecryptException) {
                  return text;
                }
              }
            }
          }
