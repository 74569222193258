<footer>
    <div class="container">
        <div class="d-flex justify-content-between align-items-center">
            <div class="f-logo">
                <img src="assets/images/w_logo.svg" alt="..." /> <span class="pl-4 text-white"> <i class="icon-copyright"></i> {{app_constant['COPY_R'+app_lang?.lang]}} {{year}}</span>
            </div>
            <div class="">
                <div class="social-icons">

                    <a *ngIf="footer_data?.facebook" target="_blank" class="btn-warning-6" href="{{footer_data?.facebook | replace}}"><span class="icon-facebook"></span></a>

                    <a *ngIf="footer_data?.twitter" target="_blank" class="btn-warning-6" href="{{footer_data?.twitter | replace}}"><span class="icon-twitter"></span></a>
                    <a *ngIf="footer_data?.linkedin" target="_blank" class="btn-warning-6" href="{{footer_data?.linkedin | replace}}"><span class="icon-linkedin"></span></a>
                </div>
            </div>
            <div class="copy-link">
                <p class="text-white mb-0"> {{app_constant['MORE_INFO'+app_lang?.lang]}} <span><a href="javascript:void(0)" class="text-white">{{app_constant['INFO_LINK'+app_lang?.lang]}}</a></span> <b> {{app_constant?.CONTACT_NUMBER}}</b> </p>
            </div>
        </div>
        <div class="mt-5">
            <ul class="footer_link d-flex justify-content-center align-items-center w-100">
                <li><a routerLink="/about-us">{{app_constant['ABOUT_APP_HEADING'+app_lang?.lang]}}</a></li>
                <li><a routerLink="/feedback">{{app_constant['SEND_FEEDBACK'+app_lang?.lang]}}</a></li>
                <li><a routerLink="/refer-app">{{app_constant['REFER_A_FRIEND_HEADING'+app_lang?.lang]}} </a></li>
                <li><a routerLink="/help-support">{{app_constant['HELP_SUPPORT_HEADING'+app_lang?.lang]}}</a></li>
            </ul>
        </div>
    </div>
</footer>
