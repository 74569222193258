import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import * as CryptoJS from "crypto-js";
import { environment } from "./../../../environments/environment";
import { DataService } from "../../user/data.service";
import { UserService } from "../../user/user.service";
@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"]
})
export class FooterComponent implements OnInit, OnDestroy {
  app_lang: any = {};
  @Input() public set childLangauge(val: string) {
    this.changeLang(val);
  }

  constructor(
    private dataService: DataService,
    private userService: UserService
  ) {}
  app_constant: any;
  change_langauge_service_data: any;
  footer_data: any = {};
  year: any;
  ngOnInit() {
    this.year = new Date().getFullYear();
    this.app_constant = environment;
    var encryptedData = localStorage.getItem("app_langauge");
    if (encryptedData) {
      this.app_lang = JSON.parse(
        CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(
          CryptoJS.enc.Utf8
        )
      );
    } else {
      this.app_lang.lang = "_EN";
    }

    this.change_langauge_service_data = this.dataService.change_langauge.subscribe(
      data => {
        if (data.langauge) {
          this.app_lang.lang = data.langauge;
        }
      }
    );

    this.userService.admin_setting().subscribe(
      data => {
        var response = JSON.parse(data["_body"]);
        if (data["status"] == 200) {
          this.footer_data = response.result;
          // var icarryit_user = CryptoJS.AES.encrypt(JSON.stringify(response), environment.secret_key).toString();
          // localStorage.setItem('base', icarryit_user);
        }
      },
      err => {}
    );
  }

  changeLang(val) {
    this.app_lang.lang = val;
  }

  ngOnDestroy() {
    if (this.change_langauge_service_data) {
      this.change_langauge_service_data.unsubscribe();
    }
  }
}
