import { Injectable } from '@angular/core';
import {environment} from '../../environments/environment';
import { Observable} from 'rxjs/Observable';
import { Http, RequestOptions, Headers } from '@angular/http';
import * as CryptoJS from 'crypto-js';
@Injectable({
  providedIn: 'root'
})
export class LoginService {

  options:any;
  langauge:any={};
  constructor(private http: Http) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');
    this.options = new RequestOptions({ headers: headers });
    var encryptedLangData = localStorage.getItem('app_langauge');
    if(encryptedLangData){
      this.langauge = JSON.parse(CryptoJS.AES.decrypt(encryptedLangData, environment.secret_key).toString(CryptoJS.enc.Utf8));
    
    }else{
      this.langauge.lang='_EN';
    }
    this.langauge.lang = this.langauge.lang.replace('_','');
  }
  update_lang(){
    //var headers = new Headers();
    
    var encryptedLangData = localStorage.getItem('app_langauge');
    if(encryptedLangData){
      this.langauge = JSON.parse(CryptoJS.AES.decrypt(encryptedLangData, environment.secret_key).toString(CryptoJS.enc.Utf8));
    
    }else{
      this.langauge.lang='_EN';
    }
    this.langauge.lang = this.langauge.lang.replace('_','');
  
  }
  login(form): Observable<any> {
    this.update_lang();
      var admin_creds = {
        email:form.email,
        password:form.password,
        dod:JSON.stringify(form.dod),
        dd:JSON.stringify(form.dd),
        device_type:'web',
        app_version:environment.app_version,
        device_key:form.dd.device_unique_id,
        social_key:form.social_key,
        social_type:form.social_type,
        lang:this.langauge.lang
      };
    return this.http.post(environment.api_url+'version_1.0/user/login', admin_creds, this.options);
  }

  // create_account(form): Observable<any> {
  //   this.update_lang();
     
  //   return this.http.post(environment.api_url+'user/login', this.options);
  // }
  forgot_password(form): Observable<any> {
    this.update_lang();
    var user_creds = {
      email:form.email,
      
      lang:this.langauge.lang
    };
  return this.http.post(environment.api_url+'user/password/forgot', user_creds, this.options);
  }
  reset_password(form): Observable<any> {
    this.update_lang();
    var user_creds = {
      email:form.email,
      password : form.new_password,
      verification_code : form.verification_code,
      lang:this.langauge.lang
    };
  return this.http.post(environment.api_url+'user/password/reset', user_creds, this.options);
  }

  user_verification(form): Observable<any> {
    this.update_lang();
    var user_data = {
      phone_no:form.phone_no,
      country_code:form.country_code,
      first_name:form.first_name,
      last_name:form.last_name,
      email:form.email,
      
    
      lang:this.langauge.lang
    };
  return this.http.post(environment.api_url+'user/verification/create', user_data, this.options);
}
resend_otp(form): Observable<any> {
  this.update_lang();
  var user_data = {
    phone_no:form.phone_no,
    country_code:form.country_code,
    first_name:form.first_name,
    last_name:form.last_name,
    email:form.email,
    lang:this.langauge.lang
  };
return this.http.post(environment.api_url+'user/resend/otp', user_data, this.options);
}
user_registration(form): Observable<any> {
  this.update_lang();
  
  if(!form.social_key){
    form.social_key = '';
  }
  if(!form.social_type){
    form.social_type = '';
  }
  var user_data = {
    phone_no:form.phone_no,
    country_code:form.country_code,
    first_name:form.first_name,
    last_name:form.last_name,
    email:form.email,
    verification_code:form.new_verification_code,
    password:form.password,
    dod:JSON.stringify(form.dod),
    dd:JSON.stringify(form.dd),
    device_type:'web',
    app_version:environment.app_version,
    device_key:form.dd.device_unique_id,
    lang:this.langauge.lang,
    user_type:form.user_type,
    social_key:form.social_key,
    social_type:form.social_type,
  };
  
return this.http.post(environment.api_url+'version_1.0/user/registration', user_data, this.options);
}


get_ip(): Observable<any> {
  //this.update_token();
  return this.http.get('https://ipinfo.io');
} 
}
