import { Injectable } from "@angular/core";
import { Http, RequestOptions, Headers } from "@angular/http";
import { environment } from "../../environments/environment";
import { Observable } from "rxjs/Observable";
import * as CryptoJS from "crypto-js";
import "rxjs/add/operator/map";
import * as moment from "moment";

@Injectable({
  providedIn: "root"
})
export class UserService {
  options: any;
  encryptedData: any;
  langauge: any = {};
  constructor(private http: Http) {
    this.encryptedData = localStorage.getItem("users");
    if (this.encryptedData) {
      var user = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secret_key
        ).toString(CryptoJS.enc.Utf8)
      );
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("token", user.token);
      headers.append("app_version", "1.0");
      this.options = new RequestOptions({ headers: headers });
    }

    var encryptedLangData = localStorage.getItem("app_langauge");
    if (encryptedLangData) {
      this.langauge = JSON.parse(
        CryptoJS.AES.decrypt(
          encryptedLangData,
          environment.secret_key
        ).toString(CryptoJS.enc.Utf8)
      );
    } else {
      this.langauge.lang = "_EN";
    }
    this.langauge.lang = this.langauge.lang.replace("_", "");
  }
  update_token() {
    //var headers = new Headers();
    this.encryptedData = localStorage.getItem("users");
    if (this.encryptedData) {
      var user = JSON.parse(
        CryptoJS.AES.decrypt(
          this.encryptedData,
          environment.secret_key
        ).toString(CryptoJS.enc.Utf8)
      );
      const headers = new Headers();
      headers.append("Content-Type", "application/json");
      headers.append("token", user.token);
      headers.append("app_version", "1.0");
      this.options = new RequestOptions({ headers: headers });
    }
    var encryptedLangData = localStorage.getItem("app_langauge");
    if (encryptedLangData) {
      this.langauge = JSON.parse(
        CryptoJS.AES.decrypt(
          encryptedLangData,
          environment.secret_key
        ).toString(CryptoJS.enc.Utf8)
      );
    } else {
      this.langauge.lang = "_EN";
    }
    this.langauge.lang = this.langauge.lang.replace("_", "");
  }
  log_out() {
    const headers = new Headers();
    headers.append("Content-Type", "application/json");
    headers.append("token", "");
    this.options = new RequestOptions({ headers: headers });
    // this.options='';
  }
  get_lat_long(addres): Observable<any> {
    if (!addres) {
      addres = "";
    }
    return this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?address=" +
        addres +
        "&result_type=postal_code  &key=AIzaSyCW-WaJ6oXIu__7Bc33RI-wZpt7HtrnTsI"
    );
  }

  get_postal_code(addres): Observable<any> {
    if (!addres) {
      addres = "";
    }
    return this.http.get(
      "https://maps.googleapis.com/maps/api/geocode/json?latlng=" +
        addres +
        "&result_type=postal_code  &key=AIzaSyCW-WaJ6oXIu__7Bc33RI-wZpt7HtrnTsI"
    );
  }

  ediit_profile(form, lat_long): Observable<any> {
    this.update_token();
    var user_creds = {
      first_name: form.first_name,
      last_name: form.last_name,
      email: form.email,
      address: lat_long[0].address,
      latitude: lat_long[0].lat,
      longitude: lat_long[0].long,
      phone_no: form.phone_no.replace(/ /g, ""),
      country_code: form.country_code.replace(/ /g, ""),
      profile_image: form.profile_image,
      image_extension: form.image_extension,
      lang: this.langauge.lang,
      deliver_by: form.deliver_by
    };
    return this.http.post(
      environment.api_url + "edit/profile",
      user_creds,
      this.options
    );
  }

  change_langauge(lang): Observable<any> {
    this.update_token();
    lang = lang.replace("_", "");

    var user_creds = {
      lang: lang
    };
    return this.http.post(
      environment.api_url + "change/langauge",
      user_creds,
      this.options
    );
  }

  create_package(data, lat_long, dropoff_address, time_zone): Observable<any> {
    var form = JSON.parse(JSON.stringify(data));
    this.update_token();
    if (!form.promocode_id) {
      form.promocode_id = "";
    }
    form.pickup_phone_no = form.pickup_phone_no.replace(/ /g, "");
    form.drop_of_phone_no = form.drop_of_phone_no.replace(/ /g, "");
    if (form.height) {
      form.size = form.height + "*" + form.width + "*" + form.depth;
    }

    var date = moment(form.date).format("YYYY-MM-DD");
    date = date.concat(" " + form.pick_time);

    var newpickdate = moment(form.date).format("YYYY-MM-DD");
    var new_local_pick_time = moment(form.show_pick_time, "h:mm A").format(
      "HH:mm:ss"
    );
    newpickdate = newpickdate.concat(" " + new_local_pick_time);
    console.log(newpickdate);
    var new_pick_date = moment(newpickdate);
    var new_show_picktime = moment(new_pick_date).format("HH:mm");

    var newdropdate = moment(form.date).format("YYYY-MM-DD");
    var new_local_drop_time = moment(form.show_drop_off_time, "h:mm A").format(
      "HH:mm:ss"
    );
    newdropdate = newdropdate.concat(" " + new_local_drop_time);
    console.log(newdropdate);
    var new_drop_date = moment(newdropdate);
    var new_show_droptime = moment(new_drop_date).format("HH:mm");

    var new_date = moment(date).utc();

    var new_pick_time = moment(new_date).format("HH:mm:ss");
    var date1 = moment(form.date).format("YYYY-MM-DD");
    date1 = date1.concat(" " + form.drop_off_time);
    var new_date1 = moment(date1).utc();
    var new_dropoff_time = moment(new_date1).format("HH:mm:ss");

    var user_creds = {
      package_type_id: form.package_type_id,
      pickup_address: lat_long[0].address,
      pickup_latitude: lat_long[0].lat,
      pickup_longitude: lat_long[0].long,
      //pickup_phone_no:form.pickup_phone_no,
      pickup_country_code: form.country_code,

      pickup_phone_no: form['encript_pickup_phone_no'],
      pick_time: new_pick_time,
      card_info: JSON.stringify(form.card_info),
      date: moment(new_date1).format("YYYY-MM-DD"),
      //drop_of_address: dropoff_address[0].address,
      drop_of_address: form['encript_drop_of_address'],
      drop_of_latitude: dropoff_address[0].lat,
      drop_of_longitude: dropoff_address[0].long,
      drop_off_time: new_dropoff_time,
      //drop_of_phone_no:form.drop_of_phone_no,
      drop_of_phone_no: form['encript_drop_of_phone_no'],
      dropof_country_code: form.country_code_drop_off,
      description: form['encript_description'],
      size: form.size,
      weight: form.weight,
      price: form.old_price,
      distance: form.distance,
      receiver_name: form['encript_receiver_name'],
      pickup_name: form['encript_pickup_name'],
      pickup_apartment: form.pickup_apartment,
      receiver_apartment: form.receiver_apartment,
      promocode_id: form.promocode_id,
      promocode_amount: form.promocode_amount,
      pickup_postal_code: form.pickup_postal_code,
      dropoff_postal_code: form.dropoff_postal_code,
      total_minuts: form.total_minuts,
      pickup_city: form.pickup_city,
      dropoff_city: form.dropoff_city,
      time_zone: time_zone,
      local_pick_time: new_show_picktime,
      local_drop_off_time: new_show_droptime,
      lang: this.langauge.lang
    };
    if(!form.description){
      user_creds.description = '';
    }
    return this.http.post(
      environment.api_url + "version_1.0/customer/send/item",
      user_creds,
      this.options
    );
  }
  apply_promocode(data): Observable<any> {
    this.update_token();
    var promocode_data = {
      promocode: data,
      lang: this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "customer/apply/promocode",
      promocode_data,
      this.options
    );
  }

  apply_payment(data): Observable<any> {
    console.log(data);
    this.update_token();
    var promocode_data = {
      amount: data.price - data.promocode_amount,
      package_id: data.package_id,
      lang: this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "customer/apply/payment",
      promocode_data,
      this.options
    );
  }

  new_payment(data): Observable<any> {
    var promocode_data = {
      amount: data.old_price - data.promocode_amount,
      package_id: data.package_id,
      customer_id: data.customer_id,
      card_id: data.card_info.card_id,
      return_url: environment.app_url
      // lang:this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "version_1.0/create/intent",
      promocode_data,
      this.options
    );
  }

  payment_failed(package_id): Observable<any> {
    var promocode_data = {
      package_id: package_id

      // lang:this.langauge.lang
    };

    return this.http.get(
      environment.api_url +
        "version_1.0/payment/failed?package_id=" +
        package_id,
      this.options
    );
  }
  card_list(): Observable<any> {
    this.update_token();
    return this.http.get(
      environment.api_url + "customer/cards?lang=" + this.langauge.lang,
      this.options
    );
  }

  about_app(): Observable<any> {
    this.update_token();
    return this.http.get(
      environment.api_url +
        "user/content?type=about_app&lang=" +
        this.langauge.lang,
      this.options
    );
  }

  terms_condition(type, lang): Observable<any> {
    this.update_token();
    lang = lang.replace("_", "");

    return this.http.get(
      environment.api_url + "user/content?type=" + type + "&lang=" + lang,
      this.options
    );
  }

  base_url(): Observable<any> {
    //this.update_token();

    //return this.http.get(environment.api_url+'baseUrl?lang='+this.langauge.lang);
    return this.http.get(
      environment.api_url + "version_2.0/baseUrl?lang=" + this.langauge.lang
    );
  }

  admin_setting(): Observable<any> {
    //this.update_token();
    return this.http.get(
      environment.api_url + "admin/setting?lang=" + this.langauge.lang
    );
  }
  stripe_verification(): Observable<any> {
    //this.update_token();
    this.update_token();
    return this.http.get(
      environment.api_url +
        "user/check/stripe/verification?lang=" +
        this.langauge.lang,
      this.options
    );
  }
  add_card(form): Observable<any> {
    this.update_token();
    // var year = form.expiry.split('/')[1].trim()
    if (form.expiry.split("/")[1].trim().length == 5) {
      var year = form.expiry
        .split("/")[1]
        .trim()
        .slice(0, -1);
    } else {
      var year = form.expiry.split("/")[1].trim();
    }
    var user_creds = {
      holder_name: form.holder_name,
      card_number: form.card_number,
      expiry_month: form.expiry.split("/")[0].trim(),
      expiry_year: year,
      cvv: form.cvv,
      lang: this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "customer/card/add",
      user_creds,
      this.options
    );
  }

  add_feedback(form): Observable<any> {
    this.update_token();
    // var year = form.expiry.split('/')[1].trim()

    var user_creds = {
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      phone_number: form.phone_number,
      comunication_via: form.communication_by,
      feedback_type: form.feedback_type,
      feedback: form.feedback,
      lang: this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "user/send/feedback",
      user_creds,
      this.options
    );
  }

  send_support(form): Observable<any> {
    this.update_token();
    // var year = form.expiry.split('/')[1].trim()

    var user_creds = {
      email: form.email,
      first_name: form.first_name,
      last_name: form.last_name,
      phone_number: form.phone_number,
      comunication_via: form.communication_by,
      package_id: form.package_id,
      message: form.feedback,
      lang: this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "user/support/help",
      user_creds,
      this.options
    );
  }

  refer_friend(form): Observable<any> {
    this.update_token();
    // var year = form.expiry.split('/')[1].trim()

    var user_creds = {
      email: form.email,
      friend_email: form.friend_email,
      first_name: form.first_name,
      friend_first_name: form.friend_first_name,
      friend_last_name: form.friend_last_name,
      last_name: form.last_name,
      //phone_number: form.phone_number,
      comunication_via: form.communication_by,
      //package_id: form.package_id,
      message: form.feedback,
      lang: this.langauge.lang
    };

    return this.http.post(
      environment.api_url + "user/refer/friend",
      user_creds,
      this.options
    );
  }
  add_review(form): Observable<any> {
    this.update_token();
    // var year = form.expiry.split('/')[1].trim()

    form.lang = this.langauge.lang;

    return this.http.post(
      environment.api_url + "user/rating",
      form,
      this.options
    );
  }

  add_overall_ratting(form): Observable<any> {
    this.update_token();
    // var year = form.expiry.split('/')[1].trim()

    form.lang = this.langauge.lang;

    return this.http.post(
      environment.api_url + "user/overallratting",
      form,
      this.options
    );
  }

  add_bank(data): Observable<any> {
    var form = JSON.parse(JSON.stringify(data));

    form.dob = form.dobs;
    delete form.dobs;
    this.update_token();
    form.lang = this.langauge.lang;
    return this.http.post(
      environment.api_url + "carrier/account/add",
      form,
      this.options
    );
  }

  update_bank(data): Observable<any> {
    var form = JSON.parse(JSON.stringify(data));
    form.dob = form.dobs;
    delete form.dobs;
    this.update_token();
    form.lang = this.langauge.lang;
    return this.http.post(
      environment.api_url + "carrier/account/update",
      form,
      this.options
    );
  }

  customer_package_list(data): Observable<any> {
    this.update_token();
    return this.http.get(
      environment.api_url +
        "customer/packages?offset=" +
        data.offset +
        "&limit=" +
        data.limit +
        "&lang=" +
        this.langauge.lang,
      this.options
    );
  }

  message_list(data): Observable<any> {
    this.update_token();
    return this.http.get(
      environment.api_url +
        "user/notification/list?offset=" +
        data.offset +
        "&limit=" +
        data.limit +
        "&lang=" +
        this.langauge.lang,
      this.options
    );
  }

  order_history(data): Observable<any> {
    this.update_token();

    return this.http.get(
      environment.api_url +
        "customer/packages?offset=" +
        data.offset +
        "&limit=" +
        data.limit +
        "&type=history&lang=" +
        this.langauge.lang,
      this.options
    );
  }

  carrier_location(data): Observable<any> {
    this.update_token();
    return this.http.get(
      environment.api_url +
        "carrier/location?id=" +
        data +
        "&lang=" +
        this.langauge.lang,
      this.options
    );
  }

  customer_package_detail(id): Observable<any> {
    //this.update_token();
    this.update_token();
    return this.http.get(
      environment.api_url +
        "customer/packages/" +
        id +
        "?lang=" +
        this.langauge.lang,
      this.options
    );
  }

  carrier_cargo_details(id, order_by): Observable<any> {
    //this.update_token();
    this.update_token();
    return this.http.get(
      environment.api_url +
        "carrier/cargos/" +
        id +
        "?lang=" +
        this.langauge.lang +
        "&order_by=" +
        order_by,
      this.options
    );
  }

  client_cargo_details(id, order_by): Observable<any> {
    //this.update_token();
    this.update_token();
    return this.http.get(
      environment.api_url +
        "version_3.0/client/cargos/" +
        id +
        "?lang=" +
        this.langauge.lang +
        "&order_by=" +
        order_by,
      this.options
    );
  }


  // accept_cargo(id): Observable<any> {
  //   //this.update_token();
  //   this.update_token();
  //   return this.http.patch(environment.api_url+'carrier/cargos/accept/'+id+'?lang='+this.langauge.lang,this.options);
  // }
  accept_cargo(id): Observable<any> {
    this.update_token();
    var data = { lang: this.langauge.lang };
    return this.http.patch(
      environment.api_url + "carrier/cargos/accept/" + id,
      data,
      this.options
    );
  }

  cancel_package(id): Observable<any> {
    this.update_token();
    var data = { package_id: id, lang: this.langauge.lang };
    return this.http.post(
      environment.api_url + "customer/package/cancel",
      data,
      this.options
    );
  }
  delete_card(card): Observable<any> {
    this.update_token();
    var data = { id: card.id, card_id: card.card_id, lang: this.langauge.lang };

    return this.http.post(
      environment.api_url + "customer/card/delete",
      data,
      this.options
    );
  }

  delete_profile_img(): Observable<any> {
    this.update_token();
    var data = { lang: this.langauge.lang };

    return this.http.post(
      environment.api_url + "user/profileimage/delete",
      data,
      this.options
    );
  }

  lat_long(origin, destination): Observable<any> {
    return this.http.get(
      environment.api_url +
        "admin/package/distance_time?origins=" +
        origin +
        "&destinations=" +
        destination,
      this.options
    );
  }

  carrier_cargo_list(data): Observable<any> {
    this.update_token();
    if (!data.travel_by) {
      data.travel_by = "";
    }
    if(!data.city){
      data.city = '';
    }
    return this.http.get(
      environment.api_url +
        "carrier/cargos?offset=" +
        data.offset +
        "&limit=" +
        data.limit +
        "&lang=" +
        this.langauge.lang +
        "&travel_by=" +
        data.travel_by +
        "&city=" +
        data.city,
    );
  }

  carrier_login_cargo_list(data): Observable<any> {
    this.update_token();
    if (!data.travel_by) {
      data.travel_by = "";
    }
    if(!data.city){
      data.city = '';
    }
    return this.http.get(
      environment.api_url +
        "carrier/cargos_list?offset=" +
        data.offset +
        "&limit=" +
        data.limit +
        "&lang=" +
        this.langauge.lang +
        "&travel_by=" +
        data.travel_by +
        "&city=" +
        data.city,
      this.options
    );
  }


  city_lists(data): Observable<any> {
    this.update_token();

    return this.http.get(
      environment.api_url +
        'version_3.0/user/city',
      this.options
    );
  }

  food_client_cargo_list(data): Observable<any> {
    this.update_token();
    if (!data.travel_by) {
      data.travel_by = "";
    }
    return this.http.get(
      environment.api_url +
        "version_3.0/client/cargos?offset=" +
        data.offset +
        "&limit=" +
        data.limit +
        "&lang=" +
        this.langauge.lang +
        "&travel_by=" +
        data.travel_by,
      this.options
    );
  }

  confirm_pickup(send_data): Observable<any> {
    this.update_token();
    send_data.lang = this.langauge.lang;
    return this.http.post(
      environment.api_url + "version_2.0/carrier/package/pickup",
      send_data,
      this.options
    );
  }

  confirm_delivery(send_data): Observable<any> {
    this.update_token();
    send_data.lang = this.langauge.lang;
    return this.http.post(
      environment.api_url + "version_2.0/carrier/package/deliver",
      send_data,
      this.options
    );
  }

  delete_notification(id): Observable<any> {
    //var id = id.join();
    this.update_token();
    var data = {
      lang: this.langauge.lang
    };
    return this.http.post(
      environment.api_url + "user/notification/delete/" + id,
      data,
      this.options
    );
  }
  delete_cargo_service(id): Observable<any> {
    //var id = id.join();
    this.update_token();
    var data = {
      lang: this.langauge.lang
    };
    return this.http.post(
      environment.api_url + "carrier/cargos/archive/" + id,
      data,
      this.options
    );
  }

  create_account(form): Observable<any> {
    this.update_token();
    // var admin_creds = {
    //   email:form.email
    //   lang:this.langauge.lang
    // };
    return this.http.get(
      environment.api_url + "version_1.0/create/customer/",
      this.options
    );
  }

  get_price(): Observable<any> {
    this.update_token();
    return this.http.get(
      environment.api_url + "version_2.0/price?lang=" + this.langauge.lang,
      this.options
    );
  }



  ediit_client_cargo(cargoId): Observable<any> {
    this.update_token();
    const sendData = {
      cargo_id: cargoId,
      is_prepare: 'yes',
      lang: this.langauge.lang,
    };
    return this.http.post(
      environment.api_url + 'version_3.0/client/cargos',
      sendData,
      this.options
    );
  }
}
