import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Routes, RouterModule } from '@angular/router';
import { ProfileComponent } from './profile/profile.component';
import { SignupComponent } from './signup/signup.component';
import { TermsConditionComponent } from './terms-condition/terms-condition.component';
import { AppCustomPreloader } from './app-routing-loader';

const routes: Routes = [


  { path:'login', loadChildren: () => import('./login/login.module').then(m => m.LoginModule)},
  { path:'', loadChildren: () => import('./user/user.module').then(m => m.UserModule)},

  { path:'profile', component:ProfileComponent},
  { path:'signup', component:SignupComponent},
  { path:':slug', component:TermsConditionComponent},
  { path:':slug/:lang', component:TermsConditionComponent},
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes,{ preloadingStrategy: AppCustomPreloader, relativeLinkResolution: 'legacy' })
  ],
  declarations: [],
  exports: [ RouterModule ],
  providers: [AppCustomPreloader],
})
export class AppRoutingModule { }
