import { Component, OnInit,OnDestroy } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from "./../../environments/environment";
import { DataService } from "../user/data.service"; 
import { UserService } from "../user/user.service";
import { Router } from '@angular/router';
import { AlertService } from "./../_services/index";
import { NgForm } from '@angular/forms';
import{LoaderService} from './../loader/loader.service';
import { Socket } from 'ngx-socket-io';
declare function escape(s:string): string;
import { ActivatedRoute } from "@angular/router";


@Component({
  selector: 'app-terms-condition',
  templateUrl: './terms-condition.component.html',
  styleUrls: ['./terms-condition.component.scss']
})
export class TermsConditionComponent implements OnInit {
  app_constant:any;
	change_langauge_service_data:any='';
	app_lang:any={};
	about_app:any={};
  message:any;
  page_height:any;
  parentLangauge:any;
  sub:any;
  content_type:any;
  type:any;
  langauge:any;
  constructor(private dataService: DataService,private userService:UserService, private alertService: AlertService, private router: Router,
		private loaderservice:LoaderService,public socket: Socket,private route:ActivatedRoute,private activatedRoute: ActivatedRoute,) {
      this.activatedRoute.params.subscribe((params)=>{
        this.sub = this.route.params.subscribe(params => {
          this.content_type = params['slug'];
          this.langauge = params['lang'];
          if(this.content_type=='terms-condition'){
            this.type = 'terms_and_condition';
          }
          if(this.content_type=='privacy-policy'){
            this.type = 'privacy_policy';
          } 
          if(this.content_type=='about-us'){
            
            this.type = 'about_app';
          }
         // alert(this.content_type)
       });
      })
     }

    ngOnInit() {
      this.page_height = window.screen.height-300;
      this.app_constant = environment;
      var encryptedData = localStorage.getItem('app_langauge');
      if(this.langauge){
        this.app_lang.lang='_'+this.langauge;
        var user_data = {lang:'_'+this.langauge};
        var icarryit_web_lang = CryptoJS.AES.encrypt(JSON.stringify(user_data), environment.secret_key).toString();
        localStorage.setItem('app_langauge', icarryit_web_lang);
        if(this.langauge=='EN'){
          var new_lang = 'en';
        }else if(this.langauge == 'SW'){
          var new_lang = 'sv';
        }else{
          var new_lang = 'fi';
        }
        localStorage.setItem('gpl', new_lang);
       
      }else{
        if(encryptedData){
          this.app_lang = JSON.parse(CryptoJS.AES.decrypt(encryptedData, environment.secret_key).toString(CryptoJS.enc.Utf8));
        }else{
          this.app_lang.lang='_EN';
        }
      }

      //alert('ssss'+this.app_lang.lang)
     
      this.change_langauge_service_data = this.dataService.change_langauge.subscribe(data =>{
        if(data.langauge){
          this.app_lang.lang = data.langauge;
          this.refresPage()
        }
      })
      this.userService.terms_condition(this.type,this.app_lang.lang).subscribe(data=> {
        var response = JSON.parse(data['_body']);
        if(data['status'] == 200){
          this.loaderservice.change_loader_status(false);
          if(response.status == 3){
            this.userService.log_out();
            this.alertService.success(response.message);
            localStorage.removeItem('users');
            localStorage.removeItem('send_item');
            localStorage.removeItem('current_cargo');
            localStorage.removeItem('select_cargo_id');
            this.router.navigate(['/']);
            this.socket.disconnect(environment.chat_url);
          }else{
            this.about_app = response.result;
            //var a= response.result.content;
            //var a = atob(response.result.content);
            var a = decodeURIComponent( escape( atob( response.result.content ) ) )
            console.log(a);
            this.about_app.content = a;
            //console.log(atob(response.result.content))
            if(!this.about_app){
              this.about_app = [];
              this.message = response.message;
            }
          }
          
        }
      },(err)=>{
        console.log(err)
        if(err.status == 0){
          this.alertService.error('CONNECTION REFUSED');
        }else if(err.status == 500){
          this.alertService.error(err.statusText);
        }else if(err.status == 404){
          this.alertService.error(err.statusText);
        }else{
          var res = JSON.parse(err['_body']);
          this.alertService.error(res.message);
        }
        this.loaderservice.change_loader_status(false);
        
  
      });
    }

    refresPage(){
      this.userService.terms_condition(this.type,this.app_lang.lang).subscribe(data=> {
        var response = JSON.parse(data['_body']);
        if(data['status'] == 200){
          this.loaderservice.change_loader_status(false);
          if(response.status == 3){
            this.userService.log_out();
            this.alertService.success(response.message);
            localStorage.removeItem('users');
            localStorage.removeItem('send_item');
            localStorage.removeItem('current_cargo');
            localStorage.removeItem('select_cargo_id');
            this.router.navigate(['/']);
            this.socket.disconnect(environment.chat_url);
          }else{
            this.about_app = response.result;
            //var a= response.result.content;
            //var a = atob(response.result.content);
            var a = decodeURIComponent( escape( window.atob( response.result.content ) ) )
            console.log(a);
            this.about_app.content = a;
            //console.log(atob(response.result.content))
            if(!this.about_app){
              this.about_app = [];
              this.message = response.message;
            }
          }
          
        }
      },(err)=>{
        console.log(err)
        if(err.status == 0){
          this.alertService.error('CONNECTION REFUSED');
        }else if(err.status == 500){
          this.alertService.error(err.statusText);
        }else if(err.status == 404){
          this.alertService.error(err.statusText);
        }else{
          var res = JSON.parse(err['_body']);
          this.alertService.error(res.message);
        }
        this.loaderservice.change_loader_status(false);
        
  
      });
    }

    ngOnDestroy(){
      if(this.change_langauge_service_data){
        this.change_langauge_service_data.unsubscribe();
      }
    }
    goToChangeLang(langauge: string): void {
      this.app_lang.lang=langauge;
      this.parentLangauge=langauge;
      this.refresPage()
    }
}
