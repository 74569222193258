import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private loaderStatus = new BehaviorSubject(status);
  currentMessage = this.loaderStatus.asObservable();

  private currentUrl = new BehaviorSubject(status);
  currentUrlMessager = this.currentUrl.asObservable();

  private geData = new BehaviorSubject({ "type": "",id:"",new_user_id:"",delete_img:""});
  getDataService = this.geData.asObservable();

  private getActivityData = new BehaviorSubject({ "type": "",id:"",cargo_info:{user_id:'',id:''},delete_img:""});
  getActivityDataService = this.getActivityData.asObservable();

  getactivityDataService


  constructor() { }
  change_loader_status(status:any) {
    this.loaderStatus.next(status)
  }

  change_current_url(status:any) {
    this.currentUrl.next(status)
  }

  get_data_service(status:any) {
    this.geData.next(status)
  }

  get_activity_data_service(status:any) {
    this.getActivityData.next(status)
  }
}
