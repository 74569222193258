<section class="sign_up" style="margin:-24px;">
    <div class="sign_form p-4">
        <div class="text-center pb-4">
            <h5 class="text-center border-heading">{{app_constant['UPDATE_MOBILE_NUMBER'+app_lang?.lang]}}</h5>
        </div>
        <div class="row justify-content-center align-items-centerc">
            <div class="col-12">
                <form (ngSubmit)="changeNumber(registrationForm.valid)" #registrationForm="ngForm">
                    <!-- <div [ngClass]="{'error':holder_name.errors && (holder_name.dirty || addCardForm.submitted)} " class="name_input box-shadow">
                        <label>{{app_constant['NAME'+app_lang?.lang]}}:</label><input type="text" #holder_name="ngModel" [(ngModel)]="model.phone_no" maxlength="255" name="holder_name" placeholder="Enter Name" required>
                    </div> -->
                    <div class="in-fild country-code">
                        <span class="icon-phone"></span>
                        <input required #country_code="ngModel" [ngClass]="{'error':country_code.errors?.required && (country_code.dirty || country_code.touched || registrationForm.submitted)}" name="country_code" [(ngModel)]="model.country_code" type="text" class="co_input"
                            value="+91" pattern="[0-9]+" maxlength="3" placeholder="+1">
                        <input #phone_no="ngModel" pattern="[0-9]+" maxlength="12" [(ngModel)]="model.phone_no" type="text" name="phone" placeholder="{{app_constant['PHONE_NUMBER'+app_lang?.lang]}}" class="form-control shadow-input" required [ngClass]="{'error':phone_no.errors && (phone_no.dirty || phone_no.touched || registrationForm.submitted)}">

                    </div>
                    <div class="text-center send mt-5">
                        <button (click)="close()" type="button" class="btn btn-warning mr-3 min-btn">
                                    
                                     {{app_constant['CANCEL_BUTTON'+app_lang?.lang]}}
                                     </button>
                        <button type="submit" [disabled]="btn_disable" class="btn btn-warning min-btn">
                        <i [ngClass]="{'fa fa-circle-notch fa-spin':btn_disable}"></i>
                        {{app_constant['SAVE_BUTTON'+app_lang?.lang]}}
                        </button>


                    </div>
                </form>
            </div>
        </div>
    </div>
</section>