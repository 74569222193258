import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationsComponent } from './navigations.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { Routes, RouterModule } from '@angular/router';
import { PipeModule } from '../pipemodule/pipe.module';

import { FormsModule } from "@angular/forms";
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    PipeModule
  ],
  declarations: [NavigationsComponent, HeaderComponent, FooterComponent],
  exports:[NavigationsComponent, HeaderComponent,FooterComponent],
})
export class NavigationsModule { }
