<app-header (goChangeLang)="goToChangeLang($event)">
</app-header>
<section class="sign_up">
    <div class="common_header">
        <div class="container">
            <div class="row">
                <div class="col-12 text-left">
                    <p class="mt-3 mb-3">
                        <!-- <a routerLink="/user/create-package">Home </a> <span class="icon-left-arrow"></span><a> {{app_constant['ABOUT_APP_HEADING'+app_lang?.lang]}} </a> -->
                    </p>
                    <h2 *ngIf="content_type == 'terms-condition'" class="mb-4 text-center">{{app_constant['TERM_CONDITION_HEADING'+app_lang?.lang]}}</h2>
                    <h2 *ngIf="content_type == 'privacy-policy'" class="mb-4 text-center">{{app_constant['PRIVACY_POLICY'+app_lang?.lang]}}</h2>

                    <h2 *ngIf="content_type == 'about-us'" class="mb-4 text-center">{{app_constant['ABOUT_APP_HEADING'+app_lang?.lang]}}</h2>




                </div>
            </div>
        </div>
    </div>
    <div class="container">
        <div class="row justify-content-center">
            <div class="head_social about_social text-center">
                <a href="javascript:void(0)"><span class="icon-facebook"></span></a>
                <a href="javascript:void(0)"><span class="icon-twitter"></span></a>
                <a href="javascript:void(0)"><span class="icon-email-fil"></span></a>
                <a href="javascript:void(0)"><span class="icon-share"></span></a>
            </div>
        </div>
    </div>
    <div class="pt-5 pb-5">
        <div class="container">
            <div class="row justify-content-center align-items-center">
                <div class="col-12">
                    <div class="about_content" [innerHtml]="about_app?.content | safeHtml">

                        <hr />
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<app-footer [childLangauge]="parentLangauge"></app-footer>