// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const currency = '€';

export const environment = {
  production: false,

  // developement url setting
  //  app_url:'http://52.17.196.40/web/',
  //  api_url:'http://52.17.196.40/api/',
  //  chat_url:'http://52.17.196.40:5000',
  //  profile_image:'http://52.17.196.40/uploads/user_images/',
  //  pickup_image:'http://52.17.196.40/uploads/pickup_image/',
  //  signature_image:'http://52.17.196.40/uploads/signature/',
  //  delivery_image:'http://52.17.196.40/uploads/delivery_image/',
  //  id_proof_image:'http://52.17.196.40/uploads/idproof/',
  //  web_url:'http://52.17.196.40/web/',

   //production url setting
  app_url:'https://web.fiuge.com/web/',
  api_url:'https://web.fiuge.com/api/',
  chat_url:'https://socket.fiuge.com/',
  profile_image:'https://web.fiuge.com/uploads/user_images/',
  pickup_image:'https://web.fiuge.com/uploads/pickup_image/',
  signature_image:'https://web.fiuge.com/uploads/signature/',
  delivery_image:'https://web.fiuge.com/uploads/delivery_image/',
  id_proof_image:'https://web.fiuge.com/uploads/idproof/',
  web_url:'https://web.fiuge.com/web/',

  // local url setting
  //  app_url: 'http://192.168.1.57:4700',
  // web_url: 'http://192.168.1.57/icarryit-web-new/src/',
  // api_url: 'http://192.168.1.207:9000/',
  // chat_url: 'http://192.168.1.207:5900/',
  // profile_image: 'http://192.168.1.207/icarryit/uploads/user_images/',
  // pickup_image: 'http://192.168.1.207/icarryit/uploads/pickup_image/',
  // signature_image: 'http://192.168.1.207/icarryit/uploads/signature/',
  // delivery_image: 'http://192.168.1.207/icarryit/uploads/delivery_image/',
  // id_proof_image: 'http://192.168.1.207/icarryit/uploads/idproof/',

  secret_key: 'abcdefghijklmnop123456',
  user_no_image: 'assets/images/user.png',
  currency: currency,
  app_version: '1.0',
  request_data_time: 'E dd MMMM, yyyy hh:mm a',
  new_request_data_time: 'E dd MMMM, yyyy HH:mm',
  carrier_request_data_time: 'E MMMM dd',
  route_detail_data_time: 'E MMM dd',
  carrier_request_year: 'Y',
  pick_date_time: 'E dd MMMM',

  SWITC_TO_CARRIER_EN: 'Switch to Carrier',
  SWITC_TO_CARRIER_SW: 'Switch to Carrier(SW)',
  SWITC_TO_CARRIER_FI: 'Kuljettajalle',

  SWITC_TO_CUSTOMER_EN: 'Switch to Customer',
  SWITC_TO_CUSTOMER_SW: 'Switch to Customer(SW)',
  SWITC_TO_CUSTOMER_FI: 'Lähettäjälle',

  PACKAGE_COUNT_EN: 'Package',
  PACKAGE_COUNT_SW: 'Package(SW)',
  PACKAGE_COUNT_FI: 'Paketti',

  LOGIN_REGISTRATION_EN: 'Login/Signup',
  LOGIN_REGISTRATION_SW: 'Logga in/Registrera dig',
  LOGIN_REGISTRATION_FI: 'Kirjaudu/Rekisteröidy',

  FORGOT_PASSWORD_EN: 'Forgot Password',
  FORGOT_PASSWORD_SW: 'Glömt lösenord',
  FORGOT_PASSWORD_FI: 'Unohtuiko salasana',

  HOME_LINK_EN: 'Home',
  HOME_LINK_SW: 'Home(SW)',
  HOME_LINK_FI: 'Etusivu',

  PICKUP_IMAGES_EN: 'Pickup Image',
  PICKUP_IMAGES_SW: 'Pickup Image(SW)',
  PICKUP_IMAGES_FI: 'Paketin kuva noudettaessa',

  DELIVER_IMAGES_EN: 'Deliver Image',
  DELIVER_IMAGES_SW: 'Deliver Image(SW)',
  DELIVER_IMAGES_FI: 'Paketin kuva toimitettaessa',

  DELEVERED_TO_EN: 'Delivered To',
  DELEVERED_TO_SW: 'Delivered To(SW)',
  DELEVERED_TO_FI: 'Nimenselvennys',

  CHAT_EN: 'Chat',
  CHAT_SW: 'Chat(SW)',
  CHAT_FI: 'CHAT',

  PACKAGE_PHOTOGRAPH_EN: 'Package Photograph',
  PACKAGE_PHOTOGRAPH_SW: 'Paketfoto',
  PACKAGE_PHOTOGRAPH_FI: 'Kuva paketista',

  NOTIFICATION_SETTINGS_EN: 'NOTIFICATION SETTINGS',
  NOTIFICATION_SETTINGS_SW: 'NOTIFICATION SETTINGS(SW)',
  NOTIFICATION_SETTINGS_FI: 'Viestiasetukset',

  NOTIFICATION_PAGE_HEAD_EN:
    'Check this section for updates on your booking requests,our services, offers and general notifications.',
  NOTIFICATION_PAGE_HEAD_SW:
    'Check this section for updates on your booking requests,our services, offers and general notifications.(SW)',
  NOTIFICATION_PAGE_HEAD_FI:
    'Täältä löydät ajankohtaista tietoa varauksistasi, palveluistamme ja tarjouksistamme.',

  VIEW_DELIVERY_INFORMATION_EN: 'View Delivery Information',
  VIEW_DELIVERY_INFORMATION_SW: 'Titta på leveransinformation',
  VIEW_DELIVERY_INFORMATION_FI: 'Näytä toimitustiedot',

  CUSTOMER_SIGNATURE_EN: 'Customer Signature',
  CUSTOMER_SIGNATURE_SW: 'Kundsignatur',
  CUSTOMER_SIGNATURE_FI: 'Asiakkaan allekirjoitus',

  SIGNED_DELIVERY_EN: 'Signed Delivery',
  SIGNED_DELIVERY_SW: 'Underskriven leverans',
  SIGNED_DELIVERY_FI: 'Toimitus kuitattu',

  SIGNUP_LINK_EN: 'Sign Up',
  SIGNUP_LINK_SW: 'Registrera dig',
  SIGNUP_LINK_FI: 'Rekisteröidy',

  CREATE_ACCOUNT_EN: 'Sign Up Today',
  CREATE_ACCOUNT_SW: 'Sign Up Today(SW)',
  CREATE_ACCOUNT_FI: 'Rekisteröidy tänään',

  ALL_IN_ONE_EN: 'All-in-One Courier Service',
  ALL_IN_ONE_SW: 'All-in-One Courier Service(SW)',
  ALL_IN_ONE_FI: 'Kaikki yhdessä palvelussa',

  SET_THE_PRICE_EN: 'Set the Price that You Pay',
  SET_THE_PRICE_SW: 'Set the Price that You Pay(SW)',
  SET_THE_PRICE_FI: 'Sinä päätät hinnan',

  UP_TO_DATE_EN: 'Up-to-Date Delivery Notifications',
  UP_TO_DATE_SW: 'Up-to-Date Delivery Notifications(SW)',
  UP_TO_DATE_FI: 'Ajantasaiset ilmoitukset',

  EASY_AND_SECURE_EN: 'Easy & Secure In-App Payment',
  EASY_AND_SECURE_SW: 'Easy & Secure In-App Payment(SW)',
  EASY_AND_SECURE_FI: 'Helppo ja turvallinen maksaminen',

  CONTACT_MORE_INFO_EN: 'Contact us today for more information',
  CONTACT_MORE_INFO_SW: 'Contact us today for more information(SW)',
  CONTACT_MORE_INFO_FI: 'Kysy meiltä lisätietoja',

  USERNAME_PLACEHOLDER_EN: 'Username',
  USERNAME_PLACEHOLDER_SW: 'Username(SW)',
  USERNAME_PLACEHOLDER_FI: 'Username(FI)',

  PASSWORD_PLACEHOLDER_EN: 'Password',
  PASSWORD_PLACEHOLDER_SW: 'Lösenord',
  PASSWORD_PLACEHOLDER_FI: 'Salasana',

  OR_LOGIN_WITH_EN: 'Or Login With',
  OR_LOGIN_WITH_SW: 'Eller logga in med',
  OR_LOGIN_WITH_FI: 'Tai kirjaudu käyttämällä',

  FACEBOOK_EN: 'Facebook',
  FACEBOOK_SW: 'Facebook',
  FACEBOOK_FI: "Facebook",

  GOOGLE_EN: "Google",
  GOOGLE_SW: "Google",
  GOOGLE_FI: "Google",

  DONT_HAVE_ACCOUNT_EN: "Don't have an account?",
  DONT_HAVE_ACCOUNT_SW: "Har du inte ett konto?",
  DONT_HAVE_ACCOUNT_FI: "Puuttuuko käyttäjätunnus?",

  SUBMIT_EN: "Submit",
  SUBMIT_SW: "Submit(SW)",
  SUBMIT_FI: "Lähetä",

  DISTANCE_UNIT_EN: "km",
  DISTANCE_UNIT_SW: "Km",
  DISTANCE_UNIT_FI: "km",

  LOGIN_BT_EN: "Login",
  LOGIN_BT_SW: "Logga in",
  LOGIN_BT_FI: "Kirjaudu",

  SHOW_ORDER_HISTORY_EN: "Show Order History",
  SHOW_ORDER_HISTORY_SW: "Visa orderhistorik",
  SHOW_ORDER_HISTORY_FI: "NÄYTÄ LÄHETYSHISTORIA",

  MESSAGE_CARRIER_EN: "Message Carrier",
  MESSAGE_CARRIER_SW: "Meddelandeoperatör",
  MESSAGE_CARRIER_FI: "Viesti kuljettajalle",

  SHOW_ON_MAP_EN: "SHOW ON MAP",
  SHOW_ON_MAP_SW: "SHOW ON MAP(SW)",
  SHOW_ON_MAP_FI: "Näytä kartalla",

  ACCEPT_EN: "Accept",
  ACCEPT_SW: "Acceptera",
  ACCEPT_FI: "Hyväksy",

  SIGN_LINK_EN: "Sign Up Here",
  SIGN_LINK_SW: "Registrera dig här",
  SIGN_LINK_FI: "Rekisteröidy täältä",

  //footer
  COPY_R_EN: "Copyright Fiuge Oy",
  COPY_R_SW: "Copyright Fiuge Oy",
  COPY_R_FI: "Copyright Fiuge Oy",

  YOU_ARE_HERE_EN: "You are here",
  YOU_ARE_HERE_SW: "You are here(SW)",
  YOU_ARE_HERE_FI: "Olet täällä",

  MORE_INFO_EN: "For more information, please contact us at",
  MORE_INFO_SW: "For more information, please contact us at(SW)",
  MORE_INFO_FI: "Lisätietoja saat lähettämällä sähköpostia osoitteeseen",

  INFO_LINK_EN: "info@fiuge.com",
  INFO_LINK_SW: "info@fiuge.com(SW)",
  INFO_LINK_FI: "info@fiuge.com",

  //sign form

  AC_HEADING_EN: "Account Information",
  AC_HEADING_SW: "Kontoinformation",
  AC_HEADING_FI: "Käyttäjätilin tiedot",

  PER_HEADING_EN: "Personal Details",
  PER_HEADING_SW: "Personliga detaljer",
  PER_HEADING_FI: "Henkilötiedot",

  VERFIY_HEADING_EN: "Verify",
  VERFIY_HEADING_SW: "Verifiera",
  VERFIY_HEADING_FI: "Varmennus",

  CONFIRM_PAS_PLACEHOLDER_EN: "Confirm Password",
  CONFIRM_PAS_PLACEHOLDER_SW: "Bekräfta lösenord",
  CONFIRM_PAS_PLACEHOLDER_FI: "Vahvista salasana",

  S_EMAIL_EN: "Enter Email Address",
  S_EMAIL_SW: "Ange e-postadress",
  S_EMAIL_FI: "Syötä sähköpostiosoite",

  FIRST_NAME_EN: "First Name",
  FIRST_NAME_SW: "Förnamn",
  FIRST_NAME_FI: "Etunimi",

  PRINTED_NAME_EN: "Deliver To",
  PRINTED_NAME_SW: "Deliver To(SW)",
  PRINTED_NAME_FI: "Nimenselvennys",

  CONTACT_INFO_EN: "Contact Info",
  CONTACT_INFO_SW: "Contact Info(SW)",
  CONTACT_INFO_FI: "Contact Info(FI)",

  DATE_OF_BIRTH_EN: "Date of Birth",
  DATE_OF_BIRTH_SW: "Födelsedag",
  DATE_OF_BIRTH_FI: "Syntymäaika",

  LAST_NAME_EN: "Last Name",
  LAST_NAME_SW: "Efternamn",
  LAST_NAME_FI: "Sukunimi",

  PHONE_NUMBER_EN: "Enter Phone Number",
  PHONE_NUMBER_SW: "Ange telefonnummer",
  PHONE_NUMBER_FI: "Syötä puhelinnumero",

  SAVE_DETAILS_EN: "Save Details",
  SAVE_DETAILS_SW: "Spara detaljer",
  SAVE_DETAILS_FI: "Tallenna tiedot",

  VERIFY_ACCOUNT_EN: "Verify account",
  VERIFY_ACCOUNT_SW: "Verifiera konto",
  VERIFY_ACCOUNT_FI: "Varmista käyttäjätili",

  SAVE_BUTTON_EN: "Save",
  SAVE_BUTTON_SW: "Spara",
  SAVE_BUTTON_FI: "Tallenna",

  BACK_BUTTON_EN: "Back",
  BACK_BUTTON_SW: "Back(SW)",
  BACK_BUTTON_FI: "TAKAISIN",

  CANCEL_BUTTON_EN: "Cancel",
  CANCEL_BUTTON_SW: "Avbryt",
  CANCEL_BUTTON_FI: "Ei",

  CANCEL_TRACKPACKAGE_BUTTON_EN: "Cancel",
  CANCEL_TRACKPACKAGE_BUTTON_SW: "Avbryt",
  CANCEL_TRACKPACKAGE_BUTTON_FI: "Peruuta",

  YES_DELETE_IT_EN: "Yes Delete it",
  YES_DELETE_IT_SW: "Yes Delete it(SW)",
  YES_DELETE_IT_FI: "Poista kortti",

  YES_REMOVE_IT_EN: "Yes Delete it",
  YES_REMOVE_IT_SW: "Yes Delete it(SW)",
  YES_REMOVE_IT_FI: "Poista kuva",

  YES_REMOVE_CARGO_EN: "Yes Delete it",
  YES_REMOVE_CARGO_SW: "Yes Delete it(SW)",
  YES_REMOVE_CARGO_FI: "Poista rahti",

  YES_DELETE_NOTIFICATION_EN: "Yes Delete it",
  YES_DELETE_NOTIFICATION_SW: "Yes Delete it(SW)",
  YES_DELETE_NOTIFICATION_FI: "Poista ilmoitus",

  YES_CANCEL_IT_EN: "Yes, cancel it!",
  YES_CANCEL_IT_SW: "Yes, cancel it!(SW)",
  YES_CANCEL_IT_FI: "Peru lähetys",

  YES_ACCEPT_IT_EN: "Yes, accept it!",
  YES_ACCEPT_IT_SW: "Yes, accept it!(SW)",
  YES_ACCEPT_IT_FI: "Hyväksy",

  CONFIRM_PICKUP_EN: "Confirm Pickup",
  CONFIRM_PICKUP_SW: "Bekräfta upphämtning",
  CONFIRM_PICKUP_FI: "Vahvista nouto",

  PICKUP_CONFIRMED_EN: "Pickup Confirmed",
  PICKUP_CONFIRMED_SW: "Upphämtning bekräftad",
  PICKUP_CONFIRMED_FI: "Vahvistettu",

  ARE_YOU_SURE_EN: "Are you sure?",
  ARE_YOU_SURE_SW: "Are you sure?(SW)",
  ARE_YOU_SURE_FI: "Oletko varma?",

  WANT_TO_CANCEL_PACKAGE_EN: "Do you want to cancel this package?",
  WANT_TO_CANCEL_PACKAGE_SW: "Do you want to cancel this package?(SW)",
  WANT_TO_CANCEL_PACKAGE_FI: "Haluatko peruuttaa lähetyksen?",

  WANT_TO_ACCEPT_CARGO_EN: "Want to accept this cargo!",
  WANT_TO_ACCEPT_CARGO_SW: "Want to accept this cargo!(SW)",
  WANT_TO_ACCEPT_CARGO_FI: "Haluatko hyväksyä rahdin?",

  WANT_TO_DELETE_CARD_EN: "Want to delete this card!",
  WANT_TO_DELETE_CARD_SW: "Want to delete this card!(SW)",
  WANT_TO_DELETE_CARD_FI: "Haluatko poistaa kortin?",

  WANT_TO_DELETE_CARGO_EN: "Want to delete this cargo!",
  WANT_TO_DELETE_CARGO_SW: "Want to delete this cargo!(SW)",
  WANT_TO_DELETE_CARGO_FI: "Oletko varma, että haluat poistaa tämän rahdin?",

  WANT_TO_DELETE_NOTIFICATION_EN: "Want to delete this notification!",
  WANT_TO_DELETE_NOTIFICATION_SW: "Want to delete this notification!(SW)",
  WANT_TO_DELETE_NOTIFICATION_FI: "Haluatko poistaa ilmoituksen?",

  WANT_TO_DELETE_PROFILE_IMAGE_EN: "Want to delete this profile image!",
  WANT_TO_DELETE_PROFILE_IMAGE_SW: "Want to delete this profile image!(SW)",
  WANT_TO_DELETE_PROFILE_IMAGE_FI: "Haluatko poistaa profiilikuvan?",

  COMP_SING_LOGIN_EN: "Complete Sing Up & Login",
  COMP_SING_LOGIN_SW: "Complete Sing Up & Login(SW)",
  COMP_SING_LOGIN_FI: "Kirjaudu / Rekisteröidy",

  RESEND_CODE_EN: "Resend Code",
  RESEND_CODE_SW: "Skicka kod igen",
  RESEND_CODE_FI: "Tarvitsen uuden koodin",

  S_FORM_CONTENT_EN: "I would like to sign up as a",
  S_FORM_CONTENT_SW: "Jag skulle vilja registrara mig som en",
  S_FORM_CONTENT_FI: "Haluaisin rekisteröityä",

  ENTER_CODE_EN: "Enter code here",
  ENTER_CODE_SW: "Ange kod här",
  ENTER_CODE_FI: "Syötä koodi tähän",

  RESEND_CONTET_EN:
    "We have sent you an access code via SMS for mobile number verification.",
  RESEND_CONTET_SW:
    "We have sent you an access code via SMS for mobile number verification.(SW)",
  RESEND_CONTET_FI:
    "Olemme lähettäneet tekstiviestillä vahvistuskoodin puhelinnumerosi varmentamiseksi.",

  SENDER_BTN_EN: "SENDER",
  SENDER_BTN_SW: "Avsändare",
  SENDER_BTN_FI: "Lähettäjä",

  CARRIER_BTN_EN: "CARRIER",
  CARRIER_BTN_SW: "Operatör",
  CARRIER_BTN_FI: "Kuljettaja",

  BOTH_BTN_EN: "BOTH",
  BOTH_BTN_SW: "Båda",
  BOTH_BTN_FI: "Molemmat",

  EMAIL_EN: "Email",
  EMAIL_SW: "Email(SW)",
  EMAIL_FI: "Sähköposti",

  EMAIL_NEW_EN: "Email",
  EMAIL_NEW_SW: "Email(SW)",
  EMAIL_NEW_FI: "Sähköpostitse",

  ENTER_PACKAGE_REFERENCE_EN: "Enter package reference is relevant",
  ENTER_PACKAGE_REFERENCE_SW: "Enter package reference is relevant(SW)",
  ENTER_PACKAGE_REFERENCE_FI: "Paketin seurantatunnus",

  TELEPHONE_EN: "Telephone",
  TELEPHONE_SW: "Telephone(SW)",
  TELEPHONE_FI: "Puhelimitse",

  POST_EN: "Post",
  POST_SW: "Post(SW)",
  POST_FI: "Kirjeitse",

  SUBMIT_SUPPORT_REQUEST_EN: "Submit Support Request",
  SUBMIT_SUPPORT_REQUEST_SW: "Submit Support Request(SW)",
  SUBMIT_SUPPORT_REQUEST_FI: "LÄHETÄ VIESTI TUKEEN",

  PHONE_NO_EN: "Phone No",
  PHONE_NO_SW: "Phone No(SW)",
  PHONE_NO_FI: "Puhelinnumero",

  EMAIL_REQUIRED_EN: "The email is required",
  EMAIL_REQUIRED_SW: "The email is required.(SW)",
  EMAIL_REQUIRED_FI: "Sähköposti vaaditaan",

  ADDRESS_REQUIRED_EN: "The address is required",
  ADDRESS_REQUIRED_SW: "The address is required.(SW)",
  ADDRESS_REQUIRED_FI: "Osoite vaaditaan",

  CORRECT_EMAIL_EN: "Please enter correct email",
  CORRECT_EMAIL_SW: "Please enter correct email.(SW)",
  CORRECT_EMAIL_FI: "Sähköpostiosoite ei ole kelvollinen",

  PHONE_NUMBER_REQUIRED_EN: "The phone number is required",
  PHONE_NUMBER_REQUIRED_SW: "The phone number is required.(SW)",
  PHONE_NUMBER_REQUIRED_FI: "Puhelinnumero vaaditaan",

  CORRECT_PHONE_NUMBER_EN: "Phone number not valid.",
  CORRECT_PHONE_NUMBER_SW: "Phone number not valid.(SW)",
  CORRECT_PHONE_NUMBER_FI: "Puhelinnumero ei ole kelvollinen",

  PHONE_NUMBER_TO_SHORT_EN: "Phone number too short.",
  PHONE_NUMBER_TO_SHORT_SW: "Phone number too short.(SW)",
  PHONE_NUMBER_TO_SHORT_FI: "Puhelinnumero on liian lyhyt",

  PICK_PHONE_NUMBER_TO_SHORT_EN: "Pickup phone number too short.",
  PICK_PHONE_NUMBER_TO_SHORT_SW: "Pickup phone number too short.(SW)",
  PICK_PHONE_NUMBER_TO_SHORT_FI: "Lähettäjän puhelinnumero on liian lyhyt",

  DELIVERY_PHONE_NUMBER_TO_SHORT_EN: "Delivery phone number too short.",
  DELIVERY_PHONE_NUMBER_TO_SHORT_SW: "Delivery phone number too short.(SW)",
  DELIVERY_PHONE_NUMBER_TO_SHORT_FI:
    "Vastaanottajan puhelinnumero on liian lyhyt",

  PASSWORD_TO_SHORT_EN: "Password too short.",
  PASSWORD_TO_SHORT_SW: "Password too short.(SW)",
  PASSWORD_TO_SHORT_FI: "Salasana on liian lyhyt",

  CORRECT_COUNTRYCODE_EN: "Country code not valid.",
  CORRECT_COUNTRYCODE_SW: "Country code not valid.(SW)",
  CORRECT_COUNTRYCODE_FI: "Maatunnus ei ole kelvollinen",

  COUNTRY_CODE_REQUIRED_EN: "Country code is required.",
  COUNTRY_CODE_REQUIRED_SW: "Country code is required.(SW)",
  COUNTRY_CODE_REQUIRED_FI: "Maatunnus vaaditaan",

  COUNTRY_REQUIRED_EN: "The country is required.",
  COUNTRY_REQUIRED_SW: "The country is required.(SW)",
  COUNTRY_REQUIRED_FI: "Maa vaaditaan",

  CITY_REQUIRED_EN: "The city is required.",
  CITY_REQUIRED_SW: "The city is required.(SW)",
  CITY_REQUIRED_FI: "Kaupunki vaaditaan",

  BANK_NAME_REQUIRED_EN: "The bank name is required.",
  BANK_NAME_REQUIRED_SW: "The bank name is required.(SW)",
  BANK_NAME_REQUIRED_FI: "Pankin nimi vaaditaan",

  ACCOUNT_NUMBER_REQUIRED_EN: "The account number is required.",
  ACCOUNT_NUMBER_REQUIRED_SW: "The account number is required.(SW)",
  ACCOUNT_NUMBER_REQUIRED_FI: "Tilinumero vaaditaan",

  ADDRESS_LINE1_REQUIRED_EN: "The address line1 is required.",
  ADDRESS_LINE1_REQUIRED_SW: "The address line1 is required.(SW)",
  ADDRESS_LINE1_REQUIRED_FI: "Osoite vaaditaan",

  PASSWORD_REQUIRED_EN: "The password is required",
  PASSWORD_REQUIRED_SW: "The password is required.(SW)",
  PASSWORD_REQUIRED_FI: "Salasana vaaditaan",

  VERIFICATION_CODE_REQUIRED_EN: "Verification code is required",
  VERIFICATION_CODE_REQUIRED_SW: "Verification code is required.(SW)",
  VERIFICATION_CODE_REQUIRED_FI: "Vahvistuskoodi vaaditaan",

  CONFIRM_PASSWORD_REQUIRED_EN: "The confirm password is required",
  CONFIRM_PASSWORD_REQUIRED_SW: "The confirm password is required.(SW)",
  CONFIRM_PASSWORD_REQUIRED_FI: "Salasanan vahvistus vaaditaan",

  CONFIRM_PASSWORD_NOTMATCHED_EN: "Confirm password not matched",
  CONFIRM_PASSWORD_NOTMATCHED_SW: "Confirm password not matched.(SW)",
  CONFIRM_PASSWORD_NOTMATCHED_FI: "Salasanat eivät täsmää",

  FIRST_NAME_REQUIRED_EN: "The first name is required",
  FIRST_NAME_REQUIRED_SW: "The first name is required.(SW)",
  FIRST_NAME_REQUIRED_FI: "Etunimi vaaditaan",

  PICKUP_NAME_REQUIRED_EN: "The sender name is required",
  PICKUP_NAME_REQUIRED_SW: "The sender name is required.(SW)",
  PICKUP_NAME_REQUIRED_FI: "Lähettäjän nimi vaaditaan",

  PICKUP_ADDRESS_REQUIRED_EN: "The pickup address is required",
  PICKUP_ADDRESS_REQUIRED_SW: "The pickup address is required.(SW)",
  PICKUP_ADDRESS_REQUIRED_FI: "Nouto-osoite vaaditaan",

  RECEIVER_NAME_REQUIRED_EN: "Receiver name is required",
  RECEIVER_NAME_REQUIRED_SW: "Receiver name is required.(SW)",
  RECEIVER_NAME_REQUIRED_FI: "Vastaanottajan nimi vaaditaan",

  DROPOFF_ADDRESS_REQUIRED_EN: "Delivery address is required",
  DROPOFF_ADDRESS_REQUIRED_SW: "Delivery address is required.(SW)",
  DROPOFF_ADDRESS_REQUIRED_FI: "Toimitusosoite vaaditaan",

  REQUESTED_DELIVERY_TIME_REQUIRED_EN: "Requested delivery time is required",
  REQUESTED_DELIVERY_TIME_REQUIRED_SW:
    "Requested delivery time is required.(SW)",
  REQUESTED_DELIVERY_TIME_REQUIRED_FI: "Toivottu toimitusaika vaaditaan",

  SIZE_REQUIRED_EN: "Size is required",
  SIZE_REQUIRED_SW: "Size is required.(SW)",
  SIZE_REQUIRED_FI: "Koko vaaditaan",

  WEIGHT_REQUIRED_EN: "Weight is required",
  WEIGHT_REQUIRED_SW: "Weight is required.(SW)",
  WEIGHT_REQUIRED_FI: "Paino vaaditaan",

  WEIGHT_INVALID_EN: "Weight is invalid",
  WEIGHT_INVALID_SW: "Weight is invalid.(SW)",
  WEIGHT_INVALID_FI: "Weight is invalid.(FI)",

  HEIGHT_REQUIRED_EN: "Height is required",
  HEIGHT_REQUIRED_SW: "Height is required.(SW)",
  HEIGHT_REQUIRED_FI: "Korkeus vaaditaan",

  HEIGHT_EN: "Height (cm)",
  HEIGHT_SW: "Height (cm)",
  HEIGHT_FI: "Korkeus (cm)",

  WIDTH_EN: "Width (cm)",
  WIDTH_SW: "Width (cm)",
  WIDTH_FI: "Leveys (cm)",

  DEPTH_EN: "Depth (cm)",
  DEPTH_SW: "Depth (cm)",
  DEPTH_FI: "Syvyys (cm)",

  WIDTH_REQUIRED_EN: "Width is required",
  WIDTH_REQUIRED_SW: "Width is required.(SW)",
  WIDTH_REQUIRED_FI: "Leveys vaaditaan",

  DEPTH_REQUIRED_EN: "Depth is required",
  DEPTH_REQUIRED_SW: "Depth is required.(SW)",
  DEPTH_REQUIRED_FI: "Syvyys vaaditaan",

  PRICE_REQUIRED_EN: "Price is required",
  PRICE_REQUIRED_SW: "Price is required.(SW)",
  PRICE_REQUIRED_FI: "Hinta vaaditaan",

  CARD_INVALID_EN: "Card is invalid",
  CARD_INVALID_SW: "Card is invalid.(SW)",
  CARD_INVALID_FI: "Kortin numero ei ole kelvollinen",

  CARD_NUMBER_REQUIRED_EN: "Card is required",
  CARD_NUMBER_REQUIRED_SW: "Card is required.(SW)",
  CARD_NUMBER_REQUIRED_FI: "Kortin numero vaaditaan",

  HOLDER_NAME_REQUIRED_EN: "Holder name is required",
  HOLDER_NAME_REQUIRED_SW: "Holder name is required.(SW)",
  HOLDER_NAME_REQUIRED_FI: "Kortinhaltijan nimi vaaditaan",

  INVALID_VALUE_EN: "Invalid value",
  INVALID_VALUE_SW: "Invalid value.(SW)",
  INVALID_VALUE_FI: "Syöttämäsi tieto ei ole kelvollinen",

  REQUESTED_PICKUP_TIME_REQUIRED_EN: "Requested pickup time is required",
  REQUESTED_PICKUP_TIME_REQUIRED_SW: "Requested pickup time is required.(SW)",
  REQUESTED_PICKUP_TIME_REQUIRED_FI: "Toivottu noutoaika vaaditaan",

  PRINTED_NAME_REQUIRED_EN: "The printed name is required",
  PRINTED_NAME_REQUIRED_SW: "The printed name is required.(SW)",
  PRINTED_NAME_REQUIRED_FI: "Nimenselvennys vaaditaan",

  LAST_NAME_REQUIRED_EN: "The last name is required",
  LAST_NAME_REQUIRED_SW: "The last name is required.(SW)",
  LAST_NAME_REQUIRED_FI: "Sukunimi vaaditaan",

  CSV_REQUIRED_EN: "CSV is required",
  CSV_REQUIRED_SW: "CSV is required.(SW)",
  CSV_REQUIRED_FI: "CVV vaaditaan",

  EXPIRY_REQUIRED_EN: "Expiry month and year is required",
  EXPIRY_REQUIRED_SW: "Expiry month and year is required.(SW)",
  EXPIRY_REQUIRED_FI: "Erääntymispäivä ja -vuosi vaaditaan",

  //user Profile

  USER_HEAD_EN: "Profile",
  USER_HEAD_SW: "Profil",
  USER_HEAD_FI: "Profiili",

  USER_INFO_EN: "User Information",
  USER_INFO_SW: "Användarinformation",
  USER_INFO_FI: "Käyttäjätilin tiedot",

  EDIT_BTN_EN: "edit profile",
  EDIT_BTN_SW: "Ändra profil",
  EDIT_BTN_FI: "Muokkaa profiilia",

  BACK_MYACCOUNT_EN: "Back to My Account",
  BACK_MYACCOUNT_SW: "Back to My Account(SW)",
  BACK_MYACCOUNT_FI: "Takaisin käyttäjätililleni",

  BACK_HOME_EN: "Back to Home",
  BACK_HOME_SW: "Tillbaka till Hem",
  BACK_HOME_FI: "Palaa etusivulle",

  UPATED_BTN_EN: "Update Profile",
  UPATED_BTN_SW: "Uppdatera profil",
  UPATED_BTN_FI: "Päivitä profiili",

  //sens an items  (create pakage)
  APPLY_EN: "Apply",
  APPLY_SW: "Använd",
  APPLY_FI: "Käytä",

  OFF_EN: "Off",
  OFF_SW: "Off",
  OFF_FI: "Off",

  LESS_AMOUNT_EN: "Your price is too low for this delivery.",
  LESS_AMOUNT_SW: "Ditt pris är för lågt för den här leveransen",
  LESS_AMOUNT_FI:
    "Asettamasi toimitushinta on liian pieni tälle toimitukselle.",

  SEND_HEADING_EN: "Send an Item",
  SEND_HEADING_SW: "Skicka en artikel",
  SEND_HEADING_FI: "Lähetä paketti",

  CONFIRM_SEND_EN: "Confirm Send",
  CONFIRM_SEND_SW: "Bekräfta skicka",
  CONFIRM_SEND_FI: "Vahvista lähetys",

  ITEM_TYPE_EN: "Item Type",
  ITEM_TYPE_SW: "Artikeltyp",
  ITEM_TYPE_FI: "Paketin tyyppi",

  CONFIRM_PAY_BTN_EN: "Confirm & Pay",
  CONFIRM_PAY_BTN_SW: "Bekräfta & betala",
  CONFIRM_PAY_BTN_FI: "Vahvista ja maksa",

  CARD_REQUIRED_EN: "Please select card",
  CARD_REQUIRED_SW: "Please select card(SW)",
  CARD_REQUIRED_FI: "Valitse maksukortti",

  CARD_ADD_EN: "Please add card",
  CARD_ADD_SW: "Please add card(SW)",
  CARD_ADD_FI: "Lisää maksukortti",

  ITEM_TYPE_REQUIRED_EN: "Please select item type",
  ITEM_TYPE_REQUIRED_SW: "Please select item type(SW)",
  ITEM_TYPE_REQUIRED_FI: "Valitse paketin tyyppi",

  ADD_NEW_CARD_EN: "Add New Card",
  ADD_NEW_CARD_SW: "Add New Card(SW)",
  ADD_NEW_CARD_FI: "Lisää uusi maksukortti",

  PICK_INFO_EN: "Pickup Information",
  PICK_INFO_SW: "Upphämtningsinformation",
  PICK_INFO_FI: "Lähettäjän tiedot",

  DELI_INFO_EN: "Delivery Information",
  DELI_INFO_SW: "Leveransinformation",
  DELI_INFO_FI: "Vastaanottajan tiedot",

  PAKAGE_SIZE_EN: "Package Size & Weight",
  PAKAGE_SIZE_SW: "Package Size & Weight(SW)",
  PAKAGE_SIZE_FI: "Paketin koko ja paino",

  DELI_FEE_EN: "Delivery Fee",
  DELI_FEE_SW: "Leveransavgift",
  DELI_FEE_FI: "Toimitushinta",

  ADD_DELIVERY_N_EN: "Add a Delivery Note",
  ADD_DELIVERY_N_SW: "ALägg till ett leveransmeddelande",
  ADD_DELIVERY_N_FI: "Syötä viesti kuljettajalle",

  DELIVERY_NOTE_EN: "Delivery Note",
  DELIVERY_NOTE_SW: "Leveransmeddelande",
  DELIVERY_NOTE_FI: "Viesti kuljettajalle",

  COMPLETE_DELIVERY_NOTE_EN:
    "Your delivery request has been posted successfully. You will get a response from a delivery driver soon",
  COMPLETE_DELIVERY_NOTE_SW:
    "Din leveransföfrågan har framgångsrikt lagts upp. Du kommer att få ett svar från en förare för leverans snart.",
  COMPLETE_DELIVERY_NOTE_FI:
    "Toimituspyyntösi onnistui. Saat pian kuittauksen kuljettajalta.",

  ADD_DELIVERY_P_EN: "Your message for the delivery driver...",
  ADD_DELIVERY_P_SW: "Ditt meddelande till föraren för leverans…",
  ADD_DELIVERY_P_FI: "Viestisi kuljettajalle...",

  PAYMENT_DETAILS_EN: "Payment Details",
  PAYMENT_DETAILS_SW: "Betalningsdetaljer",
  PAYMENT_DETAILS_FI: "Maksutiedot",

  PICK_ADD_EN: "Pickup Address",
  PICK_ADD_SW: "Upphämtningsadress",
  PICK_ADD_FI: "Nouto-osoite",

  DELIVER_ADD_EN: "Delivery Address",
  DELIVER_ADD_SW: "Leveransadress",
  DELIVER_ADD_FI: "Toimitusosoite",

  ID_EN: "ID",
  ID_SW: "ID",
  ID_FI: "Seurantatunnus",

  NA_EN: "N/A",
  NA_SW: "N/A",
  NA_FI: "-",

  OH_NO_EN: "Oh No!",
  OH_NO_SW: "Oh No!",
  OH_NO_FI: "Hups!",

  SUCCESS_EN: "Success",
  SUCCESS_SW: "Success",
  SUCCESS_FI: "Jes, onnistui!",

  CARRIER_LOCATION_EN: "Carrier Location",
  CARRIER_LOCATION_SW: "Carrier Location(SW)",
  CARRIER_LOCATION_FI: "Kuljettajan sijainti",

  ENTER_LOCATION_EN: "Enter Location",
  ENTER_LOCATION_SW: "Enter Location(SW)",
  ENTER_LOCATION_FI: "Syötä osoite",

  APART_NU_EN: "Apt. Number",
  APART_NU_SW: "Apt. Number(SW)",
  APART_NU_FI: "Huoneiston numero",

  PICK_PH_EN: "Pickup Phone",
  PICK_PH_SW: "Telefon för upphämtning",
  PICK_PH_FI: "Lähettäjän puhelinnumero",

  DELIVER_PH_EN: "Deliver Phone",
  DELIVER_PH_SW: "Telefon för leverans",
  DELIVER_PH_FI: "Vastaanottajan puhelinnumero",

  PACK_SIZE_EN: "Package Size 20x20x20 cm",
  PACK_SIZE_SW: "Package Size 20x20x20 cm(SW)",
  PACK_SIZE_FI: "Paketin koko 20x20x20 cm",

  PROCESS_CONFIRM_EN: "PROCEED TO CONFIRMATION",
  PROCESS_CONFIRM_SW: "PROCEED TO CONFIRMATION(SW)",
  PROCESS_CONFIRM_FI: "Tallenna ja jatka",

  PICKUP_NAME_EN: "Sender name",
  PICKUP_NAME_SW: "Upphämtningsnamn",
  PICKUP_NAME_FI: "Lähettäjän nimi",

  TIME_EN: "Time",
  TIME_SW: "Time",
  TIME_FI: "Aika",

  REQUESTED_PICKUP_TIME_EN: "Requested pickup time",
  REQUESTED_PICKUP_TIME_SW: "Requested pickup time(SW)",
  REQUESTED_PICKUP_TIME_FI: "Toivottu noutoaika",

  REQUESTED_DELIVERY_TIME_EN: "Requested delivery time",
  REQUESTED_DELIVERY_TIME_SW: "Requested delivery time(SW)",
  REQUESTED_DELIVERY_TIME_FI: "Toivottu toimitusaika",

  Letter_EN: "Letter",
  Letter_SW: "Brev",
  Letter_FI: "Kirje",

  GROCERY_EN: "Grocery",
  GROCERY_SW: "Grocery(SW)",
  GROCERY_FI: "Ruokatavarat",

  Package_SL_EN: "Package S-L",
  Package_SL_SW: "Paket S-L",
  Package_SL_FI: "Paketti S-L",

  NEW_BICYCLE_EN: "Bicycle",
  NEW_BICYCLE_SW: "Cykel",
  NEW_BICYCLE_FI: "Polkupyörä",

  Package_XL_EN: "Package XL",
  Package_XL_SW: "Paket XL",
  Package_XL_FI: "Paketti XL",

  Package_XXL_EN: "Package XXL",
  Package_XXL_SW: "Paket XXL",
  Package_XXL_FI: "Paketti XXL",

  SIZE_NAME_EN: "Other",
  SIZE_NAME_SW: "Other(SW)",
  SIZE_NAME_FI: "Muu koko",

  DELIVER_VIA_EN: "Deliver via",
  DELIVER_VIA_SW: "Deliver via(SW)",
  DELIVER_VIA_FI: "Kulkuväline",

  OTHER_EN: "Other",
  OTHER_SW: "Annan",
  OTHER_FI: "Muu",

  WEIGHT_EN: "Weight(kg)",
  WEIGHT_SW: "Vikt(kg)",
  WEIGHT_FI: "Paino(kg)",

  SEND_AN_ITEM_EN: "Send an Item",
  SEND_AN_ITEM_SW: "Skicka en artikel",
  SEND_AN_ITEM_FI: "Lähetä paketti",

  TRACK_A_PACKAGE_EN: "Track a Package",
  TRACK_A_PACKAGE_SW: "Track a Package(SW)",
  TRACK_A_PACKAGE_FI: "Seuraa pakettia",

  ROUTE_EN: "Route",
  ROUTE_SW: "Rutt",
  ROUTE_FI: "Reitti",

  PICKUP_EN: "Pickup",
  PICKUP_SW: "Upphämtning",
  PICKUP_FI: "Nouda",

  PICKUPMENU_EN: "Pickup",
  PICKUPMENU_SW: "Upphämtning",
  PICKUPMENU_FI: "Nouda",

  // MY_ACCOUNT_EN:"Logout",
  // MY_ACCOUNT_SW:"Logga ut",
  // MY_ACCOUNT_FI:"Kirjaudu ulos",

  HAND_OVER_EN: "Hand Over",
  HAND_OVER_SW: "Lämna över",
  HAND_OVER_FI: "Toimita",

  LOGIN_SIGNUP_EN: "Login/Signup",
  LOGIN_SIGNUP_SW: "Login/Signup(SW)",
  LOGIN_SIGNUP_FI: "Kirjaudu / Rekisteröidy",

  NO_EN: "No",
  NO_SW: "No(SW)",
  NO_FI: "Ei",

  PLEASE_LOGIN_FIRST_EN: "Please login first",
  PLEASE_LOGIN_FIRST_SW: "Please login first(SW)",
  PLEASE_LOGIN_FIRST_FI: "Kirjaudu ensin sisään",

  MESSAGE_EN: "Message",
  MESSAGE_SW: "Meddelande",
  MESSAGE_FI: "Viesti",

  MESSAGES_EN: "Messages",
  MESSAGES_SW: "Meddelanden",
  MESSAGES_FI: "Viestit",

  SELECT_YOUR_CARGO_EN: "Select Your Cargo",
  SELECT_YOUR_CARGO_SW: "Välj din frakt",
  SELECT_YOUR_CARGO_FI: "Valitse rahti",

  LOGIN_REGISTER_EN: "Login/Signup",
  LOGIN_REGISTER_SW: "Logga in/Registrera dig",
  LOGIN_REGISTER_FI: "Kirjaudu/Rekisteröidy",

  SELECT_SIZE_EN: "Select Size",
  SELECT_SIZE_SW: "Select Size(SW)",
  SELECT_SIZE_FI: "Paketin koko",

  DELIVERY_NAME_EN: "Receiver Name",
  DELIVERY_NAME_SW: "Receiver Name(SW)",
  DELIVERY_NAME_FI: "Vastaanottajan nimi",

  SET_YOUR_PRICE_EN: "Set Your Price",
  SET_YOUR_PRICE_SW: "Ange ditt pris",
  SET_YOUR_PRICE_FI: "Aseta hinta",

  YOUR_PRICE_EN: "Your Price",
  YOUR_PRICE_SW: "Ditt pris",
  YOUR_PRICE_FI: "Toimitushintasi",

  PROMOCODE_VOUCHER_EN: "Promo code/voucher:",
  PROMOCODE_VOUCHER_SW: "kampanjkod/rabattkupong:",
  PROMOCODE_VOUCHER_FI: "Alennuskoodi:",

  AMOUNT_PAID_EN: "Amount Paid:",
  AMOUNT_PAID_SW: "Belopp betalt:",
  AMOUNT_PAID_FI: "Toteutunut toimitushinta:",

  ENTER_PROMOCODE_VOUCHER_EN: "Enter promo code / voucher",
  ENTER_PROMOCODE_VOUCHER_SW: "Mata in kampanjkod/rabattkupong",
  ENTER_PROMOCODE_VOUCHER_FI: "Syötä alennuskoodi",

  CREDIT_DEBIT_CARD_EN: "Credit / Debit Card",
  CREDIT_DEBIT_CARD_SW: "Kredit-/bankkort",
  CREDIT_DEBIT_CARD_FI: "Luotto-/Pankkikortti",

  AMOUNT_PAYABLE_EN: "Amount Payable",
  AMOUNT_PAYABLE_SW: "Belopp att betala",
  AMOUNT_PAYABLE_FI: "Toimitushinta",

  ESTIMATED_AMOUNT_EN: "Estimated Amount",
  ESTIMATED_AMOUNT_SW: "Uppskattat belopp",
  ESTIMATED_AMOUNT_FI: "Toimitushinta ehdotus",

  //my account

  MY_ACCOUNT_EN: "My Account",
  MY_ACCOUNT_SW: "My Account(SW)",
  MY_ACCOUNT_FI: "Käyttäjätilini",

  ACCOUNT_SETTING_EN: "Account Setting",
  ACCOUNT_SETTING_SW: "Account Setting(SW)",
  ACCOUNT_SETTING_FI: "Käyttäjätilin asetukset",

  LOG_OUT_EN: "Logout",
  LOG_OUT_SW: "Logga ut",
  LOG_OUT_FI: "Kirjaudu ulos",

  PROFILE_EN: "Profile",
  PROFILE_SW: "Profil",
  PROFILE_FI: "Profiili",

  SETTING_EN: "Settings",
  SETTING_SW: "Inställningar",
  SETTING_FI: "Asetukset",

  PAYMENT_EN: "Payments",
  PAYMENT_SW: "Betalningar",
  PAYMENT_FI: "Maksut",

  PACKAGES_EN: "My Packages",
  PACKAGES_SW: "Mina paket",
  PACKAGES_FI: "Paketit",

  MY_PICKUP_EN: "My Pickup",
  MY_PICKUP_SW: "Min upphämtning",
  MY_PICKUP_FI: "Noudettavat",

  REFER_FRIEND_EN: "My Packages",
  REFER_FRIEND_SW: "Mina paket",
  REFER_FRIEND_FI: "Paketit",

  //my review

  WRITE_REVIEW_HEADING_EN: "Write a Review",
  WRITE_REVIEW_HEADING_SW: "Skriv en recension",
  WRITE_REVIEW_HEADING_FI: "Kirjoita arvostelu",

  RATE_REVIEW_EN: "Rate Your Delivery",
  RATE_REVIEW_SW: "Bedöm din leverans",
  RATE_REVIEW_FI: "Arvostele toimitus",

  PLEASE_GIVE_RATING_EN: "Please give rating.",
  PLEASE_GIVE_RATING_SW: "Please give rating.(SW)",
  PLEASE_GIVE_RATING_FI: "Anna arvio toimituksesta",

  PLEASE_GIVE_REVIEW_EN: "Please give review.",
  PLEASE_GIVE_REVIEW_SW: "Please give review.(SW)",
  PLEASE_GIVE_REVIEW_FI: "Kirjallinen arvio puuttuu",

  RATE_OVERALL_EN: "Overall Rating:",
  RATE_OVERALL_SW: "Total bedömning",
  RATE_OVERALL_FI: "Kokonaisarvio",

  WRITE_PLACEHOLDER_EN: "Write a review...",
  WRITE_PLACEHOLDER_SW: "Skriv en recension...",
  WRITE_PLACEHOLDER_FI: "Arvostele toimitus...",

  COMMUNICAT_HEAD_EN: "Communication:",
  COMMUNICAT_HEAD_SW: "Kommunikation:",
  COMMUNICAT_HEAD_FI: "Viestintä:",

  PUNCTUALITY_HEAD_EN: "Punctuality & Reliability:",
  PUNCTUALITY_HEAD_SW: "Punktlighet & tillförlitlighet:",
  PUNCTUALITY_HEAD_FI: "Täsmällisyys ja luotettavuus:",

  FLEXIBILITY_HEAD_EN: "Flexibility:",
  FLEXIBILITY_HEAD_SW: "Flexibilitet:",
  FLEXIBILITY_HEAD_FI: "Joustavuus:",

  SUBMIT_REVIEW_EN: "SUBMIT REVIEW",
  SUBMIT_REVIEW_SW: "Skicka in en recension",
  SUBMIT_REVIEW_FI: "Lähetä arvostelu",

  //add card

  ADD_CARD_HEADING_EN: "Add Card Details",
  ADD_CARD_HEADING_SW: "Lägg till kortdetaljer",
  ADD_CARD_HEADING_FI: "Syötä maksukortin tiedot",

  SUBMIT_FEEDBACK_HEADING_EN: "Submit Feedback",
  SUBMIT_FEEDBACK_HEADING_SW: "Submit Feedback(SW)",
  SUBMIT_FEEDBACK_HEADING_FI: "Anna palautetta",

  TERM_CONDITION_HEADING_EN: "Terms & Conditions",
  TERM_CONDITION_HEADING_SW: "Terms & Conditions(SW)",
  TERM_CONDITION_HEADING_FI: "Käyttöehdot",

  PRIVACY_POLICY_EN: "Privacy policy",
  PRIVACY_POLICY_SW: "Privacy policy(SW)",
  PRIVACY_POLICY_FI: "Privacy policy(FI)",

  SUBMIT_FEEDBACK_SUBHEADING_EN:
    "  The team at Fiuge always love to hear from you. Whether you have a feature request, have a suggestion, want to report a bug or just want to leave a pleasant message for the team, please just get in touch using the form below and we will get back to you right away.",
  SUBMIT_FEEDBACK_SUBHEADING_SW:
    "  The team at Fiuge always love to hear from you. Whether you have a feature request, have a suggestion, want to report a bug or just want to leave a pleasant message for the team, please just get in touch using the form below and we will get back to you right away.(SW)",
  SUBMIT_FEEDBACK_SUBHEADING_FI: " Anna palautetta",

  HELP_SUPPORT_SUBHEADING_EN:
    "Do you have a technical question or an issue with a particular package? We’re here to help! Please fill out the form below and we will get back to you with the upmost of urgency.",
  HELP_SUPPORT_SUBHEADING_SW:
    "Do you have a technical question or an issue with a particular package? We’re here to help! Please fill out the form below and we will get back to you with the upmost of urgency.(SW)",
  HELP_SUPPORT_SUBHEADING_FI: "Ohjeet ja tuki",

  HELP_SUPPORT_HEADING_EN: "Help & Support",
  HELP_SUPPORT_HEADING_SW: "Hjälp & stöd",
  HELP_SUPPORT_HEADING_FI: "Ohjeet ja tuki",

  ABOUT_APP_HEADING_EN: "About the App",
  ABOUT_APP_HEADING_SW: "Avseende appen",
  ABOUT_APP_HEADING_FI: "Tietoa sovelluksesta",

  // ADD_SUB_HEADING_EN:"Select card Type",
  // ADD_SUB_HEADING_SW:"Select card Type(SW)",
  // ADD_SUB_HEADING_FI:"Select card Type(FI)",

  //forgot password

  BACK_TO_LOGIN_EN: "Back to login",
  BACK_TO_LOGIN_SW: "Back to login(SW)",
  BACK_TO_LOGIN_FI: "Takaisin sisäänkirjautumiseen",

  //reset password

  RESET_PASSWORD_EN: "Reset Password",
  RESET_PASSWORD_SW: "Reset Password(SW)",
  RESET_PASSWORD_FI: "Nollaa salasana",

  VERIFICATION_CODE_PLACEHOLDER_EN: "Verification Code",
  VERIFICATION_CODE_PLACEHOLDER_SW: "Verifieringskod",
  VERIFICATION_CODE_PLACEHOLDER_FI: "Vahvistuskoodi",

  //order details

  INFORMAITON_HEAD_EN: "Order Information",
  INFORMAITON_HEAD_SW: "Orderinformation",
  INFORMAITON_HEAD_FI: "Lähetyksen tiedot",

  ORDER_DETAILS_EN: "Order Details",
  ORDER_DETAILS_SW: "Orderdetaljer",
  ORDER_DETAILS_FI: "Lähetyksen tiedot",

  HISTORY_INFORMAITON_HEAD_EN: "Order Information",
  HISTORY_INFORMAITON_HEAD_SW: "Orderinformation",
  HISTORY_INFORMAITON_HEAD_FI: "Tilauksen tiedot",

  HISTORY_ORDER_DETAILS_EN: "Order Details",
  HISTORY_ORDER_DETAILS_SW: "Orderdetaljer",
  HISTORY_ORDER_DETAILS_FI: "Tilauksen tiedot",

  DELIVERED_INFORMAITON_HEAD_EN: "Order Information",
  DELIVERED_INFORMAITON_HEAD_SW: "Orderinformation",
  DELIVERED_INFORMAITON_HEAD_FI: "Lähetyksen tiedot",

  DELIVERED_ORDER_DETAILS_EN: "Order Details",
  DELIVERED_ORDER_DETAILS_SW: "Orderdetaljer",
  DELIVERED_ORDER_DETAILS_FI: "Toimitettu paketti",

  CANCELED_INFORMAITON_HEAD_EN: "Order Information",
  CANCELED_INFORMAITON_HEAD_SW: "Orderinformation",
  CANCELED_INFORMAITON_HEAD_FI: "Lähetyksen tiedot",

  CANCELED_ORDER_DETAILS_EN: "Order Details",
  CANCELED_ORDER_DETAILS_SW: "Orderdetaljer",
  CANCELED_ORDER_DETAILS_FI: "Lähetyksen tiedot",

  ORDER_COMPLETED_EN: "Order Complete",
  ORDER_COMPLETED_SW: "Order fullständig",
  ORDER_COMPLETED_FI: "Tilaus valmis",

  TRACK_LINK_EN: "Back to Track a Package",
  TRACK_LINK_SW: "Back to Track a Package(SW)",
  TRACK_LINK_FI: "Takaisin paketin seurantaan",

  SEND_AN_ITEM_LINK_EN: "Back to Send an Item",
  SEND_AN_ITEM_LINK_SW: "Back to Send an Item(SW)",
  SEND_AN_ITEM_LINK_FI: "Takaisin paketin lähetykseen",

  PACKAGE_HEAD_EN: "Package Details",
  PACKAGE_HEAD_SW: "Paketdetaljer",
  PACKAGE_HEAD_FI: "Paketin tiedot",

  DELI_NOTE_EN: "Package Details",
  DELI_NOTE_SW: "Paketdetaljer",
  DELI_NOTE_FI: "Paketin tiedot",

  // thank you

  THANK_HEAD_EN: "Thank You",
  THANK_HEAD_SW: "Tack",
  THANK_HEAD_FI: "Kiitos",

  ORDER_PLACED_EN: "Order Placed",
  ORDER_PLACED_SW: "Order lagd",
  ORDER_PLACED_FI: "Tilaus jätetty",

  ST_PICKUP_EN: "Pickup",
  ST_PICKUP_SW: "Upphämtning",
  ST_PICKUP_FI: "Nouto",

  ST_DELIVERY_EN: "Delivery",
  ST_DELIVERY_SW: "Leveransav",
  ST_DELIVERY_FI: "Toimitus",

  TRACK_ORDER_EN: "Track Order",
  TRACK_ORDER_SW: "Spåra order",
  TRACK_ORDER_FI: "Lähetyksen seuranta",

  //Order History

  ORDER_HISTORY_EN: "Order History",
  ORDER_HISTORY_SW: "Orderhistorik",
  ORDER_HISTORY_FI: "Lähetyshistoria",

  //add card
  NAME_EN: "Name",
  NAME_SW: "Namn",
  NAME_FI: "Nimi",

  CARD_NUMBER_EN: "Card Number",
  CARD_NUMBER_SW: "Kortnummer",
  CARD_NUMBER_FI: "Kortin numero",

  EXPIRY_EN: "Expiry",
  EXPIRY_SW: "Utgång",
  EXPIRY_FI: "Erääntyminen",

  CSV_EN: "CSV",
  CSV_SW: "CSV",
  CSV_FI: "CVV",

  ADD_CARD_PAYMENT_EN: "ADD_CARD_PAYMENT",
  ADD_CARD_PAYMENT_SW: "Lägg till betalningskort",
  ADD_CARD_PAYMENT_FI: "Lisää maksukortti",

  SELECT_CARD_TYPE_EN: "Select Card Type",
  SELECT_CARD_TYPE_SW: "Välj korttyp",
  SELECT_CARD_TYPE_FI: "Valitse korttityyppi",

  ADD_CARD_EN: "Add Card",
  ADD_CARD_SW: "Lägg till kort",
  ADD_CARD_FI: "Lisää maksukortti",

  //

  FURNITURE_HEAD_EN: "Furniture",
  FURNITURE_HEAD_SW: "Möbler",
  FURNITURE_HEAD_FI: "Huonekalu",

  CARI_DETAILS_HEAD_EN: "Carrier Details",
  CARI_DETAILS_HEAD_SW: "Operatörsdetaljer",
  CARI_DETAILS_HEAD_FI: "Kuljettajan tiedot",

  MESSAGE_CARRIER_BTN_EN: "MESSAGE CARRIER",
  MESSAGE_CARRIER_BTN_SW: "Meddelandeoperatör",
  MESSAGE_CARRIER_BTN_FI: "Viesti kuljettajalle",

  AMT_PAYABLE_EN: "Amount Payable",
  AMT_PAYABLE_SW: "Amount Payable_SW",
  AMT_PAYABLE_FI: "Toimitushinta",

  TRACK_PACKAGE_BTN_EN: "Amount Payable",
  TRACK_PACKAGE_BTN_SW: "Amount Payable_SW",
  TRACK_PACKAGE_BTN_FI: "Toimitushinta",

  MSG_HEAD_EN: "Messages",
  MSG_HEAD_SW: "Meddelanden",
  MSG_HEAD_FI: "Viestit",

  VIEW_MSG_EN: "View Messages",
  VIEW_MSG_SW: "Titta på meddelanden",
  VIEW_MSG_FI: "Viestit",

  //bank info
  Y_DETAIL_EN: "Your Details",
  Y_DETAIL_SW: "Your Details_SW",
  Y_DETAIL_FI: "Sinun tietosi",

  ENT_DETAIL_EN: "Enter Details",
  ENT_DETAIL_SW: "Ange detaljer",
  ENT_DETAIL_FI: "Syötä tiedot",

  BANK_DETAIL_EN: "Bank Details",
  BANK_DETAIL_SW: "Bank Details_SW",
  BANK_DETAIL_FI: "Pankkitiedot",

  BANK_ACCOUNT_EN: "Bank Account",
  BANK_ACCOUNT_SW: "Bank Account_SW",
  BANK_ACCOUNT_FI: "Maksutiedot",

  DATE_BIRTH_EN: "Date of Birth",
  DATE_BIRTH_SW: "Date of Birth(SW)",
  DATE_BIRTH_FI: "Syntymäaika",

  DATE_BIRTH_VALIDATION_EN: "The Date of Birth is required.",
  DATE_BIRTH_VALIDATION_SW: "The Date of Birth is required.(SW)",
  DATE_BIRTH_VALIDATION_FI: "Syntymäaika vaaditaan",

  ADDRESS_EN: "Address",
  ADDRESS_SW: "Address(SW)",
  ADDRESS_FI: "Osoite",

  CITY_EN: "City",
  CITY_SW: "Stad",
  CITY_FI: "Kaupunki",

  ADDRESS_1_EN: "Address Line1",
  ADDRESS_1_SW: "Adresslinje 1",
  ADDRESS_1_FI: "Osoite",

  ADDRESS_2_EN: "Address Line2",
  ADDRESS_2_SW: "Adresslinje 2",
  ADDRESS_2_FI: "Osoite",

  COUNTRY_EN: "Country",
  COUNTRY_SW: "Lands",
  COUNTRY_FI: "Maa",

  UPLOAD_ID_EN: "Upload ID",
  UPLOAD_ID_SW: "Ladda upp ID",
  UPLOAD_ID_FI: "Lisää henkilökortti",

  BANK_NAME_EN: "Bank Name",
  BANK_NAME_SW: "Banknamn",
  BANK_NAME_FI: "Pankin nimi",

  ACC_NUMBER_EN: "Account No.",
  ACC_NUMBER_SW: "Kontonummer",
  ACC_NUMBER_FI: "Tilinumero",

  ROUT_NUMBER_EN: "Routing No.",
  ROUT_NUMBER_SW: "Routing No.",
  ROUT_NUMBER_FI: "Aluenumero",

  SSN_NUMBER_EN: "SSN No.",
  SSN_NUMBER_SW: "SSN No.",
  SSN_NUMBER_FI: "Henkilötunnus",

  UPDATE_MOBILE_NUMBER_EN: "Update Mobile Number",
  UPDATE_MOBILE_NUMBER_SW: "Uppdatera mobilnummer",
  UPDATE_MOBILE_NUMBER_FI: "Päivitä puhelinnumero",

  //select yout cargo

  SELCT_CARGO_EN: "Select your Cargo",
  SELCT_CARGO_SW: "Välj din frakt",
  SELCT_CARGO_FI: "Valitse rahti",

  TRAVEL_BY_EN: "Travel by",
  TRAVEL_BY_SW: "Res med",
  TRAVEL_BY_FI: "Kulkuväline",

  FOOT_EN: "Foot",
  FOOT_SW: "Foot(SW)",
  FOOT_FI: "Jalan",

  ALL_EN: "All",
  ALL_SW: "All(SW)",
  ALL_FI: "Kaikki",

  MOTORBIKE_EN: "Motorbike",
  MOTORBIKE_SW: "Motorbike(SW)",
  MOTORBIKE_FI: "Moottoripyörä",

  CAR_EN: "Car",
  CAR_SW: "Car(SW)",
  CAR_FI: "Auto",

  VAN_EN: "Van",
  VAN_SW: "Van(SW)",
  VAN_FI: "Pakettiauto",

  TRUCK_EN: "Truck",
  TRUCK_SW: "Truck(SW)",
  TRUCK_FI: "Kuorma-auto",

  BOAT_EN: "Boat",
  BOAT_SW: "Boat(SW)",
  BOAT_FI: "Vene",

  RATING_EN: "Rating",
  RATING_SW: "Bedömning",
  RATING_FI: "Arvio",

  REVIEW_EN: "Review",
  REVIEW_SW: "Recensera",
  REVIEW_FI: "Arvostelu",

  // track Package

  TRACK_PACK_EN: "Track a Package",
  TRACK_PACK_SW: "Track a Package(sw)",
  TRACK_PACK_FI: "Seuraa pakettia",

  TRACK_PACKAGE_EN: "Track Package",
  TRACK_PACKAGE_SW: "Track Package(sw)",
  TRACK_PACKAGE_FI: "Seuraa pakettia",

  TRACK_ITEMS_EN: "Track a Items",
  TRACK_ITEMS_SW: "Track a Items(sw)",
  TRACK_ITEMS_FI: "Seuraa paketteja",

  // confirm-pickup

  TAKE_PHOTO_EN: "Take Photograph of Package",
  TAKE_PHOTO_SW: "Ta foto av paketet",
  TAKE_PHOTO_FI: "Ota kuva paketista",

  DELIVERY_TAKE_PHOTO_EN: "Take photograph of delivery Package",
  DELIVERY_TAKE_PHOTO_SW: "Take photograph of delivery Package(SW)",
  DELIVERY_TAKE_PHOTO_FI: "Ota kuva paketista",

  TAKE_PIC_BTN_EN: "TAKE PHOTO",
  TAKE_PIC_BTN_SW: "ta foto",
  TAKE_PIC_BTN_FI: "ota kuva",

  PACKAGE_IMAGE_REQUIRE_EN: "Package image is required",
  PACKAGE_IMAGE_REQUIRE_SW: "Package image is required(SW)",
  PACKAGE_IMAGE_REQUIRE_FI: "Paketin kuva vaaditaan",

  DELIVERY_PACKAGE_IMAGE_REQUIRE_EN: "Delivery package image is required",
  DELIVERY_PACKAGE_IMAGE_REQUIRE_SW: "Delivery package image is required(SW)",
  DELIVERY_PACKAGE_IMAGE_REQUIRE_FI: "Paketin kuva vaaditaan",

  SIGNATURE_REQUIRE_EN: "Signature is required",
  SIGNATURE_REQUIRE_SW: "Signature is required(SW)",
  SIGNATURE_REQUIRE_FI: "Allekirjoitus vaaditaan",

  CON_PIC_BTN_EN: "CONFIRM PICKUP",
  CON_PIC_BTN_SW: "Bekräfta upphämtning",
  CON_PIC_BTN_FI: "Vahvista nouto",

  PHOTO_HEAD_EN: "Photograph of Package",
  PHOTO_HEAD_SW: "Photograph of Package(sw)",
  PHOTO_HEAD_FI: "Paketin kuva noudettaessa",

  DELI_HEAD_EN: "Confirm Delivery",
  DELI_HEAD_SW: "Confirm Delivery(sw)",
  DELI_HEAD_FI: "Vahvista toimitus",

  PACKET_HEAD_EN: "Packet",
  PACKET_HEAD_SW: "Paket",
  PACKET_HEAD_FI: "Paketti",

  PACKET_HEADS_EN: "Packets",
  PACKET_HEADS_SW: "Packets(SW)",
  PACKET_HEADS_FI: "Pakettia",

  FIRST_DROP_CURRENT_PACKAGE_EN: "Deliver current package first",
  FIRST_DROP_CURRENT_PACKAGE_SW: "Deliver current package first(SW)",
  FIRST_DROP_CURRENT_PACKAGE_FI: "Sinulla on jo paketti toimitettavana",

  FIRST_PICKUP_PACKET_EN: "FIRST PICKUP PACKET",
  FIRST_PICKUP_PACKET_SW: "Välj först ett paket",
  FIRST_PICKUP_PACKET_FI: "Valitse ensin noudettava paketti",

  SELECT_FIRST_CARGO_EN: "To use this functionality, you have to select cargo",
  SELECT_FIRST_CARGO_SW:
    "För att använda den här funktionaliteten måste du välja frakt",
  SELECT_FIRST_CARGO_FI:
    "Sinun täytyy valita rahti käyttääksesi tätä toimintoa",

  LOGIN_FIRST_EN: "To use this functionality, you have to login first",
  LOGIN_FIRST_SW:
    "För att använda den här funktionaliteten måste du först logga in",
  LOGIN_FIRST_FI: "Sinun täytyy kirjautua sisään käyttääksesi tätä toimintoa.",

  DEL_APPROVAL_HEAD_EN: "Delivery Approval",
  DEL_APPROVAL_HEAD_SW: "Delivery Approval(sw)",
  DEL_APPROVAL_HEAD_FI: "Paketti noudettu",

  // Cargo Details

  CARGO_DETA_HEAD_EN: "Cargo Details",
  CARGO_DETA_HEAD_SW: "Cargo Details(sw)",
  CARGO_DETA_HEAD_FI: "Rahdin tiedot",

  CARGO_EN: "Cargo",
  CARGO_SW: "Frakt",
  CARGO_FI: "Rahti",

  OVERV_HEAD_EN: "Overview",
  OVERV_HEAD_SW: "Overview(sw)",
  OVERV_HEAD_FI: "Yhteenveto",

  CARGODETAIL_SUBHEAD_EN: "Cargo",
  CARGODETAIL_SUBHEAD_SW: "Frakt",
  CARGODETAIL_SUBHEAD_FI: "Rahti",

  OTHER_SIZE_EN: "Other Size",
  OTHER_SIZE_SW: "Annan storlek",
  OTHER_SIZE_FI: "Muu koko",

  DISTANCE_IS_TOO_LONG_EN: "Distance is too long.",
  DISTANCE_IS_TOO_LONG_SW: "Distance is too long.(SW)",
  DISTANCE_IS_TOO_LONG_FI: "Distance is too long.(FI)",

  BACK_TO_CARGO_EN: "Back to Select your Cargo",
  BACK_TO_CARGO_SW: "Back to Select your Cargo(sw)",
  BACK_TO_CARGO_FI: "Takaisin rahdin valintaan",

  PENDING_STATUS_EN: "Pending",
  PENDING_STATUS_SW: "Avvaktar",
  PENDING_STATUS_FI: "Odottaa",

  ASSIGN_STATUS_EN: "Assigned",
  ASSIGN_STATUS_SW: "Assigned(sw)",
  ASSIGN_STATUS_FI: "Etsitään kuljettajaa",

  ACCEPTED_STATUS_EN: "Accepted",
  ACCEPTED_STATUS_SW: "Accepterad",
  ACCEPTED_STATUS_FI: "Valittu kuljetettavaksi",
  // ACCEPTED_STATUS_FI:"Hyväksytty",

  VALID_PICKUPADDRESS_EN: "Please Select valid pickup Address",
  VALID_PICKUPADDRESS_SW: "Please Select valid pickup Address(SW)",
  VALID_PICKUPADDRESS_FI: "Nouto-osoite ei ole kelvollinen",

  VALID_DELIVERADDRESS_EN: "Please Select valid deliver Address",
  VALID_DELIVERADDRESS_SW: "Please Select valid deliver Address(SW)",
  VALID_DELIVERADDRESS_FI: "Toimitusosoite ei ole kelvollinen",

  TO_PICKUP_STATUS_EN: "In Transit",
  TO_PICKUP_STATUS_SW: "Att hämta upp",
  TO_PICKUP_STATUS_FI: "Kuljetuksessa",

  ON_DELIVERY_STATUS_EN: "On Delivery",
  ON_DELIVERY_STATUS_SW: "On Delivery(sw)",
  ON_DELIVERY_STATUS_FI: "Kuljetuksessa",

  DELIVERED_STATUS_EN: "Delivered",
  DELIVERED_STATUS_SW: "Fullgjord",
  DELIVERED_STATUS_FI: "Toimitettu",

  CANCELLED_STATUS_EN: "Cancelled",
  CANCELLED_STATUS_SW: "Avbruten",
  CANCELLED_STATUS_FI: "Peruutettu",

  COMPLETE_EN: "Complete",
  COMPLETE_SW: "Fullgjor",
  COMPLETE_FI: "Toimitettu",

  DELIVERED_EN: "Delivered?",
  DELIVERED_SW: "Levererad",
  DELIVERED_FI: "Toimitettu?",

  MAKE_ACTIVE_DELIVERY_EN: "Make Active Delivery",
  MAKE_ACTIVE_DELIVERY_SW: "Gör aktiv leverans",
  MAKE_ACTIVE_DELIVERY_FI: "Valitse toimitettavaksi",

  WRITE_MESSAGE_EN: "Write a message",
  WRITE_MESSAGE_SW: "Write a message(SW)",
  WRITE_MESSAGE_FI: "Kirjoita viesti",

  ENTER_NAME_EN: "Enter Name",
  ENTER_NAME_SW: "Ange namn",
  ENTER_NAME_FI: "Syötä nimi",

  ADD_PAYMENT_CARD_EN: "Add Payment Card",
  ADD_PAYMENT_CARD_SW: "Lägg till betalningskort",
  ADD_PAYMENT_CARD_FI: "Lisää maksukortti",

  PACKAGE_ID_EN: "Package id",
  PACKAGE_ID_SW: "Package id(SW)",
  PACKAGE_ID_FI: "Seurantatunnus",

  PACKAGE_ID_REQUIRED_EN: "Package id is required",
  PACKAGE_ID_REQUIRED_SW: "Package id is required(SW)",
  PACKAGE_ID_REQUIRED_FI: "Paketin seurantatunnus vaaditaan",

  TERMS_CONDITION_TEXT_1_EN: "I have read and accept the terms and conditions",
  TERMS_CONDITION_TEXT_1_SW:
    "I have read and accept the terms and conditions(SW)",
  TERMS_CONDITION_TEXT_1_FI: "Olen lukenut käyttöehdot ja hyväksyn ne",

  ERROR_FOR_TERM_CONDITION_EN: "Please select terms and conditions",
  ERROR_FOR_TERM_CONDITION_SW: "Please select terms and conditions(SW)",
  ERROR_FOR_TERM_CONDITION_FI: "Hyväksy käyttöehdot",

  FEEDBACK_REQUIRED_EN: "Feedback is required",
  FEEDBACK_REQUIRED_SW: "Feedback is required(SW)",
  FEEDBACK_REQUIRED_FI: "Feedback is required(FI)",

  MESSAGE_REQUIRED_EN: "Message is required",
  MESSAGE_REQUIRED_SW: "Message is required(SW)",
  MESSAGE_REQUIRED_FI: "Viesti vaaditaan",

  PICK_TIME_REQUIRED_EN: "Please select pickup time",
  PICK_TIME_REQUIRED_SW: "Please select pickup time(SW)",
  PICK_TIME_REQUIRED_FI: "Please select pickup time(FI)",

  TELEPHONE_NUMBER_EN: "Telephone Number",
  TELEPHONE_NUMBER_SW: "Telephone Number(SW)",
  TELEPHONE_NUMBER_FI: "Puhelinnumero",

  EMAIL_ADDRESS_EN: "Email Address",
  EMAIL_ADDRESS_SW: "Email Address(SW)",
  EMAIL_ADDRESS_FI: "Sähköposti",

  I_WOULD_LIKE_TO_OPT_EN:
    "I would like to opt in to receive marketing communications via:",
  I_WOULD_LIKE_TO_OPT_SW:
    "I would like to opt in to receive marketing communications via:(SW)",
  I_WOULD_LIKE_TO_OPT_FI: "Haluan lisätietoa sovelluksesta:",

  SUBMIT_FEEDBACK_BTN_EN: "SUBMIT FEEDBACK",
  SUBMIT_FEEDBACK_BTN_SW: "SUBMIT FEEDBACK(SW)",
  SUBMIT_FEEDBACK_BTN_FI: "LÄHETÄ PALAUTE",

  TYPE_OF_FEEDBACK_EN: "Type of Feedback",
  TYPE_OF_FEEDBACK_SW: "Type of Feedback(SW)",
  TYPE_OF_FEEDBACK_FI: "Palautteen tyyppi",

  TYPE_OF_FEEDBACK2_EN: "General",
  TYPE_OF_FEEDBACK2_SW: "General(SW)",
  TYPE_OF_FEEDBACK2_FI: "Yleinen",

  REFER_A_FRIEND_HEADING_EN: "Refer A Friend",
  REFER_A_FRIEND_HEADING_SW: "Refer A Friend(SW)",
  REFER_A_FRIEND_HEADING_FI: "Vinkkaa kaverille",

  REFER_A_FRIEND_SUBHEADING_EN:
    "Thank you for choosing FIUGE! Pay it forward and tip a friend for us.",
  REFER_A_FRIEND_SUBHEADING_SW:
    "Thank you for choosing FIUGE! Pay it forward and tip a friend for us.(SW)",
  REFER_A_FRIEND_SUBHEADING_FI:
    "Kiitos, että valitsit FIUGEn! Laita hyvä kiertämään ja vinkkaa meistä myös kaverille.",

  ENTER_FRIENDS_DETAIL_EN: "Enter Friend's Details",
  ENTER_FRIENDS_DETAIL_SW: "Enter Friend's Details(SW)",
  ENTER_FRIENDS_DETAIL_FI: "Syötä ystävän tiedot",

  ENTER_YOUR_DETAIL_EN: "Enter Your Details",
  ENTER_YOUR_DETAIL_SW: "Enter Your Details(SW)",
  ENTER_YOUR_DETAIL_FI: "Syötä omat tietosi",

  ZIP_CODE_EN: "Zip Code",
  ZIP_CODE_SW: "Postnummer",
  ZIP_CODE_FI: "Postinumero",

  ENTER_FRIENDS_EMAIL_ADDRESS_EN: "Enter Friend's Email address",
  ENTER_FRIENDS_EMAIL_ADDRESS_SW: "Enter Friend's Email address(SW)",
  ENTER_FRIENDS_EMAIL_ADDRESS_FI: "Syötä ystävän sähköpostiosoite",

  FRIENDS_FIRST_NAME_EN: "Friend's First Name",
  FRIENDS_FIRST_NAME_SW: "Friend's First Name(SW)",
  FRIENDS_FIRST_NAME_FI: "Ystävän etunimi",

  FRIENDS_LAST_NAME_EN: "Friend's Last Name",
  FRIENDS_LAST_NAME_SW: "Friend's Last Name(SW)",
  FRIENDS_LAST_NAME_FI: "Ystävän sukunimi",

  SEND_REFERRAL_BTN_EN: "SEND REFERRAL ",
  SEND_REFERRAL_BTN_SW: "SEND REFERRAL(SW)",
  SEND_REFERRAL_BTN_FI: "VINKKAA KAVERILLE",

  SEND_FEEDBACK_EN: "Send Feedback ",
  SEND_FEEDBACK_SW: "Skicka återkoppling",
  SEND_FEEDBACK_FI: "Anna palautetta",

  ACCEPTED_CARGO_EN: "Cargo {ID} was accepted by other carrier.",
  ACCEPTED_CARGO_SW: "Cargo {ID} was accepted by other carrier.(SW)",
  ACCEPTED_CARGO_FI: "Toinen kuljettaja valitsi rahdin {ID} kuljetettavaksi.",

  ADD_CARGO_EN:
    "A new cargo has been added by Admin. The price of the new cargo is {PRICE}" +
    currency +
    ".",
  ADD_CARGO_SW:
    "A new cargo has been added by Admin. The price of the new cargo is {PRICE}" +
    currency +
    ".(SW)",
  ADD_CARGO_FI:
    "Admin on lisännyt uuden cargon. Uuden cargon hinta on {PRICE}" +
    currency +
    ".",

  DELETE_CARGO_EN: "Cargo {ID} was removed. New Cargos are coming soon!",
  DELETE_CARGO_SW: "Cargo {ID} was removed. New Cargos are coming soon!(SW)",
  DELETE_CARGO_FI: "Rahti {ID} on poistettu. Pidä silmällä uusia rahteja!",

  CARGO_ACCEPTED_BY_OTHER_EN: "This cargo accepted by other carrier.",
  CARGO_ACCEPTED_BY_OTHER_SW: "This cargo accepted by other carrier.(SW)",
  CARGO_ACCEPTED_BY_OTHER_FI:
    "Toinen kuljettaja on valinnut tämän rahdin toimitettavaksi.",

  PROFILE_NOT_VERIFY_BY_ADMIN_EN: "Your profile is not verified by admin.",
  PROFILE_NOT_VERIFY_BY_ADMIN_SW: "Your profile is not verified by admin.(SW)",
  PROFILE_NOT_VERIFY_BY_ADMIN_FI:
    "Sinua ei ole vielä hyväksytty kuljettajaksi.",

  ENTER_PROMOCODE_EN: "Please enter promocode.",
  ENTER_PROMOCODE_SW: "Please enter promocode.(SW)",
  ENTER_PROMOCODE_FI: "Syötä alennuskoodi",

  INVALID_PRICE_EN: "Invalid price.",
  INVALID_PRICE_SW: "Invalid price.(SW)",
  INVALID_PRICE_FI: "Hinta ei ole kelvollinen",

  OK_BTN_EN: "OK",
  OK_BTN_SW: "OK(SW)",
  OK_BTN_FI: "OK",

  CANCEL_BTN_VIEW_EN: "CANCEL",
  CANCEL_BTN_VIEW_SW: "Cancel(SW)",
  CANCEL_BTN_VIEW_FI: "SULJE",

  VIEW_BTN_EN: "VIEW",
  VIEW_BTN_SW: "VIEW(SW)",
  VIEW_BTN_FI: "NÄYTÄ",

  RATE_NOW_EN: "RATE NOW",
  RATE_NOW_SW: "RATE NOW(SW)",
  RATE_NOW_FI: "Tallenna",

  NEW_MESSAGE_EN: "You have new message from {USER_ID} on package ID: {ID}",
  NEW_MESSAGE_SW: "You have new message from {USER_ID} on package ID: {ID}(SW)",
  NEW_MESSAGE_FI:
    "{USER_ID} on lähettänyt sinulle viestin koskien pakettia {ID}",

  PAYMENT_INFO_EN:
    "We will provide you an estimated payable based on the size and the distance of your delivery and general price level of the service. Your price can differ from our estimation, however to ensure the delivery, we suggest you to pay attention to the estimation.",
  PAYMENT_INFO_SW:
    "We will provide you an estimated payable based on the size and the distance of your delivery and general price level of the service. Your price can differ from our estimation, however to ensure the delivery, we suggest you to pay attention to the estimation.(SW)",
  PAYMENT_INFO_FI:
    "Annamme sinulle toimitushintaehdotuksen, joka perustuu pakettisi kokoon, kuljetettavaan matkaan sekä palvelun yleiseen hintatasoon. Hintasi voi poiketa ehdotuksesta, mutta kuljetuksen varmistamiseksi suosittelemme kiinnittämään huomiota ehdotukseemme.",

  SUBMIT_PAYMENT_EN: "SUBMIT PAYMENT",
  SUBMIT_PAYMENT_SW: "SUBMIT PAYMENT(SW)",
  SUBMIT_PAYMENT_FI: "SUBMIT PAYMENT(FI)",

  CANCEL_DELIVERY_NOTE_EN: "Payment Failed",
  CANCEL_DELIVERY_NOTE_SW: "Payment Failed(SW)",
  CANCEL_DELIVERY_NOTE_FI:
    "Maksutapahtuma epäonnistui. Paketin lähetys on keskeytynyt.",

  ORDER_CANCEL_EN: "Order Cancelled",
  ORDER_CANCEL_SW: "Order Cancelled(SW)",
  ORDER_CANCEL_FI: "Tilaus peruutettu",

  PENDING_PAYMENT_STATUS_EN: "Pending",
  PENDING_PAYMENT_STATUS_SW: "Pending(SW)",
  PENDING_PAYMENT_STATUS_FI: "Kesken",

  PAID_PAYMENT_STATUS_EN: "Paid",
  PAID_PAYMENT_STATUS_SW: "Paid(SW)",
  PAID_PAYMENT_STATUS_FI: "Maksettu",

  FREE_PAYMENT_STATUS_EN: "Free",
  FREE_PAYMENT_STATUS_SW: "Free(SW)",
  FREE_PAYMENT_STATUS_FI: "Ilmainen",

  FAILED_PAYMENT_STATUS_EN: "Failed",
  FAILED_PAYMENT_STATUS_SW: "Failed(SW)",
  FAILED_PAYMENT_STATUS_FI: "Hylätty",

  PAYMENT_TITLE_EN: "Payment",
  PAYMENT_TITLE_SW: "Payment(SW)",
  PAYMENT_TITLE_FI: "Maksun tilanne",

  OVERALL_SUCCESS_EN: "How we succeeded today? Please rate your carrier",
  OVERALL_SUCCESS_SW: "How we succeeded today? Please rate your carrier(SW)",
  OVERALL_SUCCESS_FI: "Miten onnistuimme tänään? Arvioi kuljettajasi",

  GIVE_FEEDBACK_EN: "Would you like to specify your feedback?",
  GIVE_FEEDBACK_SW: "Would you like to specify your feedback?(SW)",
  GIVE_FEEDBACK_FI: "Haluatko antaa meille lisäpalautetta?",

  SKIP_BTN_EN: "Skip",
  SKIP_BTN_SW: "Skip(SW)",
  SKIP_BTN_FI: "Ohita",

  CUSTOMER_SING_UP_TITLE_EN : "Want to make a delivery? It’s super simple!",
  CUSTOMER_SING_UP_TITLE_SW : "Want to make a delivery? It’s super simple!(SW)",
  CUSTOMER_SING_UP_TITLE_FI : "Paketti pitäisi saada perille? Helppo homma!",

  CARRIER_SING_UP_TITLE_EN : "Get to know our FIUGE application for carriers",
  CARRIER_SING_UP_TITLE_SW : "Get to know our FIUGE application for carriers(SW)",
  CARRIER_SING_UP_TITLE_FI : "Tutustu kuljettajille tarkoitettuun FIUGE-sovellukseen",

  CARRIER_SING_UP_MAINTEXT_EN : "With FIUGE you decide when, where and how you work. We optimize clients’ orders into cargos, allowing you to earn faster and more often. Find out what makes FIUGE so special and join our team today.",
  CARRIER_SING_UP_MAINTEXT_SW : "With FIUGE you decide when, where and how you work. We optimize clients’ orders into cargos, allowing you to earn faster and more often. Find out what makes FIUGE so special and join our team today.(SW)",
  CARRIER_SING_UP_MAINTEXT_FI : "FIUGElla päätät milloin, missä ja miten haluat työskennellä. Me optimoimme asiakastilaukset usean lähetyksen kokonaisuuksiksi. Siten voit tienata enemmän pienemmällä työllä. Lue mikä tekee meistä erityisen ja liity tiimimme jo tänään.",

  CUSTOMER_SING_UP_MAINTEXT_EN : "The FIUGE application has all the features that you need to send and receive in an easy and flexible way. Find out what makes FIUGE so special and register as a user.",
  CUSTOMER_SING_UP_MAINTEXT_SW : "The FIUGE application has all the features that you need to send and receive in an easy and flexible way. Find out what makes FIUGE so special and register as a user.(SW)",
  CUSTOMER_SING_UP_MAINTEXT_FI : "FIUGE-sovelluksellamme lähetyksen tekeminen ja toimitus on vaivatonta. Tutustu sovelluksen ominaisuuksiin alta, rekisteröidy käyttäjäksi ja tee lähetystilaus jo tänään.",


  CARRIER_ICON_All_In_One_EN :"Choose the cargo that suits you",
  CARRIER_ICON_All_In_One_SW :"Choose the cargo that suits you(SW)",
  CARRIER_ICON_All_In_One_FI :"Valitse rahti joka sopii sinulle",

  CARRIER_ICON_Set_The_Price_EN :"Optimized delivery routes",
  CARRIER_ICON_Set_The_Price_SW :"Optimized delivery routes(SW)",
  CARRIER_ICON_Set_The_Price_FI :"Optimoidut reitit",

  CARRIER_ICON_Up_To_Date_EN :"Track current and past orders",
  CARRIER_ICON_Up_To_Date_SW :"Track current and past orders(SW)",
  CARRIER_ICON_Up_To_Date_FI :"Seuraa ajantasaisesti",

  CARRIER_ICON_Easy_Secure_EN :"Payments to your account",
  CARRIER_ICON_Easy_Secure_SW :"Payments to your account(SW)",
  CARRIER_ICON_Easy_Secure_FI :"Palkkiot suoraan tilillesi",

  MAX_SIZE_ERROR_EN:
    "Sorry, package you are trying to send is too large. Please contact customer service.",
  MAX_SIZE_ERROR_SW:
    "Beklagar, paketet du försöker skicka är för stor. Vänligen kontakta vår kundservice.",
  MAX_SIZE_ERROR_FI:
    "Paketti, jota yrität toimittaa on liian iso. Ota yhteyttä asiakaspalveluun.",

  RE_PAYMENT_DONE_EN: "Your payment is successfully done.",
  RE_PAYMENT_DONE_SW: "Din betalning har gjorts.",
  RE_PAYMENT_DONE_FI: "Maksusi onnistui.",

  CONTINUE_REPAYMENT_BTN_EN: "Continue Payment",
  CONTINUE_REPAYMENT_BTN_SW: "Fortsätta Betalningen",
  CONTINUE_REPAYMENT_BTN_FI: "Jatka Maksua",


  PREPARE_CARGO_EN :"Prepare the Cargo",
  PREPARE_CARGO_SW :"Prepare the Cargo(SW)",
  PREPARE_CARGO_FI :"Valmistele kuljetus",

  SELECT_PREPARE_CARGO_EN: "Select Cargo To Prepare",
  SELECT_PREPARE_CARGO_SW: "Select Prepare Cargo(SW)",
  SELECT_PREPARE_CARGO_FI: "Valitse valmisteltava kuljetus",

  PREPARE_CARGO_DETAIL_EN: "Prepare Cargo Details",
  PREPARE_CARGO_DETAIL_SW: "Prepare Cargo Details(SW)",
  PREPARE_CARGO_DETAIL_FI: "Valmisteltavan kuljetuksen tilaukset",

  BACK_TO_PREPARE_CARGO_EN: "Back to Select Cargo To Prepare",
  BACK_TO_PREPARE_CARGO_SW: "Back to Select Prepare Cargo(SW)",
  BACK_TO_PREPARE_CARGO_FI: "Takaisin valitsemaan valmisteltava kuljetus",

  PREPARE_CARGO_BTN_EN: "CARGO READY",
  PREPARE_CARGO_BTN_SW: "CARGO READY(SW)",
  PREPARE_CARGO_BTN_FI: "KULJETUS VALMIS",

  MIN_PICKUP_TIME_EN: "Pickup Time",
  MIN_PICKUP_TIME_SW: "Pickup Time(SW)",
  MIN_PICKUP_TIME_FI: "Noutoaika",

  MIN_DELIVERY_TIME_EN: "Delivery Time",
  MIN_DELIVERY_TIME_SW: "Delivery Time(SW)",
  MIN_DELIVERY_TIME_FI: "Toimitusaika",

  PREPARE_CARGO_STATUS_EN: "Cargo ready",
  PREPARE_CARGO_STATUS_SW: "Cargo ready(SW)",
  PREPARE_CARGO_STATUS_FI: "Kuljetus valmis",

  PACKAGE_COUNT_HEAD_EN: "package",
  PACKAGE_COUNT_HEAD_SW: "package(SW)",
  PACKAGE_COUNT_HEAD_FI: "paketti",

  PACKAGES_COUNT_HEAD_EN: "packages",
  PACKAGES_COUNT_HEAD_SW: "packages(SW)",
  PACKAGES_COUNT_HEAD_FI: "pakettia",

  CONTACT_NUMBER: "/ 020 798 1470",

  FOOD_CARGO_YES_STATUS_EN: "Yes",
  FOOD_CARGO_YES_STATUS_SW: "Yes(SW)",
  FOOD_CARGO_YES_STATUS_FI: "Kyllä",

  FOOD_CARGO_NO_STATUS_EN: "No",
  FOOD_CARGO_NO_STATUS_SW: "No(SW)",
  FOOD_CARGO_NO_STATUS_FI: "Ei",

  CITY_TITLE_EN: "City",
  CITY_TITLE_SW: "Stad",
  CITY_TITLE_FI: "Kaupunki",

TOO_EARLY_EN: "It too early to accept this Cargo. Try again later!",
TOO_EARLY_FI: "Tätä Cargoa ei voi vielä valita. Kokeile myöhemmin uudelleen!",
TOO_EARLY_SW: "It too early to accept this Cargo. Try again later!(SWE)"

};

/*
 * In development mode, for easier debugging, you can ignore zone related error
 * stack frames such as `zone.run`/`zoneDelegate.invokeTask` by importing the
 * below file. Don't forget to comment it out in production mode
 * because it will have a performance impact when errors are thrown
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
