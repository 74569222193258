import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-navigations',
  templateUrl: './navigations.component.html',
  styleUrls: ['./navigations.component.scss']
})
export class NavigationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
